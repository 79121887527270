import React, { memo, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import $ from "jquery";
// import { toast } from 'react-toastify';
import * as actions from "../../Redux/Actions/ForgotPassAction";
import { checkEmail } from "../Utility/InputValidation";
import Spinner from "react-bootstrap/Spinner";

function ForgotPasswordForm(props) {
  const [emailInputError, setEmailInputError] = useState("");
  const [loading, isLoading] = useState(false);
  const { setFormTemplate, email } = props;

  const backToFormHandler = () => {
    setFormTemplate(0);
  };

  // TODO:  Enable error toast once issues with generating code is fixed
  //        Temporary bypass while verification code is having issues

  // eslint-disable-next-line no-unused-vars
  const codeGenerateResponse = (generateCodeResponse) => {
    $("#return-options").modal("toggle");
    //   if (generateCodeResponse.status === 200 && generateCodeResponse.data) {
    //   $('#return-options').modal('toggle');
    // } else {
    //   toast.error('Unable to generate verification code. Please try again.', {
    //     position: toast.POSITION.BOTTOM_CENTER,
    //   });
    // }
  };

  const submitEmailHandler = async (event) => {
    event.preventDefault();
    isLoading(true);
    const payload = { email };
    const requestResponse = await props.verifyForgotPassEmail(payload);

    if (requestResponse) {
      if (requestResponse.status === 200 && requestResponse.data) {
        props.setUserId(requestResponse.data.id);
        const verCodeRequestPayload = {
          user_id: requestResponse.data.id,
          user_contact: email,
          sent_by: "email",
        };
        const generateCodeResponse = await props.generateVerificationCode(
          verCodeRequestPayload
        );
        codeGenerateResponse(generateCodeResponse);
        isLoading(false);
      }
    } else {
      setEmailInputError("Email not registered");
      isLoading(false);
    }
  };

  return (
    <div className="login-form-container">
      <form className="login-form">
        <div className="form-group  mb-5">
          <h2 className="h1 font-weight-bold txt-color-dark">
            Forgot Password
          </h2>
          <span className="form-title-head">
            Please enter your registered email.
          </span>
        </div>

        <div className="form-group mb-5">
          <label htmlFor="email-input" className="form-label">
            Email Address
          </label>
          <input
            id="email-input"
            type="text"
            className="form-control login-control"
            placeholder="you@example.com"
            value={email}
            onChange={(event) => {
              const emailInputValue = event.target.value;
              setEmailInputError(
                checkEmail(emailInputValue) || emailInputValue === ""
                  ? ""
                  : "Please input a valid email address"
              );
              props.setEmail(emailInputValue);
            }}
          />
          <p style={{ fontSize: 14, color: "red" }}>{emailInputError}</p>
        </div>

        <div className="form-group mb-5">
          <button
            className="btn btn-success login-btn"
            type="button"
            id="alert-btn-open"
            onClick={submitEmailHandler}
            disabled={!checkEmail(email)}
          >
            {loading ? (
              <Spinner animation="border" role="status" />
            ) : (
              <span
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  fontFamily: "museo-sans",
                }}
              >
                Submit
              </span>
            )}
          </button>
        </div>

        <div className="form-group mb-5">
          <a
            className="txt-color-secondary"
            onClick={() => {
              backToFormHandler();
            }}
          >
            <i className="fas fa-arrow-left mr-3" />
            Back to Login
          </a>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  email: state.forgotPass.email,
  errorMessage: state.forgotPass.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  setEmail: (text) => dispatch(actions.setEmail(text)),
  setUserId: (text) => dispatch(actions.setUserId(text)),
  verifyForgotPassEmail: (payload) =>
    dispatch(actions.verifyForgotPassEmail(payload)),
  generateVerificationCode: (payload) =>
    dispatch(actions.generateVerificationCode(payload)),
});

ForgotPasswordForm.propTypes = {
  email: PropTypes.string,
  setEmail: PropTypes.func.isRequired,
  setUserId: PropTypes.func.isRequired,
  verifyForgotPassEmail: PropTypes.func.isRequired,
  generateVerificationCode: PropTypes.func.isRequired,
};

ForgotPasswordForm.defaultProps = {
  email: "",
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(ForgotPasswordForm));
