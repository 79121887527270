import * as types from './Actions/ActionTypes/CustomSolutionTypes';

const initState = {
  statusCdoe: '',
  errorMessage: '',
  data: '',
  hasWatchedErrorMessage: '',
  hasWatchedStatusCode: '',
};

const CustomSolutionReducer = (state = initState, action) => {
  switch (action.type) {
    case types.CUSTOM_SOLUTION_ERROR_MESSAGE: return {
      ...state,
      errorMessage: action.data,
    };
    case types.CUSTOM_SOLUTION_STATUS_CODE: return {
      ...state,
      statusCode: action.data,
    };
    case types.CUSTOM_SOLUTION_DATA: return { ...state, data: action.data };
    case types.CUSTOM_SOLUTION_HAS_WATCHED_ERROR_MESSAGE: return {
      ...state,
      hasWatchedErrorMessage: action.data,
    };
    case types.CUSTOM_SOLUTION_HAS_WATCHED_STATUS_CODE: return {
      ...state,
      hasWatchedStatusCode: action.data,
    };
    default: return state;
  }
};

export default CustomSolutionReducer;
