import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as httpMethods from '../../Shared/Api';

function PasswordSettings(props) {
  const { userId } = props;
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [hideCurrentPassword, setHideCurrentPassword] = useState(true);
  const [hideNewPassword, setHideNewPassword] = useState(true);

  const changePassSubmitHandler = () => {
    const payload = {
      user_id: userId,
      password: currentPassword,
      new_password: newPassword,
    };

    httpMethods
      .Post('api/users/change_password/', payload)
      .then(() => {
        toast.success('Password changed successfully.', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch(() => {
        toast.error('Wrong password. Please try again.', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  return (

    <div className="col-12 col-md-9 px-3 px-md-5">
      <form action="" className="form-box-fluid shadow-none">
        <div className="form-group">
          <h3 className="form-heading mb-5">Change Password</h3>
        </div>

        <div className="form-row">
          <div className="form-group col-md-8 col-12">
            <label htmlFor="settings-current-password" className="form-label"> Current Password</label>
            <input
              id="settings-current-password"
              type={hideCurrentPassword ? 'password' : 'text'}
              className="form-control login-control"
              placeholder="password"
              value={currentPassword}
              onChange={(event) => {
                setCurrentPassword(event.target.value);
              }}
            />

            <div
              className="d-table login-password-show"
              onClick={() => {
                setHideCurrentPassword(!hideCurrentPassword);
              }}
            >
              <input type="checkbox" name="" className="login-password-show-checkbox" />
              <span className="login-password-show-checkbox-label">Show</span>
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-8 col-12">
            <label htmlFor="settings-new-password" className="form-label"> New Password</label>
            <input
              id="settings-new-password"
              type={hideNewPassword ? 'password' : 'text'}
              className="form-control login-control"
              placeholder="password"
              value={newPassword}
              onChange={(event) => {
                setNewPassword(event.target.value);
              }}
            />

            <div
              className="d-table login-password-show"
              onClick={() => {
                setHideNewPassword(!hideNewPassword);
              }}
            >
              <input type="checkbox" name="" className="login-password-show-checkbox" />
              <span className="login-password-show-checkbox-label">Show</span>
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-12 col-md-4 px-0">
            <button
              type="button"
              className="btn btn-primary btn-continue"
              onClick={changePassSubmitHandler}
            >
              Change Password
            </button>
          </div>
        </div>

      </form>
    </div>
  );
}

export default PasswordSettings;
