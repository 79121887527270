import React from 'react';

function PhysicianFollowUpModal() {
  return (
    <div>
      <p>
        Based on your response, we recommend that you follow up with a healthcare provider.
        (e.g. Your primary care provider, a physical therapist, etc.)
      </p>
    </div>
  );
}

export default PhysicianFollowUpModal;
