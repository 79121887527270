import React from 'react';
import PhysTreeHeader from './Templates/Header';
import PhysTreeFooter from './Templates/Footer';
import QuestionProgressBar from './Templates/QuestionProgressBar';
import AlgoQuestionTemplate from './Templates/AlgoQuestionTemplate';

function LowBackAlgorithm() {
  return (
    <div>
      <PhysTreeHeader />
      <div className="site-page container-fluid p-0">
        <QuestionProgressBar category={2} />
        <AlgoQuestionTemplate />
      </div>
      <PhysTreeFooter />
    </div>
  );
}

export default LowBackAlgorithm;
