import * as types from './Actions/ActionTypes/LowBackMovementAlgorithmTypes';

const initState = {
  result: '',
  statusCdoe: '',
  errorMessage: '',
};

const LowBackMovementAlgorithmReducer = (state = initState, action) => {
  switch (action.type) {
    case types.LOW_BACK_MOVEMENT_ALGORITHM_ERROR_MESSAGE: return {
      ...state,
      errorMessage: action.data,
    };
    case types.LOW_BACK_MOVEMENT_ALGORITHM_STATUS_CODE: return {
      ...state,
      statusCode: action.data,
    };
    case types.LOW_BACK_MOVEMENT_ALGORITHM_RESULT: return { ...state, result: action.data };
    default: return state;
  }
};

export default LowBackMovementAlgorithmReducer;
