import { _request } from "./BaseApi";

export const login = async (payload) => {
  try {
    let url = `api/users/login/`;
    let result = await _request("post", url, payload);
    return result;
  } catch (err) {
    return err;
  }
};

export const getHasNotLogged3Weeks = async (id) => {
  try {
    let url = `api/users/get_has_not_logged_3weeks/?user_id=${id}`;
    let result = await _request("get", url);
    return result;
  } catch (err) {
    return err;
  }
};
