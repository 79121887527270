import React from 'react';

function MedRiskExplanationModal() {
  return (
    <div>
      <p>
        Based upon the results of your survey, your low back pain
        should improve with 3 action steps:
      </p>
      <ul>
        <li>Exercises</li>
        <li>Self management</li>
        <li>Physical Therapy consultation</li>
      </ul>
      <p>
        We have provided you with 2 of the actions, customized exercises
        based on your answers, as well as mindful postural education,
        activity recommendations, and a progressive walking program.
      </p>
      <p>
        Based on your risk profile, we would also recommend that you
        schedule an appointment with a Physical Therapist via
        telehealth or in person, to provide you with some additional
        advice and assistance on how to best manage your low back pain.
        In the meantime, keep moving. Studies show, mobility
        is one of the best medicines for low back pain.
      </p>
    </div>
  );
}

export default MedRiskExplanationModal;
