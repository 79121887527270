import React from "react";

function ComingSoonModal() {
  return (
    <div
      className="modal fade"
      id="coming-soon-journal"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content rounded-20">
          <div className="modal-body p-5 p-md-4 text-center">
            <a className="close" data-dismiss="modal">
              <i className="fas fa-times txt-color-dark-grey" />
            </a>
            <h3 className="txt-color-dark h3 font-weight-bold mt-md-4 mb-5">
              Coming Soon...
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComingSoonModal;
