import React from 'react';

function QP25(props) {
  const { setComponentID, subID } = props;
  const questionAnswersVariant = [
    ['RP27-0', 'RP22-0'],
    ['RP27-1', 'RP22-1'],
  ];
  const optionSet = questionAnswersVariant[subID];
  return (
    <div className="site-page container-fluid p-0">
      <main className="container d-flex justify-content-center align-items-center">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="card-box">
              <div className="row">
                <div className="col-12 col-sm-12 d-flex align-items-center">
                  <div className="d-block">
                    <h4 className="txt-color-dark h4 font-weight-bold text-sm-left text-center">
                      Would you like us to reassess your condition to
                      see if another set of exercises would be beneficial?
                    </h4>
                    <div className="w-100 mt-5">
                      <div className="d-table mx-auto mx-sm-0">
                        <a
                          className="btn btn-outline-secondary btn-radio mr-md-2 mr-2 btn-fw-80"
                          onClick={() => {
                            setComponentID(optionSet[0]);
                          }}
                        >
                          Yes
                        </a>
                        <a
                          className="btn btn-outline-secondary btn-radio mr-md-2 btn-fw-80"
                          onClick={() => {
                            setComponentID(optionSet[1]);
                          }}
                        >
                          No
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default QP25;
