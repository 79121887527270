import * as api from "../../api/HistoryApi";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const saveHistoryAnswer = () => {
  return async (dispatch, getState) => {
    try {
      const id = await AsyncStorage.getItem("userId");
      const isPhaseTwo = getState().home.isPhaseTwo;
      const history = getState().history_answer;
      const { retakeNumber, historyAnswer } = history;

      const answerType = {
        lbsQ1: 1, // Make constants file date = 1
        lbsQ2: 2, // frequency 1-5,<5,<10 = 2
        lbsQ3: 3, // boolean = 3
        imgQ1: 3,
        imgQ2: 4, // list = 4
        medQ1: 3,
        medQ2: 4,
        prcQ1: 3,
        prcQ2: 4,
        prcTrtQ1: 3,
        prcTrtQ2: 4,
      };

      const payload = {
        user_id: id,
        survey: isPhaseTwo ? 8 : 3,
        retake_number: retakeNumber,
        answer: JSON.stringify(historyAnswer),
        answer_type: JSON.stringify(answerType),
      };
      let response = await api.saveHistoryAnswer(payload);
      if (response.status === 200) {
        dispatch({ type: "SAVE_HISTORY_ANSWER" });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
