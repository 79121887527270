import React from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as httpMethods from "../../Shared/Api";
import { useHistory } from "react-router-dom";
import PhysTreeHeader from "../Templates/Header";
import PhysTreeFooter from "../Templates/Footer";
import wizardBlue from "../Images/wizard_blue.svg";
import ctaImgBlue from "../Images/cta_img_blue.svg";
import { useSelector } from "react-redux";
import { PhaseTwo } from "../../Constants/Surveys";
import * as api from "../../api/HistoryApi";

const FollowUp = () => {
  const history = useHistory();

  const saveAnswer = (payload) => {
    httpMethods
      .Post("api/surveys/save_answer/", payload)
      .then(() => {
        history.push({
          pathname: "/results",
          state: {
            category: "PT",
            retake_number: payload.retake_number,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const viewButtonHandler = async () => {
    const answerType = { result: 10 };
    const id = await AsyncStorage.getItem("userId");
    const getRetakeNumberResponse = await api.getPTRetakeNumber(id);
    const answer = {
      result: "See a Physical Therapist",
    };
    const payload = {
      user_id: id,
      survey: PhaseTwo,
      retake_number: getRetakeNumberResponse.data.data,
      answer: JSON.stringify(answer),
      answer_type: JSON.stringify(answerType),
    };

    saveAnswer(payload);
  };

  return (
    <div>
      <PhysTreeHeader />
      <div className="site-page container-fluid p-0">
        <main className="container  d-flex justify-content-center align-items-center">
          <div className="w-100">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="card-box mw-100 p-md-5 ">
                  <div className="row">
                    <div className="col-12 col-sm-3 mb-3">
                      <img
                        src={wizardBlue}
                        alt=""
                        style={{ maxWidth: 200 }}
                        className="mx-auto d-none d-sm-table"
                      />
                      <img
                        src={ctaImgBlue}
                        alt=""
                        style={{ maxWidth: 200 }}
                        className="mx-auto d-table d-sm-none"
                      />
                    </div>

                    <div className="col-12 col-sm-9 d-flex align-items-center">
                      <div className="d-block">
                        <h2 className="txt-color-dark h2 font-weight-bold text-sm-left text-center">
                          Given your response, we feel it is best that you
                          follow up with your physician at this time.
                        </h2>
                        <div className="w-100 mt-5 text-center text-sm-left">
                          <a
                            style={{ cursor: "pointer" }}
                            className="btn btn-primary btn-continue"
                            data-aim="physician-follow-up"
                            id="alert-btn-open"
                            onClick={() =>  viewButtonHandler()}
                          >
                            Got It
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <PhysTreeFooter />
    </div>
  );
};

export default FollowUp;
