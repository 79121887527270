import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as httpMethods from '../../../Shared/Api';

import wizardBlue from '../../Images/wizard_blue.svg';
import ctaImgBlue from '../../Images/cta_img_blue.svg';

function InitialEvaluate(props) {
  const { setComponentID } = props;
  const history = useHistory();
  const redirectHandler = (url) => {
    history.push(url);
  };
  const loggedUserId = sessionStorage.getItem('userId');
  const [symptoms, setSymptoms] = useState('');

  const getSymptoms = () => {
    const payload = {
      user_id: loggedUserId,
    };

    httpMethods
      .Get(`api/surveys/get_symptoms/?user_id=${payload.user_id}`)
      .then((response) => {
        console.log(response.data.data);
        setSymptoms(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isBetterHandler = () => {
    switch (symptoms) {
      case 'Better':
        setComponentID('QP24-0');
        break;
      case 'Same':
        setComponentID('QP21-0');
        break;
      case 'Worse':
        setComponentID('QP21-0');
        break;
      default:
        console.log("Cannot get previous functional assessment.");
        break;
    }
  };
  
  const isSameHandler = () => {
    switch (symptoms) {
      case 'Better':
        setComponentID('QP21-0');
        break;
      case 'Same':
        setComponentID('QP21-0');
        break;
      case 'Worse':
        setComponentID('QP21-0');
        break;
      default:
        console.log("Cannot get previous functional assessment.");
        break;
    }
  };

  const isWorseHandler = () => {
    switch (symptoms) {
      case 'Better':
        setComponentID('QP21-0');
        break;
      case 'Same':
        setComponentID('QP21-0');
        break;
      case 'Worse':
        setComponentID('QP25-0');
        break;
      default:
        console.log("Cannot get previous functional assessment.");
        break;
    }
  };

  useEffect(() => {
    getSymptoms();
  }, []);

  return (
    <main className="container  d-flex justify-content-center align-items-center">
      <div className="w-100">

        <div className="card-box mw-100">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-2 mb-3">
              <img src={wizardBlue} alt="" style={{ maxWidth: 160 }} className="mx-auto d-none d-lg-block" />
              <img src={ctaImgBlue} alt="" style={{ maxWidth: 200 }} className="mx-auto d-table d-lg-none" />
            </div>

            <div className="col-12 col-md-12 col-lg-10 d-flex align-items-center">
              <div className="d-block">
                <h2 className="txt-color-dark h2 font-weight-bold text-sm-left text-center">
                  With respect to your low back pain, how would you describe
                  yourself now compared to when you first sought treatment?
                </h2>
                <div className="w-100 mt-5 text-center text-sm-left btn-group-line">

                  <label className="btn btn-sm btn-outline-secondary btn-radio mr-1 btn-redirect" data-link="">
                    <input
                      type="radio"
                      autoComplete="off"
                      className="text-dark font-weight-bold d-none"
                      onClick={() => { redirectHandler('/phase-two/recovered'); }}
                    />
                    Completely Recovered
                  </label>

                  <label className="btn btn-sm btn-outline-secondary btn-radio mr-1 btn-redirect" data-link="">
                    <input
                      type="radio"
                      autoComplete="off"
                      className="text-dark font-weight-bold d-none"
                      onClick={() => { isBetterHandler(); }}
                    />
                    Much Improved
                  </label>
                  <label className="btn btn-sm btn-outline-secondary btn-radio mr-1 btn-redirect" data-link="">
                    <input
                      type="radio"
                      autoComplete="off"
                      className="text-dark font-weight-bold d-none"
                      onClick={() => { isBetterHandler(); }}
                    />
                    Slightly Improved
                  </label>
                  <label className="btn btn-sm btn-outline-secondary btn-radio mr-1 btn-redirect" data-link="">
                    <input
                      type="radio"
                      autoComplete="off"
                      className="text-dark font-weight-bold d-none"
                      onClick={() => { isSameHandler(); }}
                    />
                    No Change
                  </label>
                  <label className="btn btn-sm btn-outline-secondary btn-radio mr-1 btn-redirect" data-link="">
                    <input
                      type="radio"
                      autoComplete="off"
                      className="text-dark font-weight-bold d-none"
                      onClick={() => { isWorseHandler(); }}
                    />
                    Slightly Worse
                  </label>
                  <label className="btn btn-sm btn-outline-secondary btn-radio mr-1 btn-redirect" data-link="">
                    <input
                      type="radio"
                      autoComplete="off"
                      className="text-dark font-weight-bold d-none"
                      onClick={() => { isWorseHandler(); }}
                    />
                    Much Worse
                  </label>
                  <label className="btn btn-sm btn-outline-secondary btn-radio mr-1 btn-redirect" data-link="">
                    <input
                      type="radio"
                      autoComplete="off"
                      className="text-dark font-weight-bold d-none"
                      onClick={() => { isWorseHandler(); }}
                    />
                    Worse
                    Than Ever
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </main>
  );
}

export default InitialEvaluate;
