import React, { memo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import verifyEmailImg from "../../Images/code-verify-email.svg";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../Redux/Actions/VerifyAction";

function VerifyEmailModal(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useSelector((state) => ({
    state: state.register,
  }));

  const { email, userId, password } = state;
  const GotItOnclickHandler = async () => {
    /*   const payload = {
      user_id: userId,
      user_contact: email,
      sent_by: "email",
    };
    await dispatch(actions.verifySubmit(payload)); */
    history.push({
      pathname: "/verify",
      state: { userId: userId, email: email, password: password },
    });
  };

  return (
    <Modal
      show={props.isOpen}
      onHide={GotItOnclickHandler}
      animation={true}
      centered
      contentClassName="rounded-20"
    >
      <div className="modal-content rounded-20">
        <div className="modal-body p-5   ">
          <a href="" class="close" data-dismiss="modal">
            <i class="fas fa-times txt-color-dark-grey"></i>
          </a>
          <div className="row">
            <div className="col-12 d-flex align-items-center mx-0 px-0 justify-content-center">
              <img src={verifyEmailImg} alt="" />
            </div>
            <div className="col-12 my-4">
              <h4 className="text-center txt-color-dark h3 font-weight-bold">
                Email Verification
              </h4>
              <p className="text-center">
                We’ve sent a 4 digit code to {email}.
              </p>
            </div>

            <div className="col-12">
              <a
                onClick={GotItOnclickHandler}
                className="btn-continue btn-outline-secondary text-decoration-none mx-auto d-table"
                id="alert-btn-close"
              >
                Ok
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  type: state.verify.type,
  userId: state.register.userId,
});

export default connect(mapStateToProps, null)(memo(VerifyEmailModal));
