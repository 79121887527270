import * as types from './Actions/ActionTypes/ForgotPassTypes';

const initState = {
  email: '',
  errorMessage: '',
  userId: '',
};

const ForgotPassReducer = (state = initState, action) => {
  switch (action.type) {
    case types.FORGOT_PASS_EMAIL: return { ...state, email: action.data };
    case types.FORGOT_PASS_ERROR_MESSAGE: return { ...state, errorMessage: action.data };
    case types.FORGOT_PASS_USER_ID: return { ...state, userId: action.data };
    default: return state;
  }
};

export default ForgotPassReducer;
