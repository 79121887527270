import React, { useState } from "react";
import $ from "jquery";
import { toast } from "react-toastify";
import * as httpMethods from "../../Shared/Api";

function NewJournalModal(props) {
  const { getUserJournalList, category } = props;
  const [subject, setSubject] = useState("");
  const [notes, setNotes] = useState("");

  const loggedUserId = sessionStorage.getItem('userId');

  const saveJournalSuccess = () => {
    if (getUserJournalList) {
      getUserJournalList();
    }
    toast.success("Journal successfully saved.", {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    $("#new-journal").modal("hide");
    setSubject("");
    setNotes("");
  };

  const submitClickHandler = () => {
    const payload = {
      title: subject,
      notes,
      user_id: loggedUserId,
      rating: null,
      category,
    };

    httpMethods
      .Post("api/journal/save_journal/", payload)
      .then(saveJournalSuccess)
      .catch(() => {
        toast.error("An error occurred. Please try again.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  return (
    <div
      className="modal fade"
      id="new-journal"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content rounded-20">
          <div className="modal-body p-5 p-md-4  ">
            <a className="close" data-dismiss="modal">
              <i className="fas fa-times txt-color-dark-grey" />
            </a>
            <a
              style={{ cursor: "pointer" }}
              className="link mt-4 d-block txt-color-dark-grey"
            >
              <i className="fas fa-arrow-left mr-2" data-dismiss="modal" />
              Back to Program
            </a>
            <h3 className="txt-color-dark h3 font-weight-bold mt-md-4 mb-5">
              <i className="fa fa-clipboard txt-color-primary mr-2" />
              Add Note
            </h3>
            <form>
              <div className="form-row">
                <div className="form-group col-12 col-md-12">
                  <label htmlFor="subject-input" className="form-label">
                    Subject
                  </label>
                  <input
                    id="subject-input"
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    value={subject}
                    onChange={(event) => setSubject(event.target.value)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-12 col-md-12">
                  <label htmlFor="notes-input" className="form-label">
                    Notes
                  </label>
                  <textarea
                    id="notes-input"
                    className="form-control"
                    rows="3"
                    value={notes}
                    onChange={(event) => setNotes(event.target.value)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-12 col-md-4">
                  <button
                    type="button"
                    className="btn btn-primary btn-md btn-continue"
                    onClick={submitClickHandler}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewJournalModal;
