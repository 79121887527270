import * as api from "../../api/ResultsApi";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const getSurveyResults = (category, retake_number) => {
  return async (dispatch) => {
    try {
      const id = await AsyncStorage.getItem("userId");
      const userName = await AsyncStorage.getItem("email");
      let response = await api.getSurveyResults(id, category, retake_number);
      if (response.status === 200) {
        const resultData = JSON.parse(response.data.data.results);
        dispatch({
          type: "GET_RESULTS_DETAILS",
          payload: response.data.data,
          resultsDetails: resultData,
          name: userName,
        });
        if (category === "Phase2" || category === "Phase2WithAlgo") {
          let res = await api.getFunctionalAssessment(
            id,
            category,
            retake_number
          );
          const title = res.data.data.map((i) => {
            return "";
          });
          dispatch({
            type: "GET_FUNCTIONAL_ASSESSMENT",
            payload: res.data.data,
            title: title,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCustomSolutionPercent = (category, retake_number) => {
  return async (dispatch) => {
    try {
      const id = await AsyncStorage.getItem("userId");
      let response = await api.getCustomSolutionPercent(
        id,
        category,
        retake_number
      );
      if (response.status === 200) {
        dispatch({ type: "GET_PERCENTAGE", payload: response.data.data });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
