import * as types from "./Actions/ActionTypes/VerifyCodeTypes";

const initState = {
  errorMessage: "",
  statusCode: "",
};

const VerifyCodeReducer = (state = initState, action) => {
  switch (action.type) {
    case types.VERIFY_CODE_ERROR_MESSAGE:
      return { ...state, errorMessage: action.data };
    case types.VERIFY_CODE_STATUS_CODE:
      return { ...state, statusCode: action.data };
    case "VERIFY_CODE_ERROR":
      return {
        ...state,
        statusCode: 422,
      };
    case "RESET_VERIFY":
      return initState;

    default:
      return state;
  }
};

export default VerifyCodeReducer;
