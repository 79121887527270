import React from 'react';

function HighRiskExplanationModal() {
  return (
    <div>
      <p>
        The results of your survey indicate your back pain has
        been having a significant impact on your lifestyle and
        fear may be one limiting factor in your overall recovery.
        We have provided you with the following:
      </p>
      <ul>
        <li>customized exercises based on the information you provided</li>
        <li>mindful postural education handout</li>
        <li>activity recommendations</li>
        <li>progressive walking program.</li>
      </ul>
      <p>
        These are safe exercises, and appropriate for you to begin performing now.
      </p>
      <p>
        Additionally, based on your risk profile, we would also recommend
        that you schedule an appointment with a Physical Therapist via
        telehealth or in person, to provide you with some additional
        advice and assistance on how to best manage your low back pain.
        In the meantime, keep moving. Studies show, mobility is one of the
        best medicines for low back pain.
      </p>
    </div>
  );
}

export default HighRiskExplanationModal;
