import * as types from './ActionTypes/RegisterTypes';

export const setFirstName = (data) => (
  {
    type: types.REGISTER_FIRST_NAME,
    data,
  }
);

export const setLastName = (data) => (
  {
    type: types.REGISTER_LAST_NAME,
    data,
  }
);

export const setGender = (data) => (
  {
    type: types.REGISTER_GENDER,
    data,
  }
);

export const setYear = (data) => (
  {
    type: types.REGISTER_YEAR,
    data,
  }
);

export const setEmail = (data) => (
  {
    type: types.REGISTER_EMAIL,
    data,
  }
);

export const setPhoneNum = (data) => (
  {
    type: types.REGISTER_PHONE_NUM,
    data,
  }
);

export const setZipCode = (data) => (
  {
    type: types.REGISTER_ZIP_CODE,
    data,
  }
);

export const setPassword = (data) => (
  {
    type: types.REGISTER_PASSWORD,
    data,
  }
);

export const errorMessageReturn = (data) => (
  {
    type: types.REGISTER_ERROR_MESSAGE,
    data,
  }
);

export const setUserId = (data) => (
  {
    type: types.REGISTER_USER_ID,
    data,
  }
);

// const signupSuccessResponse = (dispatch, response) => {
//   dispatch(errorMessageReturn(null));
//   if (response.data) {
//     dispatch(setUserId(response.data.data.id));
//   } else {
//     dispatch(setUserId(''));
//   }
// };

// const signupErrorResponse = (dispatch, error) => {
//   dispatch(errorMessageReturn(error.response.data.message));
// };

// export const signupSubmit = (payload) => {
//   const signupRequest = (dispatch) => {
//     const signup = httpMethods
//       .Post('api/users/register/', payload)
//       .then((response) => {
//         signupSuccessResponse(dispatch, response);
//       })
//       .catch((error) => {
//         signupErrorResponse(dispatch, error);
//       });

//     return signup;
//   };

//   return signupRequest;
// };
