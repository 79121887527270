import React from 'react';

function QP24(props) {
  const { setComponentID, subID } = props;
  const questionAnswersVariant = [
    ['RP26-0', 'RP21-0'],
    ['RP29-0', 'RP28-0'],
    ['RP26-1', 'RP21-1'],
  ];
  const optionSet = questionAnswersVariant[subID];

  return (
    <div className="site-page container-fluid p-0">
      <main className="container d-flex justify-content-center align-items-center">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="card-box">
              <div className="row">
                <div className="col-12 col-sm-12 d-flex align-items-center">
                  <div className="d-block">
                    <h4 className="txt-color-dark h4 font-weight-bold text-sm-left text-center">
                      Do you feel ready to progress to the second phase of your exercise program?
                    </h4>
                    <div className="w-100 mt-5">
                      <div className="d-table mx-auto mx-sm-0">
                        <a
                          className="btn btn-outline-secondary btn-radio mr-md-2 mr-2 btn-fw-80"
                          onClick={() => {
                            setComponentID(optionSet[0]);
                          }}
                        >
                          Yes
                        </a>
                        <a
                          className="btn btn-outline-secondary btn-radio mr-md-2 btn-fw-80"
                          onClick={() => {
                            setComponentID(optionSet[1]);
                          }}
                        >
                          No
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default QP24;
