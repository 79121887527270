import AsyncStorage from "@react-native-async-storage/async-storage";
import * as httpMethods from "../../Shared/Api";

require("dotenv").config({ path: "../.env" });

const payload = {
  grant_type: process.env.REACT_APP_API_GRANT_TYPE,
  username: process.env.REACT_APP_API_CLIENT_USER,
  password: process.env.REACT_APP_API_CLIENT_PASSWORD,
};

const auth = {
  username: process.env.REACT_APP_API_CLIENT_ID,
  password: process.env.REACT_APP_API_CLIENT_SECRET,
};

export const getAccessToken = () => {
  const getAccessTokenRequestHandler = () => {
    const getToken = httpMethods
      .GetAccessToken("api/o/token/", payload, auth)
      .then(async (response) => {
        const accessToken = response.data.access_token;
        await AsyncStorage.setItem("access_token", accessToken);
      });

    return getToken;
  };

  return getAccessTokenRequestHandler;
};
export default getAccessToken;
