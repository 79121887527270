import React from "react";

import wizardBlue from "../Images/wizard_blue.svg";
import ctaImgBlue from "../Images/cta_img_blue.svg";
import { useHistory } from "react-router-dom";

const NewUser = () => {
  const history = useHistory();
  return (
    <div className="site-page container-fluid p-0">
      <main className="container d-flex justify-content-center align-items-center">
        <div className="w-100">
          <div className="card-box mw-100">
            <div className="row">
              <div className="col-12 col-sm-4 mb-3">
                <img
                  src={wizardBlue}
                  alt=""
                  style={{ maxWidth: 200 }}
                  className="mx-auto d-none d-sm-table"
                />
                <img
                  src={ctaImgBlue}
                  alt=""
                  style={{ maxWidth: 200 }}
                  className="mx-auto d-table d-sm-none"
                />
              </div>
              <div className="col-12 col-sm-6 d-flex align-items-center">
                <div className="d-block mx-auto mx-md-0 text-center text-md-left">
                  <h1 className="mb-4 h2">Did you come here for...</h1>
                  <div className="w-100 mt-5">
                    <div className="d-table mx-auto mx-sm-0">
                      <a
                        onClick={() => history.push("/pain-warning")}
                        className="btn btn-outline-secondary btn-lg btn-radio mr-1"
                      >
                        Low back pain?
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default NewUser;
