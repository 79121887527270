import React from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useHistory, Redirect } from "react-router-dom";
import logo from "../Images/logo.svg";
import { useDispatch } from "react-redux";

function Header() {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("access_token");
  const email = sessionStorage.getItem("email");

  const logoutUser = () => {
    AsyncStorage.removeItem("token");
    dispatch({ type: "RESET_AUTH" });
    history.push("/");
  };

  const headerSection = token ? (
    <header className="site-header">
      <nav className="navbar navbar-expand-lg justify-content-between">
        <a
          className="navbar-brand"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("/home");
          }}
        >
          <img src={logo} alt="" />
        </a>

        <div
          className="d-flex align-items-center main-menu"
          id="navbarSupportedContent"
        >
          <button
            className="client-profile is-letter"
            type="button"
            style={{ backgroundColor: "white" }}
          >
            {email[0]}
          </button>

          <div className="d-table dropdown btn-group">
            <a
              className="txt-color-dark  ml-3 d-none d-md-block"
              data-toggle="dropdown"
              data-target="#dropdown-menu"
              style={{ cursor: "pointer" }}
            >
              <i className="fas fa-chevron-down fa-sm" />
            </a>

            <a
              className="txt-color-dark  ml-3 d-block d-md-none"
              id="btn-sidebar-open"
              data-toggle="dropdown"
              data-target="#dropdown-menu"
            >
              <i className="fas fa-chevron-down fa-sm" />
            </a>

            <div
              className="dropdown-menu dropdown-menu-right"
              id="dropdown-menu"
            >
              <a
                className="dropdown-item py-2"
                onClick={() => {
                  history.push("/home");
                }}
              >
                <i className="fas fa-home mr-2" />
                Home
              </a>
              <a
                className="dropdown-item py-2"
                onClick={() => {
                  history.push("/settings");
                }}
              >
                <i className="fas fa-cog mr-2" />
                Settings
              </a>
              <a
                className="dropdown-item py-2"
                onClick={() => {
                  history.push("/contact-us");
                }}
              >
                <i className="fas fa-user-cog mr-2" />
                Support
              </a>
              <a className="dropdown-item py-2" onClick={logoutUser}>
                <i className="fas fa-sign-out-alt mr-2" />
                Logout
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  ) : (
    <Redirect push to="/" />
  );

  return headerSection;
}

export default Header;
