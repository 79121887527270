const initialState = {
  answerDetails: {},
  faAnswer: {},
  step: 1,
  retakeNumber: 1,
  isSubmitSuccess: false,
  isSaveAnswerSuccess: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_FA_ANSWER":
      return {
        ...state,
        faAnswer: {
          ...state.faAnswer,
          ...action.payload,
        },
        isSubmitSuccess: true,
      };
    case "SAVE_FA_ANSWER":
      return {
        ...state,
        isSubmitSuccess: false,
        isSaveAnswerSuccess: true,
      };
    case "GET_FA_RETAKE_NUMBER":
      return {
        ...state,
        retakeNumber: action.payload,
      };
    case "GET_FA_ANSWERS":
      return {
        ...state,
        answerDetails: action.payload,
      };

    case "INCREMENT_FA_STEP":
      return {
        ...state,
        step: state.step + 1,
        faAnswer: {
          ...state.faAnswer,
          ...action.payload,
        },
      };

    case "DECREMENT_FA_STEP":
      return {
        ...state,
        step: state.step - 1,
      };

    case "RESET_FA":
      return initialState;
    default:
      return state;
  }
};

export default reducer;
