import React, { useEffect } from "react";
import PhysTreeHeader from "../Templates/Header";
import PhysTreeFooter from "../Templates/Footer";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Q1 from "./Q1";
import Q2 from "./Q2";
import Q3 from "./Q3";
import Q4 from "./Q4";
import Q5 from "./Q5";
import Q6 from "./Q6";
import Q7 from "./Q7";

const RiskAssessment = (props) => {
  const dispatch = useDispatch();
  const { lra } = useSelector((state) => ({
    lra: state.lra,
  }));

  const { answer, step } = lra;
  const defaultValues = {
    ...answer,
  };
  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit } = methods;

  const onSubmit = (data) => {
    console.log(data);
  };

  useEffect(() => {
    dispatch({ type: "RESET_LOW_RISK_ASSESSMENT" });
  }, []);

  return (
    <FormProvider {...methods}>
      <div>
        <PhysTreeHeader />
        <form onSubmit={handleSubmit(onSubmit)} className="text-md-left">
          {step === 1 && <Q1 />}
          {step === 2 && <Q2 />}
          {step === 3 && <Q3 />}
          {step === 4 && <Q4 />}
          {step === 5 && <Q5 />}
          {step === 6 && <Q6 />}
          {step === 7 && <Q7 />}
        </form>
        <PhysTreeFooter />
      </div>
    </FormProvider>
  );
};

export default RiskAssessment;
