import * as types from './Actions/ActionTypes/VerifyTypes';

const initState = {
  errorMessage: '',
  statusCode: '',
  type: '',
};

const VerifyReducer = (state = initState, action) => {
  switch (action.type) {
    case types.VERIFY_ERROR_MESSAGE: return { ...state, errorMessage: action.data };
    case types.VERIFY_STATUS_CODE: return { ...state, statusCode: action.data };
    case types.VERIFY_TYPE: return { ...state, type: action.data };
    default: return state;
  }
};

export default VerifyReducer;
