import { isNumeric } from 'jquery';

// Change this to be able to be used by other inputs
const setFormattedZipCode = (data, dataSetter) => {
  if (isNumeric(data)) {
    dataSetter(data);
  } else {
    const newData = data.slice(0, -1);
    dataSetter(newData);
  }
};

const setFormattedPhoneNumber = (data) => {
  let newData = '';
  let noFormat = data.replace(/\D/g, '');
  const areaCode = noFormat.substring(0, 3);
  const middle = noFormat.substring(3, 6);
  const last = noFormat.substring(6, 10);

  if (noFormat.length > 6) {
    newData = `+(${areaCode})${middle}-${last}`;
  } else if (noFormat.length > 3) {
    newData = `+(${areaCode})${middle}`;
  } else if (noFormat.length > 0) {
    newData = `+(${areaCode}`;
  }

  return newData;
};

const setFormattedDOB = (data) => {
  let newData = '';
  let noFormat = data.replace(/\D/g, '');
  const month = noFormat.substring(0, 2);
  const date = noFormat.substring(2, 4);
  const year = noFormat.substring(4, 8);

  if (noFormat.length > 4) {
    newData = `${month}/${date}/${year}`;
  } else if (noFormat.length > 2) {
    newData = `${month}/${date}`;
  } else if (noFormat.length > 0) {
    newData = `${month}`;
  }

  return newData;
};

export {
  setFormattedPhoneNumber,
  setFormattedZipCode,
  setFormattedDOB,
};
