import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
require("dotenv").config({ path: "../.env" });
export const _request = async (method, url, payload = null, headers = true) => {
  let the_headers = {};
  if (headers) {
    the_headers["Content-Type"] = "application/json";
  } else {
    the_headers["Content-Type"] = "multipart/form-data";
  }

  let accessToken = await AsyncStorage.getItem("access_token");
  if (accessToken) {
    the_headers["Authorization"] = "Bearer " + accessToken;
  }

  require('dotenv').config({ path: '../.env' });

  let options = {
    headers: the_headers,
    method: method,
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: url,
    data: payload,
  };

  // if (payload) {
  //   if (method === "get") {
  //     options["params"] = payload;
  //   } else {
  //     options["data"] = payload;
  //   }
  // }

  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};
