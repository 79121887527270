import React from 'react';

function JournalEntry(props) {
  const {
    entryData,
  } = props;

  const {
    date, title, desc, rating, category,
  } = entryData;

  return (
    <div className="row p-3 p-md-4 rounded-20 border mb-2 mx-0 journal-content">
      <div className="order-1 order-md-1 col-8 col-md-2">
        <span className="txt-color-dark">
          {date}
        </span>
      </div>
      <div className="order-3 order-md-2 col-12 col-md-8">
        <p className="p-0 m-0 font-weight-bold">{title}</p>
        <p className="p-0 m-0">{desc}</p>
      </div>
      <div className="order-1 order-md-3 col-8 col-md-2 text-center">
        <span className="txt-color-dark  font-weight-bold">
          <b>Rating:</b>
        </span>
        <p className="p-0 m-0">{category ? rating : 'N/A'}</p>
      </div>
    </div>
  );
}

export default JournalEntry;
