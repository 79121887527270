import React, { useState, memo, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import * as actions from "../../Redux/Actions/LoginAction";
import * as authActions from "../../Redux/Actions/AccessTokenAction";
import { checkEmail } from "../Utility/InputValidation";
import * as api from "../../api/AuthApi";
import { useDispatch } from "react-redux";
require("dotenv").config({ path: "../.env" });
function Login(props) {
  const { setFormTemplate, email, password, setUserId } = props;
  const [isPassHidden, setIsPassHidden] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(async () => {
    dispatch({ type: "RESET_AUTH" });
    dispatch({ type: "RESET_HOME" });
    dispatch({ type: "RESET_LBS" });
    dispatch({ type: "RESET_HISTORY" });
    dispatch({ type: "RESET_FA" });
  }, []);

  const loginSubmitHandler = async () => {
    const payload = {
      email: props.email?.toLowerCase(),
      password: props.password,
      client_id: process.env.REACT_APP_API_CLIENT_ID,
      client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
    };

    let response = await api.login(payload);
    if (response.status === 200) {
      const { access_token } = response.data;
      const { email, id } = response.data.data;
      setUserId(id);
      await AsyncStorage.setItem("access_token", access_token);
      sessionStorage.setItem("access_token", access_token);
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("userId", parseInt(id));
      await AsyncStorage.setItem("userId", parseInt(id));
      dispatch({ type: "SET_IS_AUTH" });
      checkLoginRecords(parseInt(id));
      history.push("/home");
    } else if (response.status === 404) {
      toast.error("Username or password is invalid. Please try again.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const checkLoginRecords = async (id) => {
    let getHasNotLogged3WeeksReponse = await api.getHasNotLogged3Weeks(id);
    if (getHasNotLogged3WeeksReponse.status === 200) {
      if (getHasNotLogged3WeeksReponse.data.data === true) {
        sessionStorage.setItem("isReturningUser", true);
      } else {
        sessionStorage.setItem("isReturningUser", false);
      }
    } else {
      console.log(getHasNotLogged3WeeksReponse.status);
    }
  }

  const togglePasswordVisibility = () => {
    setIsPassHidden(!isPassHidden);
  };

  const forgotPassClickHandler = () => {
    setFormTemplate(1);
  };

  return (
    <div className="login-form-container">
      <form className="login-form">
        <div className="form-group  mb-5">
          <h2 className="h1 font-weight-bold txt-color-dark">Login</h2>
        </div>
        <div className="form-group mb-5">
          <div className="form-label">Email Address</div>
          <input
            name="email-input"
            id="email-input"
            type="text"
            className="form-control login-control"
            placeholder="you@example.com"
            value={email}
            onChange={(event) => props.setEmail(event.target.value)}
          />
          <p style={{ fontSize: 14, color: "red" }}>
            {checkEmail(email) || email === "" ? "" : "Invalid email"}
          </p>
        </div>

        <div className="form-group mb-5">
          <div className="form-label">Password</div>
          <input
            value={password}
            id="password-input"
            type={isPassHidden ? "password" : "text"}
            className="form-control login-control"
            placeholder="password"
            onChange={(event) => props.setPassword(event.target.value)}
          />
          <div className="d-table login-password-show">
            <input
              type="checkbox"
              name=""
              id=""
              className="login-password-show-checkbox"
              onChange={togglePasswordVisibility}
            />
            <span
              className={
                isPassHidden
                  ? "login-password-show-checkbox-label"
                  : "login-password-show-checkbox-label active"
              }
            >
              Show
            </span>
          </div>
        </div>

        <div className="form-group mb-5">
          <button
            type="button"
            className="btn btn-success login-btn"
            onClick={loginSubmitHandler}
            disabled={!checkEmail(email)}
          >
            Login
          </button>
          <a
            style={{ cursor: "pointer" }}
            className="mt-3 txt-color-secondary d-table mx-auto"
            onClick={forgotPassClickHandler}
          >
            Forgot Password?
          </a>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  email: state.login.email,
  password: state.login.password,
  errorMessage: state.login.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  setEmail: (text) => dispatch(actions.setEmail(text)),
  setUserId: (text) => dispatch(actions.setUserId(text)),
  setPassword: (text) => dispatch(actions.setPassword(text)),
  getAccessToken: () => dispatch(authActions.getAccessToken()),
});

Login.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  setEmail: PropTypes.func.isRequired,
  setUserId: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
};

Login.defaultProps = {
  email: "",
  password: "",
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Login));
