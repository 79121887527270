import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ProcedureModal from "../modals/HistoryProcedureModal";
import { useHistory } from "react-router-dom";

const HistoryProcedure = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [procedureModal, isProcedureModalVisible] = useState(false);
  const { isPhaseTwo, history_answer } = useSelector((state) => ({
    isPhaseTwo: state.home.isPhaseTwo,
    history_answer: state.history_answer,
  }));
  const { historyAnswer } = history_answer;
  const { setValue, getValues } = useFormContext();

  const continueHandler = (item) => {
    const finalPrcQ2 = item.reduce(
      (acc, cur) => ({ ...acc, [cur.name]: true }),
      {}
    );
    const payload = {
      ...historyAnswer,
      prcQ1: getValues("prcQ1"),
      prcQ2: finalPrcQ2,
    };
    dispatch({ type: "INCREMENT_HISTORY_STEP", payload: payload });
  };

  const noHandler = () => {
    setValue("prcQ1", false);
    const payload = {
      ...historyAnswer,
      prcQ1: getValues("prcQ1"),
      prcQ2: {},
    };
    dispatch({ type: "INCREMENT_HISTORY_STEP", payload: payload });
  };

  return (
    <main className="container d-flex justify-content-center align-items-center">
      <div className="w-100">
        <div className="card-box mw-100">
          <div className="form-group col-6">
            <h2 className="form-heading-sub">History</h2>
          </div>
          <h4 className="my-md-5 my-4 h4 col-md-10">
            {isPhaseTwo
              ? "Since you’ve been using this PhysioTree tool, have you had any procedures (e.g. Injections, Surgery) for this episode of low back pain?"
              : "Have you had any procedures (e.g. Injections, Surgery) for this episode of low back pain?"}
          </h4>
          <div className="form-row ">
            <div className="form-group col-12" data-toggle="buttons">
              <label className="btn btn-outline-secondary btn-radio btn-fw-90 btn-redirect mr-1">
                <input
                  type="radio"
                  className="text-dark font-weight-bold d-none"
                  onClick={noHandler}
                />
                No
              </label>
              <label className="btn btn-outline-secondary btn-radio btn-fw-90 btn-redirect mr-1">
                <input
                  type="radio"
                  className="text-dark font-weight-bold d-none"
                  onClick={() => {
                    setValue("prcQ1", true);
                    isProcedureModalVisible(true);
                  }}
                />
                Yes
              </label>
            </div>
          </div>

          <ProcedureModal
            isOpen={procedureModal}
            onClose={() => isProcedureModalVisible(false)}
            onContinueHandler={(item) => continueHandler(item)}
          />
        </div>
        <a
          className="btn-prev"
          onClick={() => dispatch({ type: "DECREMENT_HISTORY_STEP" })}
        >
          <i className="fas fa-arrow-left mr-2" />
          Previous
        </a>
      </div>
    </main>
  );
};

export default HistoryProcedure;
