import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ImagingModal from "../modals/ImagingTypeModal";

const ImagingType = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [imagingModal, isImagingModalVisible] = useState(false);
  const { isPhaseTwo, history_answer } = useSelector((state) => ({
    isPhaseTwo: state.home.isPhaseTwo,
    history_answer: state.history_answer,
  }));

  const { historyAnswer } = history_answer;
  const { setValue, getValues } = useFormContext();

  const continueHandler = (item) => {
    const finalImgQ2 = item.reduce(
      (acc, cur) => ({ ...acc, [cur.name]: true }),
      {}
    );
    const payload = {
      ...historyAnswer,
      imgQ1: getValues("imgQ1"),
      imgQ2: finalImgQ2,
    };
    dispatch({ type: "INCREMENT_HISTORY_STEP", payload: payload });
  };

  const noHandler = () => {
    setValue("imgQ1", false);
    const payload = {
      ...historyAnswer,
      imgQ1: getValues("imgQ1"),
      imgQ2: {},
    };
    dispatch({ type: "INCREMENT_HISTORY_STEP", payload: payload });
  };
  return (
    <main className="container d-flex justify-content-center align-items-center">
      <div className="w-100">
        <div className="card-box mw-100">
          <div className="form-group col-6">
            <h2 className="form-heading-sub">History</h2>
          </div>
          <h4 className="my-md-5 my-4 h4 col-md-10">
            {isPhaseTwo
              ? "Since you’ve been using this PhysioTree tool, have you had any imaging (e.g. X-Ray, MRI, CT-Scan) for your low back?"
              : "Have you had any imaging (e.g. X-Ray, MRI, CT-Scan) for your low back?"}
          </h4>

          <div className="form-row ">
            <div className="form-group col-12" data-toggle="buttons">
              <label className="btn btn-outline-secondary btn-radio btn-fw-90 btn-redirect mr-1">
                <input
                  type="radio"
                  className="text-dark font-weight-bold d-none"
                  onClick={noHandler}
                />
                No
              </label>
              <label className="btn btn-outline-secondary btn-radio btn-fw-90 btn-redirect mr-1">
                <input
                  type="radio"
                  autoComplete="off"
                  className="text-dark font-weight-bold d-none"
                  onClick={() => {
                    setValue("imgQ1", true);
                    isImagingModalVisible(true);
                  }}
                />
                Yes
              </label>
            </div>
          </div>
        </div>
        {!isPhaseTwo && (
          <a className="btn-prev" onClick={() => history.goBack()}>
            <i className="fas fa-arrow-left mr-2" />
            Previous
          </a>
        )}
        <ImagingModal
          isOpen={imagingModal}
          onClose={() => isImagingModalVisible(false)}
          onContinueHandler={(item) => continueHandler(item)}
        />
      </div>
    </main>
  );
};

export default ImagingType;
