import { _request } from "./BaseApi";

export const getResults = async (id) => {
  try {
    let url = `api/surveys/get_all_results/?user_id=${id}`;
    let result = await _request("get", url);
    return result;
  } catch (err) {
    return err;
  }
};

export const saveAnswers = async (payload) => {
  try {
    let url = `api/surveys/save_answer/`;
    let result = await _request("post", url, payload);
    return result;
  } catch (err) {
    return err;
  }
};

export const getIsSecondLogin = async (id) => {
  try {
    let url = `api/users/get_is_second_login/?user_id=${id}`;
    let result = await _request("get", url);
    return result;
  } catch (err) {
    return err;
  }
};
