import React, { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as httpMethods from "../../../Shared/Api";
import { useHistory } from 'react-router-dom';
import workoutImg from '../../Images/workout3.png';
import { PhaseTwo } from "../../../Constants/Surveys";
import * as api from "../../../api/HomeApi";


function RP21() {
  const history = useHistory();
  const [retakeNumber, setRetakeNumber] = useState(1);
  const [exerciseProgram, setExerciseProgram] = useState('');

  const saveAnswer = (payload) => {
    httpMethods
      .Post("api/surveys/save_answer/", payload)
      .then(() => {
        history.push({
          pathname: "/results",
          state: {
            category: "Phase2",
            retake_number: payload.retake_number,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRetakeNumber = async () => {
    const id = await AsyncStorage.getItem("userId");

    httpMethods
      .Get(`api/surveys/get_retake_number/?user_id=${id}&category=Phase2`)
      .then((result) => {
        setRetakeNumber(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(async () => {
    getRetakeNumber();

    const id = await AsyncStorage.getItem("userId");
    let response = await api.getResults(id);
    if (response.status === 200) {
      const responseData = JSON.parse(JSON.parse(response.data.data[response.data.data.length-1].results));
      setExerciseProgram(responseData.exercise_program);
    }
  }, []);

  const viewButtonHandler = async () => {
    const answerType = { result: 10 };
    const id = await AsyncStorage.getItem("userId");
    const answer = {
      result: exerciseProgram,
    };
    const payload = {
      user_id: id,
      survey: PhaseTwo,
      retake_number: retakeNumber,
      answer: JSON.stringify(answer),
      answer_type: JSON.stringify(answerType),
    };

    saveAnswer(payload);
  };

  return (
    <div className="site-page container-fluid p-0">
      <main className="container  d-flex justify-content-center align-items-center">
        <div className="w-100">
          <div className="row justify-content-center">
            <div className="col-lg-10">

              <div className="card-box mw-100 p-md-5 ">
                <div className="row justify-content-center">
                  <div className="col-md-9">
                    <div className="text-center mx-auto">
                      <img alt="" src={workoutImg} className="img-fluid  mb-4" />
                      <p className="px-md-5">
                        Keep up the good work with your current Phase I
                        exercises to maximize their effectiveness and we&apos;ll
                        check on you in a few weeks to see if you&apos;re ready
                        to progress to Phase II.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <a
                className="btn btn-primary btn-continue d-table mx-auto mb-5"
                onClick={() => viewButtonHandler()}
              >
                Got It
              </a>

            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default RP21;
