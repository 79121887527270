import React, { useState, memo } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import loginLogo from "../Images/logo_login.svg";
import footerLogo from "../Images/logo-footer.svg";
import welcomeLogo from "../Images/logo-login.svg";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ForgotPasswordVerify from "./ForgotPasswordVerify";
import LoginForm from "./LoginForm";
import ForgotPasswordNew from "./ForgotPasswordNew";
import LoginVerifyModal from "./LoginVerifyModal";
import "react-toastify/dist/ReactToastify.css";

function LoginTemplate() {
  const [formTemplate, setFormTemplate] = useState(0);

  let form;
  switch (formTemplate) {
    case 0:
      form = <LoginForm {...{ setFormTemplate }} />;
      break;
    case 1:
      form = <ForgotPasswordForm {...{ setFormTemplate }} />;
      break;
    case 2:
      form = <ForgotPasswordVerify {...{ setFormTemplate }} />;
      break;
    case 3:
      form = <ForgotPasswordNew {...{ setFormTemplate }} />;
      break;

    default:
      form = <LoginForm {...{ setFormTemplate }} />;
      break;
  }

  return (
    <div>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row">
          <div className="d-md-block col-12 col-md-6 login-container">
            <div className="login-brand">
              <a
                href="https://physiotree.com/"
                className="txt-color-primary font-weight-bold"
              >
                <img src={loginLogo} alt="" />
              </a>
            </div>

            {form}

            <div className="login-footer">
              <img src={footerLogo} alt="" className="d-table mx-auto" />
              <div className="w-100 d-flex justify-content-center mt-3">
                <span className="txt-color-secondary mr-3">
                  Don&apos;t have Account?
                </span>
                <a
                  href="/signup"
                  className="txt-color-primary font-weight-bold"
                >
                  Sign Up Here
                </a>
              </div>
            </div>
          </div>

          <div className="d-none d-md-block col-12 col-md-6 login-container bg-color-dark login-cherry-container">
            <div className="login-cherry-content d-block d-md-flex align-items-center">
              <div className="w-100 p-lg-5 p-3">
                <img
                  src={welcomeLogo}
                  alt=""
                  style={{ maxWidth: 95 }}
                  className="w-100"
                />
                <h2 className="txt-color-primary mt-5 mb-4 font-weight-bold welcome-heading">
                  Welcome to PhysioTree
                </h2>

                <p className="txt-color-white font-weight-lighter welcome-message">
                  Effectively assess your low
                  <br />
                  back pain within minutes with
                  <br />
                  our simple tool.
                </p>
              </div>
            </div>
          </div>

          {/* MODALS */}
          <LoginVerifyModal {...{ setFormTemplate }} />
        </div>
      </div>
    </div>
  );
}

export default connect(null, null)(memo(LoginTemplate));
