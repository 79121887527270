import React from "react";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const Tooltip = ({ text }) => {
  return (
    <>
      <OverlayTrigger
        placement={"bottom"}
        overlay={
          <Popover id={`popover-positioned-bottom`}>
            <Popover.Content>
              <div>
                <p>{text}</p>
              </div>
            </Popover.Content>
          </Popover>
        }
      >
        <i className="fas fa-info-circle fa-xs txt-color-secondary ml-2" />
      </OverlayTrigger>
    </>
  );
};

export default Tooltip;
