import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { options } from "./constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const Activity2 = () => {
  const dispatch = useDispatch();
  const [q5, setQ5] = useState(0);
  const [selected, isSelected] = useState(false);

  const { register, watch } = useFormContext();

  const { faAnswer, isPhaseTwo } = useSelector((state) => ({
    isPhaseTwo: state.home.isPhaseTwo,
    faAnswer: state.fa.faAnswer,
  }));

  const watchQ4 = watch("q4");

  const continueHandler = () => {
    const payload = {
      ...faAnswer,
      q4: watchQ4,
      q5: q5,
    };
    dispatch({ type: "INCREMENT_FA_STEP", payload: payload });
  };

  const onChange = (item) => {
    setQ5(item.value);
    isSelected(true);
  };

  return (
    <div className="site-page container-fluid p-0">
      <main className="container  d-flex justify-content-center align-items-center">
        <div className="w-100">
          <div className="card-box mw-100">
            <div className="form-row">
              <div className="form-group col-6">
                <h2 className="form-heading-sub">Functional Assessment</h2>
              </div>
              <div className="form-group col-6 d-flex justify-content-end align-items-center">
                <p className="txt-bold d-block d-lg-none mb-2 txt-md">
                  Activity 2 of 3
                </p>
              </div>
            </div>
            <div className="form-row">
              <div className="col-12">
                <p className="form-title-sub highligter">
                  Please identify up to 3 important activities that you are
                  unable to do or are having difficulty with as a result of your
                  low back pain.
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip">
                        For example: If you choose swimming, and your back pain
                        is limiting your swimming by 50%, then you'd mark as 5.
                      </Tooltip>
                    }
                  >
                    <a>
                      <i className="fas fa-info-circle fa-s txt-color-secondary ml-2" />
                    </a>
                  </OverlayTrigger>
                </p>
                <p className="txt-bold d-none d-lg-block">
                  Activity&nbsp; 2 &nbsp;of 3
                </p>
              </div>
              <div className="col-12 col-lg-3 mb-4 mt-2">
                <div className="form-group">
                  <label htmlFor="enter_text" className="form-label mb-0">
                    Activity #2
                  </label>
                  <input
                    maxLength={32}
                    className="form-control"
                    placeholder="Enter Text"
                    {...register("q4", {
                      required: true,
                    })}
                    readOnly={isPhaseTwo ? true : false}
                  />
                  {watchQ4 === "" && (
                    <span style={{ fontSize: 14, color: "red" }}>
                      Please enter the activity name
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12">
                <p className="form-title-head">
                  Then, based on how you feel today, rate your ability to
                  perform the activity.
                </p>
              </div>
              <div className=" d-lg-block d-none">
                <div
                  className="form-group col-12 d-flex justify-content-between"
                  data-toggle="buttons"
                >
                  {options.map((item) => {
                    return (
                      <label className="btn btn-outline-secondary btn-radio btn-fw-70 mr-3 btn-redirect">
                        <input
                          type="radio"
                          className="text-dark font-weight-bold d-none"
                          name="q5"
                          onChange={() => {
                            onChange(item);
                          }}
                        />
                        {item.value}
                      </label>
                    );
                  })}
                </div>
                <div className="form-group col-12 mt-5 py-3 border-bottom">
                  <div className="row">
                    <div className="col-6">
                      <p className="txt-color-primary font-weight-bold text-left m-0">
                        Unable to perform activity
                      </p>
                    </div>
                    <div className="col-6">
                      <p className="txt-color-primary font-weight-bold text-right m-0">
                        Able to perform activity
                        <br />
                        at the same level as before injury
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100 d-lg-none d-block">
                <div className="form-group col-12">
                  <div className="w-100">
                    <span className="perform_rate_value">{q5}</span>
                  </div>
                  <div className="w-100 d-flex justify-content-center align-items-center">
                    <input
                      type="range"
                      min="0"
                      max="10"
                      defaultValue={0}
                      onChange={(event) => {
                        setQ5(parseInt(event.target.value));
                        isSelected(true);
                      }}
                      style={{ width: "90%" }}
                    />
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-between align-items-center mt-4">
                  <small className="txt-color-primary">
                    Unable to perform activity
                  </small>
                  <small className="txt-color-primary text-right">
                    Able to perform activity
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-auto mr-auto">
              <a
                className="btn-prev btn-xs btn"
                onClick={() => dispatch({ type: "DECREMENT_FA_STEP" })}
              >
                <i className="fas fa-arrow-left mr-2" />
                Previous
              </a>
            </div>
            <div className="col-auto">
              <button
                type="button"
                className="btn btn-sm btn-next  "
                onClick={continueHandler}
                disabled={selected !== true || watchQ4 === "" ? true : false}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Activity2;
