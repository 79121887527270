import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import * as httpMethods from '../../Shared/Api';
import * as actions from '../../Redux/Actions/ForgotPassAction';

function ForgotPasswordNew(props) {
  const [newPassword, setNewPassword] = useState('');
  const [hidePassword, setHidePassword] = useState(true);
  const {
    setFormTemplate,
    userId,
  } = props;

  const backToFormHandler = () => {
    setFormTemplate(0);
  };

  const submitChangePassRequest = (payload) => {
    const request = httpMethods
      .Post('api/users/forgot_password/', payload)
      .then(() => {
        setFormTemplate(0);
      })
      .catch((error) => {
        console.log(error);
      });

    return request;
  };

  const submitHandler = () => {
    const payload = {
      user_id: userId,
      new_password: newPassword,
    };
    submitChangePassRequest(payload);
  };

  return (
    <div className="login-form-container">
      <form className="login-form">

        <div className="form-group  mb-5">
          <h2 className="h1 font-weight-bold txt-color-dark">New Password</h2>
          <span className="form-title-head">
            Please enter your new password.
          </span>
        </div>

        <div className="form-group mb-5">
          <label htmlFor="new-password-input" className="form-label">
            Password
          </label>
          <input
            type={hidePassword ? 'password' : 'text'}
            className="form-control login-control"
            placeholder="password"
            id="new-password-input"
            value={newPassword}
            onChange={(event) => {
              setNewPassword(event.target.value);
            }}
          />

          <div className="d-table login-password-show">
            <input
              type="checkbox"
              name=""
              id=""
              className="login-password-show-checkbox"
              onClick={() => {
                setHidePassword(!hidePassword);
              }}
            />
            <span
              className="login-password-show-checkbox-label"
            >
              Show
            </span>
          </div>
        </div>

        <div className="form-group mb-5">
          <a
            href="#"
            className="btn btn-primary login-btn"
            data-aim="verify-alert"
            id="alert-btn-open"
            onClick={submitHandler}
          >
            Submit
          </a>
        </div>

        <div className="form-group mb-5">
          <a style={{ cursor: 'pointer' }} className="txt-color-secondary" onClick={() => { backToFormHandler(); }}>
            <i className="fas fa-arrow-left mr-3" />
            Back to Login
          </a>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => (
  {
    userId: state.forgotPass.userId,
    errorMessage: state.forgotPass.errorMessage,
  }
);

const mapDispatchToProps = (dispatch) => (
  { verifyForgotPassEmail: (payload) => dispatch(actions.verifyForgotPassEmail(payload)) }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(ForgotPasswordNew));
