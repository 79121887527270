import { _request } from "./BaseApi";

export const getSurveyResults = async (id, category, retakeNumber) => {
  try {
    let url = `api/surveys/get_results/?user_id=${id}&retake_number=${retakeNumber}&category=${category}`;
    let result = await _request("get", url);
    return result;
  } catch (err) {
    return err;
  }
};

export const getCustomSolutionPercent = async (id, category, retakeNumber) => {
  try {
    let url = `api/surveys/get_custom_solution_percent/?user_id=${id}&category=${category}&retake_number=${retakeNumber}`;
    let result = await _request("get", url);
    return result;
  } catch (err) {
    return err;
  }
};

export const getFunctionalAssessment = async (id, category, retakeNumber) => {
  try {
    let url = `api/surveys/get_functional_assessments/?user_id=${id}&retake_number=${retakeNumber}&category=${category}`;
    let result = await _request("get", url);
    return result;
  } catch (err) {
    return err;
  }
};
