import * as types from './Actions/ActionTypes/MedicationTypes';

const initState = {
  q1: '',
  q2: '',
};

const MedicationReducer = (state = initState, action) => {
  switch (action.type) {
    case types.MEDICATION_Q1: return { ...state, q1: action.data };
    case types.MEDICATION_Q2: return { ...state, q2: action.data };
    default: return state;
  }
};

export default MedicationReducer;
