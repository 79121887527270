const initialState = {
  answer: {},
  step: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_ANSWER":
      return {
        ...state,
        answer: {
          ...state.answer,
          ...action.payload,
        },
      };
    case "INCREMENT":
      return {
        ...state,
        step: state.step + 1,
        answer: {
          ...state.answer,
          ...action.payload,
        },
      };
    case "DECREMENT":
      return {
        ...state,
        step: state.step - 1,
      };

    case "RESET_LOW_RISK_ASSESSMENT":
      return initialState;

    default:
      return state;
  }
};

export default reducer;
