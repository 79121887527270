const initialState = {
  customSolutionList: [],
  educationList: [],
  exerciseDetails: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CUSTOM_SOLUTIONS":
      return {
        ...state,
        customSolutionList: action.custom_solution_payload,
        educationList: action.education_payload,
      };
    case "SET_EXERCISE_DETAILS": {
      return {
        ...state,
        exerciseDetails: action.payload,
      };
    }
    case "RESET_CUSTOM_SOLUTION":
      return initialState;

    default:
      return state;
  }
};

export default reducer;
