import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const HistoryTreatmentModal = ({ isOpen, onClose, onContinueHandler }) => {
  const [array, setArray] = useState([]);
  const historyTreatment = [
    {
      id: 1,
      title: "Physical Therapy",
      name: "physicalThrp",
    },
    {
      id: 2,
      title: "Chiropractic",
      name: "chiropractic",
    },
    {
      id: 3,
      title: "Massage",
      name: "massage",
    },
    {
      id: 4,
      title: "Acupuncture",
      name: "acupuncture",
    },
  ];

  let prcTrtQ2 = [];
  const toggleHandler = (e, item) => {
    if (e.target.checked) {
      setArray([...array, item]);
    } else {
      prcTrtQ2 = prcTrtQ2.filter((type) => type.id != item.id);
      setArray(prcTrtQ2);
    }
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        animation={true}
        centered
        contentClassName="rounded-20"
      >
        <div className="modal-content rounded-20">
          <div className="modal-body p-5   ">
            <a className="close" onClick={onClose}>
              <i className="fas fa-times txt-color-dark-grey" />
            </a>
            <div className="form-group">
              <h4 className=" h4 font-weight-bold mt-md-5 mb-4 ">
                Please Select Type(s) of Imaging Received:
              </h4>
            </div>

            <div className="form-group" data-toggle="buttons">
              {historyTreatment.map((item) => {
                return (
                  <label
                    className="btn btn-outline-secondary btn-radio mr-1 btn-block"
                    key={item.name}
                  >
                    <input
                      type="checkbox"
                      autoComplete="off"
                      className="text-dark font-weight-bold d-none"
                      onChange={(e) => {
                        toggleHandler(e, item);
                      }}
                    />
                    {item.title}
                  </label>
                );
              })}
            </div>
            <div className="form-group mt-5">
              <div className="d-block text-right ">
                <button
                  type="button"
                  className="btn btn-success btn-md rounded-10 mt-md-2"
                  disabled={array.length === 0 ? true : false}
                  onClick={() => onContinueHandler(array)}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HistoryTreatmentModal;
