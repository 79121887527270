import React, { useEffect } from "react";
import PhysTreeHeader from "../Templates/Header";
import PhysTreeFooter from "../Templates/Footer";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ImagingType from "./components/ImagingType";
import Medication from "./components/Medications";
import HistoryProcedure from "./components/HistoryProcedure";
import HistoryTreatment from "./components/HistoryTreatment";
import { useHistory } from "react-router-dom";
import * as api from "../../api/HistoryApi";
import * as actions from "../../new-redux/actions/History";
import AsyncStorage from "@react-native-async-storage/async-storage";

const History = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { history_answer, isPhaseTwo } = useSelector((state) => ({
    history_answer: state.history_answer,
    isPhaseTwo: state.home.isPhaseTwo,
  }));

  const { step, isSubmitSuccess, isSaveAnswerSuccess } = history_answer;

  const methods = useForm();

  useEffect(async () => {
    dispatch({ type: "RESET_HISTORY" });
    const id = await AsyncStorage.getItem("userId");
    if (isPhaseTwo) {
      let response = await api.getRetakeNumber(id);
      dispatch({ type: "GET_RETAKE_NUMBER", payload: response.data.data });
    } else {
      let response = await api.getPhase1RetakeNumber(id);
      dispatch({ type: "GET_RETAKE_NUMBER", payload: response.data.data });
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccess) {
      dispatch(actions.saveHistoryAnswer());
    }
  }, [isSubmitSuccess]);

  useEffect(() => {
    if (isSaveAnswerSuccess) {
      if (isPhaseTwo) {
        history.push("/functional-assessment");
      } else {
        history.push("/risk-assessment");
      }
    }
  }, [isSubmitSuccess, isSaveAnswerSuccess]);

  return (
    <FormProvider {...methods}>
      <div>
        <PhysTreeHeader />

        <div className="site-page container-fluid p-0">
          {step === 1 && <ImagingType />}
          {step === 2 && <Medication />}
          {step === 3 && <HistoryProcedure />}
          {step === 4 && <HistoryTreatment />}
        </div>
        <PhysTreeFooter />
      </div>
    </FormProvider>
  );
};

export default History;
