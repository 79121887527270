import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import * as httpMethods from '../Shared/Api';
import PhysTreeHeader from './Templates/Header';
import PhysTreeFooter from './Templates/Footer';

import JournalEntry from './Journal/JournalEntry';
import NewJournalModal from './Journal/NewJournalModal';
import JournalDownloadModal from './Journal/JournalDownloadModal';
import JournalDownloadDoneModal from './Journal/JournalDownloadDoneModal';
import JournalShareDoneModal from './Journal/JournalShareDoneModal';
import JournalShareModal from './Journal/JournalShareModal';

function Journal() {
  const [journalList, setJournalList] = useState([]);
  const loggedUserId = sessionStorage.getItem('userId');
  const getUserJournalList = () => {
    const payload = {
      user_id: loggedUserId,
      category: 'Phase1',
    };

    httpMethods
      .Post('api/journal/get_journal/', payload)
      .then((response) => {
        const journalListResponse = response.data.data;
        setJournalList([...journalListResponse]);
      })
      .catch(() => {
        toast.error('Failed to retrieve user journal list. Please try refresh page.', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  useEffect(() => {
    getUserJournalList();
  }, []);

  const listJournalByCategory = (journalCategory) => journalList.map((journalEntry) => {
    const {
      title, id, rating, category,
    } = journalEntry;

    if (category === journalCategory) {
      const date = moment(new Date(journalEntry.date_saved)).format('DD MMM YYYY'); // FORMAT --> 04 May 2021
      const desc = journalEntry.notes;
      const journalEntryData = {
        date, title, desc, rating, category,
      };

      return <JournalEntry entryData={journalEntryData} key={id} />;
    }
    return <span />;
  });

  const doesCategoryExist = (categoryToCheck) => journalList.some(
    (journalEntry) => journalEntry?.category === categoryToCheck,
  );

  const categorizeJournalList = (journalTitle, journalCategory) => {
    if (doesCategoryExist(journalCategory)) {
      return (
        <>
          <div className="row mb-3 mt-5">
            <div className="col-8">
              <h3 className="txt-color-dark h5">
                {journalTitle}
              </h3>
            </div>
            <div className="col-4 justify-content-end" />
          </div>
          {listJournalByCategory(journalCategory)}
        </>
      );
    }
    return <span />;
  };

  return (
    <div>
      <ToastContainer />
      <PhysTreeHeader />
      <div className="site-page container mt-5">

        <div className="row mb-5">
          <div className="col-md-6 col-12">
            <h2 className="txt-color-dark h1 font-weight-bold mb-5">Journal</h2>
          </div>
          <div className="col-12 col-md-6">
            <a data-toggle="modal" data-target="#journal-share" id="alert-btn-open" className="btn btn-outline-secondary rounded-10 px-4 py-3 mr-2 float-right mb-2">
              <i className="fas fa-share-alt mr-2" />
              Share
            </a>

            <a data-toggle="modal" data-target="#journal-download" id="alert-btn-open" className="btn btn-outline-secondary rounded-10 px-4 py-3 mr-2 float-right mb-2">
              <i className="fas fa-download mr-2" />
              Download
            </a>

            <a data-toggle="modal" data-target="#new-journal" id="alert-btn-open" className="btn btn-outline-secondary rounded-10 px-4 py-3 mr-2 float-right mb-2">
              + New Journal Entry
            </a>

          </div>
        </div>
        {categorizeJournalList('Lower Back Program', 'Phase1')}
        {categorizeJournalList('Lower Back Program Phase II', 'Phase2')}
        {categorizeJournalList('General', null)}
      </div>
      <PhysTreeFooter />

      <JournalDownloadModal journalList={journalList} />
      <JournalShareModal journalList={journalList} />
      <JournalShareDoneModal />
      <JournalDownloadDoneModal />
      <NewJournalModal getUserJournalList={getUserJournalList} />
    </div>

  );
}

export default Journal;
