import React from 'react';
import footerLogo from '../Images/logo-footer.svg';

function Footer() {
  return (
    <footer className="site-footer">
      <p className="copyright">
        <img src={footerLogo} alt="" />
        <br />
        <br />
        © 2003-2020 PhysioTree
      </p>
    </footer>
  );
}

export default Footer;
