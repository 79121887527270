import React, { memo } from "react";
import { connect } from "react-redux";

import { ToastContainer } from "react-toastify";
import PhysTreeHeader from "../Templates/Header";
import PhysTreeFooter from "../Templates/Footer";

import EnableNotifModal from "./Modal/EnableNotifModal";
import EnableNotifNoModal from "./Modal/EnableNotifNoModal";

function Disclaimer() {
  return (
    <div>
      <PhysTreeHeader />
      <ToastContainer />

      <main className="site-page container d-flex justify-content-center align-items-center">
        <div className="form-box border-0 shadow-none">
          <div className="form-group mb-5 d-flex">
            <h2 className="h1 font-weight-bold txt-color-dark">
              <i className="fas fa-exclamation-triangle txt-color-primary mr-3" />
              911 Disclaimer
            </h2>
          </div>
          <p className="txt-color-dark mb-4" style={{ fontSize: 18 }}>
            If this is a medical emergency, or if you are experiencing ANY of
            the following symptoms:
          </p>
          <ul className="mb-4">
            <li>
              Chest pain or shortness of breath for which your physician is
              unaware.
            </li>
            <li>
              Recent change in mental status, confusion, or memory for which
              your physician is unaware.
            </li>
            <li>
              New difficulty speaking for which your physician is unaware.
            </li>
            <li>An intention to harm yourself or others.</li>
          </ul>
          <p>Then please stop and call 911.</p>
          <a
            className="btn btn-primary btn-continue d-table mx-auto mt-4"
            data-target="#enable-notif"
            data-toggle="modal"
          >
            I am not experiencing any of the above and would like to proceed
          </a>
        </div>
      </main>

      <PhysTreeFooter />
      <EnableNotifModal />
      <EnableNotifNoModal />
    </div>
  );
}

export default connect(null, null)(memo(Disclaimer));
