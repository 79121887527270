/* eslint-disable react/no-children-prop */
import "bootstrap";
import "./App.css";
import "./Custom.css";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import instance from "./Shared/AxiosInstance";

import Login from "./Components/Login/LoginTemplate";
import Signup from "./Components/Signup/Signup";
import Plan from "./Components/Plan/Plan";
import Pricing from "./Components/Plan/Pricing";
import ContactUs from "./Components/ContactUs";
import SuccessContactUs from "./Components/SuccessContactUs";
import PaymentSuccess from "./Components/Plan/Success";
import Disclaimer from "./Components/Disclaimer/Disclaimer";

// import Process from './Components/Process';
// TEMPORARILY DISABLED UNTIL NEW DESIGN IS IMPLEMENTED
import Welcome from "./Components/Welcome";

import LowBackAlgorithm from "./Components/LowBackAlgorithm";
import Journal from "./Components/Journal";
import Settings from "./Components/Settings";
/*import SolutionResults from "./Components/SolutionResults";
 import LowerBackStart from "./Components/LowerBackStart";
import History from "./Components/History";
import RiskAssessment from "./Components/RiskAssessment"; 
import Assessment from "./Components/Assessment";*/
// Phase 2
import TreatmentEvaluation from "./Components/PhaseTwo/TreatmentEvaluation";
import CompletelyRecovered from "./Components/PhaseTwo/CompletelyRecovered";

/* newly  added */
import NewVerify from "./Components/Signup/NewVerify/index";
import PrivacyPolicy from "./Components/Signup/PrivacyPolicy";
import TermsAndConditions from "./Components/Signup/TermsAndConditions";
/* home dashboard */
import Dashboard from "./Components/NewDashboard/Dashboard";
/* low back risk assessment */
import LBRAssessment from "./Components/NewLBRAssessment/index";
/* custom solutions */
import NewCustomSolution from "./Components/NewCustomSolution/index";
/* history */
import ExperienceLowBackPain from "./Components/NewHistory/index";
import FollowUp from "./Components/NewHistory/FollowUp";
import LowerBackStart from "./Components/NewHistory/LowerBackStart";
import History from "./Components/NewHistory/History";
/* functional assessment */
import FunctionalAssessment from "./Components/NewFunctionalAssessment/index.js";
/* results */
import NewResults from "./Components/NewResults/index";
instance.interceptors.request.use(
  async (configuration) => {
    const config = configuration;
    const accessToken = await AsyncStorage.getItem("access_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  // TODO: VERY IMPORANT: Refactor code to use redux hooks
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/" exact component={Login} />
        <Route path="/home" exact component={Dashboard} />
        <Route path="/signup" exact component={Signup} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route
          path="/terms-and-conditions"
          exact
          component={TermsAndConditions}
        />
        <Route path="/plan" exact component={Plan} />
        <Route path="/pricing" exact component={Pricing} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/success-contact-us" component={SuccessContactUs} />
        <Route path="/payment-success" exact component={PaymentSuccess} />
        <Route path="/disclaimer" component={Disclaimer} />
        <Route path="/verify" component={NewVerify} />
        {/* <Route path="/process" component={Process} /> */}
        <Route path="/low-back-algorithm" exact component={LowBackAlgorithm} />
        <Route path="/welcome" exact component={Welcome} />

        {/* added new custom solution */}
        <Route
          path="/custom-solution/:category/:retake_number"
          exact
          component={NewCustomSolution}
        />
        {/* old custom solution 
        <Route path="/custom-solution" exact component={CustomSolution} />*/}
        <Route path="/journal" exact component={Journal} />
        <Route path="/settings" exact component={Settings} />
        <Route path="/phase-two/evaluate" component={TreatmentEvaluation} />
        <Route path="/phase-two/recovered" component={CompletelyRecovered} />
        {/* new low back risk assessment */}
        <Route path="/risk-assessment" exact component={LBRAssessment} />
        {/* new history */}
        <Route path="/pain-warning" exact component={ExperienceLowBackPain} />
        <Route path="/follow-up" exact component={FollowUp} />
        <Route path="/lower-back-start" exact component={LowerBackStart} />
        <Route path="/history" exact component={History} />
        {/* new functional assessment  */}
        <Route
          path="/functional-assessment"
          exact
          component={FunctionalAssessment}
        />
        {/* new results */}
        <Route path="/results" exact component={NewResults} />
      </Switch>
    </Router>
  );
}

export default App;
{
  /*    old pain warning page */
}

{
  /*   <Route
          path="/pain-warning"
          exact
          children={<PainWarningPage isPhaseTwo={false} />}
        /> 
           
          
        <Route path="/lower-back-start" component={LowerBackStart} />
           <Route
          path="/history"
          exact
          children={<History isPhaseTwo={false} />}
        />  
          <Route
          path="/phase-two/retake"
          exact
          children={<RiskAssessment isPhaseTwo />}
        />
          <Route
          path="/phase-two/history"
          exact
          children={<History isPhaseTwo />}
        />*/
}

{
  /*  Phase Two */
}
{
  /*  <Route
          path="/phase-two/initial"
          exact
          children={<PainWarningPage isPhaseTwo />}
        />
            <Route path="/phase-two/follow-up" component={PhaseTwoFollowUp} /> */
}
{
  /*    <Route
          path="/functional-assessment"
          exact
          children={<Assessment isPhaseTwo={false} />}
        /> 
           <Route
          path="/phase-two/functional-assessment"
          exact
          children={<Assessment isPhaseTwo />}
        /> 
           <Route path="/result" exact component={SolutionResults} />
              <Route path="/phase-two/result" exact component={PhaseTwoResult} />*/
}
