import * as types from './ActionTypes/LoginTypes';

export const setEmail = (data) => (
  {
    type: types.LOGIN_EMAIL,
    data,
  }
);

export const setFirstName = (data) => (
  {
    type: types.LOGIN_FIRST_NAME,
    data,
  }
);

export const setPassword = (data) => (
  {
    type: types.LOGIN_PASSWORD,
    data,
  }
);

export const errorMessageReturn = (data) => (
  {
    type: types.LOGIN_ERROR_MESSAGE,
    data,
  }
);

export const setUserId = (data) => (
  {
    type: types.LOGIN_USER_ID,
    data,
  }
);

// TODO: REMOVE IF NOT NEEDED. MOVED FUNCTION TO LoginForm.jsx SINCE GLOBALITY ISN'T NEEDED

// const loginSuccessResponse = (dispatch, response) => {
//   if (response.data) {
//     dispatch(setUserId(response.data.data.id));
//   } else {
//     dispatch(setUserId(''));
//   }
// };

// const loginErrorResponse = (dispatch, error) => {
//   setTimeout(() => {
//     dispatch(errorMessageReturn(error.response.data.message));
//   }, 1500);
// };

// export const loginSubmit = (payload) => {
//   const loginRequest = (dispatch) => {
//     const login = httpMethods
//       .Post('api/users/login/', payload)
//       .then((response) => {
//         loginSuccessResponse(dispatch, response);
//       })
//       .catch((error) => {
//         loginErrorResponse(dispatch, error);
//       });

//     return login;
//   };

//   return loginRequest;
// };
