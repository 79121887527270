import * as types from './Actions/ActionTypes/LowerBackStartTypes';

const initState = {
  q1: '',
  q2: 1,
  q3: '',
};

const LowerBackStartReducer = (state = initState, action) => {
  switch (action.type) {
    case types.LOWER_BACK_START_Q1: return { ...state, q1: action.data };
    case types.LOWER_BACK_START_Q2: return { ...state, q2: action.data };
    case types.LOWER_BACK_START_Q3: return { ...state, q3: action.data };
    default: return state;
  }
};

export default LowerBackStartReducer;
