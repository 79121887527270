import React, { useEffect } from "react";
import QuestionProgressBar from "../Templates/QuestionProgressBar";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";

const Q5 = () => {
  const dispatch = useDispatch();
  const { register, setValue, getValues } = useFormContext();

  useEffect(() => {
    register("q5", { required: true });
  }, []);

  const onChange = (e) => {
    setValue("q5", e);
    dispatch({ type: "INCREMENT", payload: getValues() });
  };

  return (
    <div className="site-page container-fluid p-0">
      <QuestionProgressBar category={0} />
      <main className="container  d-flex justify-content-center align-items-center">
        <div className="w-100">
          <div className="card-box mw-100">
            <form>
              <div className="form-row">
                <div className="form-group">
                  <h2 className="form-heading-sub">Low Back Risk Assessment</h2>
                </div>
                <div className="form-group col-6 d-flex justify-content-end align-items-center">
                  <p className="txt-color-dark-grey d-block d-lg-none m-0">
                    Question 5 of 7
                  </p>
                </div>
              </div>

              <div className="form-row">
                <div className="col-12">
                  <p className="txt-color-dark-grey d-none d-lg-block">
                    Question 5 of 7
                  </p>
                  <p className="form-title-head">
                    It&apos;s not really safe for a person with a condition like
                    mine to be physically active.
                  </p>
                </div>
                <div className="form-group col-12" data-toggle="buttons">
                  <label className="btn btn-sm btn-outline-secondary btn-radio btn-md mr-1 btn-redirect">
                    <input
                      type="radio"
                      autoComplete="off"
                      className="text-dark font-weight-bold d-none"
                      value={0}
                      name="q5"
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                    Strongly Disagree
                  </label>

                  <label className="btn btn-sm btn-outline-secondary btn-radio btn-md mr-1 btn-redirect">
                    <input
                      type="radio"
                      autoComplete="off"
                      className="text-dark font-weight-bold d-none"
                      value={1}
                      name="q5"
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                    Disagree
                  </label>

                  <label className="btn btn-sm btn-outline-secondary btn-radio btn-md mr-1 btn-redirect">
                    <input
                      type="radio"
                      autoComplete="off"
                      className="text-dark font-weight-bold d-none"
                      value={2}
                      name="q5"
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                    Agree
                  </label>

                  <label className="btn btn-sm btn-outline-secondary btn-radio btn-md mr-1 btn-redirect">
                    <input
                      type="radio"
                      autoComplete="off"
                      className="text-dark font-weight-bold d-none"
                      value={3}
                      name="q5"
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                    Strongly Agree
                  </label>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-12 col-md-4" />
              </div>
            </form>
          </div>

          <a
            className="btn-prev btn-xs btn"
            onClick={() => dispatch({ type: "DECREMENT" })}
          >
            <i className="fas fa-arrow-left mr-2" />
            Previous
          </a>
        </div>
      </main>
    </div>
  );
};

export default Q5;
