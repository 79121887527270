import * as httpMethods from "../../Shared/Api";
import * as types from "./ActionTypes/VerifyCodeTypes";

export const errorMessageReturn = (data) => ({
  type: types.VERIFY_CODE_ERROR_MESSAGE,
  data,
});

export const setStatusCode = (data) => ({
  type: types.VERIFY_CODE_STATUS_CODE,
  data,
});

const verifyCodeSuccessResponse = (dispatch, response) => {
  dispatch(errorMessageReturn(null));
  if (response.data) {
    dispatch(setStatusCode(response.data.status));
  } else {
    dispatch(setStatusCode(""));
  }
};

const verifyCodeErrorResponse = (dispatch, error) => {
  dispatch(errorMessageReturn(error.response.data.message));
};

export const verifyCodeSubmit = (payload) => {
  const verifyCodeRequest = (dispatch) => {
    dispatch({ type: "RESET_VERIFY" });
    const verifyCode = httpMethods
      .Post("api/verifications/verify_code/", payload)
      .then((response) => {
        verifyCodeSuccessResponse(dispatch, response);
      })
      .catch((error) => {
        dispatch({ type: "VERIFY_CODE_ERROR" });
        verifyCodeErrorResponse(dispatch, error);
      });

    return verifyCode;
  };

  return verifyCodeRequest;
};
