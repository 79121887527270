import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as httpMethods from "../../../Shared/Api";
import ComingSoonModal from '../../ComingSoonModal';
import consultImg from '../../Images/consult.png';
import ctaImg from '../../Images/cta_img.png';
import { PhaseTwo } from "../../../Constants/Surveys";
import * as api from "../../../api/HistoryApi";

function RP22() {
  const history = useHistory();
  
  const saveAnswer = (payload) => {
    httpMethods
      .Post("api/surveys/save_answer/", payload)
      .then(() => {
        history.push({
          pathname: "/results",
          state: {
            category: "PT",
            retake_number: payload.retake_number,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const viewButtonHandler = async () => {
    const answerType = { result: 10 };
    const id = await AsyncStorage.getItem("userId");
    const getRetakeNumberResponse = await api.getPTRetakeNumber(id);
    const answer = {
      result: "See a Physical Therapist",
    };
    const payload = {
      user_id: id,
      survey: PhaseTwo,
      retake_number: getRetakeNumberResponse.data.data,
      answer: JSON.stringify(answer),
      answer_type: JSON.stringify(answerType),
    };

    saveAnswer(payload);
  };

  return (
    <div className="site-page container-fluid p-0">
      <main className="container  d-flex justify-content-center align-items-center">
        <div className="w-100">
          <div className="row justify-content-center">
            <div className="col-lg-10">

              <div className="card-box mw-100 p-md-5 ">
                <div className="row justify-content-center">
                  <div className="col-md-9">
                    <div className="text-center mx-auto">
                      <img src={consultImg} alt="" className="img-fluid  mb-4" />
                      <p className="px-md-5">
                        It seems like you&apos;re not improving at the rate we&apos;d expect.
                        We recommend that you follow up with a physical therapist
                        for a complete evaluation to address your low back pain.
                      </p>
                      <a
                        style={{ cursor: 'pointer' }}
                        className="btn btn-primary btn-continue d-table mx-auto mt-5"
                        onClick={viewButtonHandler}
                      >
                        View Results
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-box mw-100 p-md-5 ">
                <div className="row">
                  <div className="col-12 col-md-5 mb-3">
                    <img src={ctaImg} alt="" className="d-block mx-auto " />
                  </div>
                  <div className="col-12 col-md-7 d-flex align-items-center">
                    <div className="d-block mx-auto mx-md-0 text-center text-md-left  ">
                      <h3 className="my-4">Locate a Provider</h3>
                      <p>Click below to connect with a low back specialist now.</p>
                      <a
                        style={{ cursor: 'pointer' }}
                        data-toggle="modal"
                        data-target="#coming-soon-journal"
                        className="btn btn-primary btn-continue mt-md-2 mx-auto"
                      >
                        Browse Preferred Providers
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </main>
      <ComingSoonModal />
    </div>
  );
}

export default RP22;
