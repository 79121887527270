import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import MarketingHeader from "./Templates/Header";
import MarketingFooter from "./Templates/MarketingFooter";
import * as httpMethods from "../Shared/Api";
import { useForm } from "react-hook-form";

function ContactUs() {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
    reset,
  } = useForm({
    criteriaMode: "all",
    mode: "onChange",
  });

  const onSubmit = (data) => {
    const { name, email, message } = data;
    const payload = {
      name,
      email,
      message,
    };
    httpMethods
      .Post("api/support/send_message/", payload)
      .then(() => {
        history.push("/success-contact-us");
      })
      .catch(() => {
        toast.error("Error sending message. Please try again.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);
  return (
    <>
      <MarketingHeader />
      <main className="site-page container my-5">
        <section className="row">
          <div className="col-md-8 order-md-2">
            <div className="w-100 border shadow p-5  rounded-10">
              <h2 className="txt-color-primary">PhysioTree Support</h2>
              <p>
                Fill out the form below if you have any questions or need
                assistance.
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group col-12 mb-3 mt-5">
                  <label htmlFor="name" className="form-label mb-0 mb-md-3">
                    Name
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter Name"
                    {...register("name", {
                      required: true,
                    })}
                    style={{
                      borderBottomColor: errors?.name?.types?.required
                        ? "#AA0000"
                        : "",
                    }}
                  />
                </div>

                <div className="form-group col-12 mb-3">
                  <label htmlFor="email" className="form-label mb-0 mb-md-3">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email"
                    {...register("email", {
                      required: true,
                    })}
                    style={{
                      borderBottomColor: errors?.email?.types?.required
                        ? "#AA0000"
                        : "",
                    }}
                  />
                </div>

                <div className="form-group col-12 mb-3">
                  <label htmlFor="message" className="form-label mb-0 mb-md-3">
                    Message
                  </label>

                  <textarea
                    rows="7"
                    className="form-control"
                    placeholder="How can we help you?"
                    {...register("message", {
                      required: true,
                    })}
                    style={{
                      borderColor: errors?.message?.types?.required
                        ? "#AA0000"
                        : "",
                    }}
                  />
                </div>
                <div className="form-group col-12 mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary btn-continue"
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="col-md-4 order-md-1">
            <div className="w-100 pt-5">
              <h2 className="txt-color-primary">Connect</h2>
              <p>
                For regular updates be sure to follow us on your favorite social
                media platform.
              </p>
            </div>

            <div className="w-100 pt-5">
              <h6 className="txt-color-primary">Follow Us on Facebook</h6>
              <a
                href="https://www.facebook.com/physiotree"
                target="_blank"
                rel="noreferrer"
                className="txt-color-dark-grey"
              >
                <i className="fab fa-facebook-square fa-2x" />
              </a>
            </div>
            {/* <div className="w-100 pt-5">
            <h6 className="txt-color-primary">
              Follow Us on Instagram
            </h6>
            <a target="_blank" className="txt-color-dark-grey">
              <i className="fab fa-instagram-square fa-2x" />
            </a>
          </div> */}
            <div className="w-100 pt-5">
              <a target="_blank" className="txt-color-primary fw-700">
                support@physiotree.com
              </a>
            </div>
          </div>
        </section>
        <MarketingFooter />
      </main>
    </>
  );
}

export default ContactUs;
