import React from 'react';

function LowRiskExplanationModal() {
  return (
    <div>
      <p>
        <b>Great News!</b>
      </p>
      <p>
        According to research and our experience, your low back pain will likely
        improve within a few weeks with a combination of:
      </p>
      <ul>
        <li>Exercise</li>
        <li>Self management</li>
      </ul>
      <p>
        Remain as active as possible, avoiding only the activities that significantly
        increase your pain.. We have provided you with customized exercises based upon
        the information you provided, as well as mindful postural education, activity
        recommendations, and a progressive walking program.
        Try to get through your entire program. Keep moving, Motion is Lotion!
      </p>
    </div>
  );
}

export default LowRiskExplanationModal;
