import React, { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import PhysTreeHeader from "../Templates/Header";
import PhysTreeFooter from "../Templates/Footer";

import { useHistory } from "react-router-dom";
import ComingSoonModal from "../ComingSoonModal";

/* newly added */
import WarningModal from "./WarningModal";
import RetakeTestModal from "./RetakeTestModal";
import HomeNewUser from "./NewUser";
import ReturningModal from "./ReturningModal";

import ShareModal from "../Modals/ShareModal";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../new-redux/actions/HomeAction";

import * as api from "../../api/HomeApi";
import moment from "moment";

const Dashboard = () => {
  const [retakeModal, isRetakeModalVisible] = useState(false);
  const [shareModal, isShareModalVisible] = useState(false);
  const [returningModal, isReturningModalVisible] = useState(false);
  const [isLastPT, setIsLastPT] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { home } = useSelector((state) => ({
    home: state.home,
  }));

  const { isNew, isReturningUser, resultList, isWarningModalVisible } = home;
  useEffect(async () => {
    const id = await AsyncStorage.getItem("userId");
    dispatch({ type: "RESET_LBS" });
    dispatch({ type: "RESET_HISTORY" });
    dispatch({ type: "RESET_FA" });
    let response = await api.getResults(id);
    if (response.status === 200) {
      dispatch(actions.setResults(response.data.data));
      dispatch({ type: "IS_RETURNING_USER" });
      if (response.data.data[response.data.data.length-1].category == 'PT') {
        setIsLastPT(true);
        dispatch({ type: "IS_PT"});
      } else {
        dispatch({ type: "IS_RETURNING_USER" });
      }
    } else {
      dispatch({ type: "IS_USER_NEW" });
    }

    const value = sessionStorage.getItem("isReturningUser");
    if (value == 'true'){
      isReturningModalVisible(true);
    } else {
      isReturningModalVisible(false);
    }
  }, []);

  const closeReturningModal = () => {
    sessionStorage.setItem("isReturningUser", false);
    isReturningModalVisible(false);
  };

  return (
    <>
      <div>
        <PhysTreeHeader />

        {isReturningUser && (
          <main className="container my-5">
            <div className="row">
              <div className="col-12 col-md-12 mb-4">
                <div
                  className="w-100  border rounded-20 p-4 h-100 card-box"
                  style={{ maxWidth: "none", margin: "auto" }}
                >
                  <i className="fas fa-th-large fa-lg txt-color-primary mr-2" />
                  <span className="h5">PhysioTree Low Back Dashboard</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-4">
                <div
                  className="w-100  border rounded-20 p-5 h-100 card-box"
                  style={{ maxWidth: "none", margin: "auto" }}
                >
                  <div className="row mb-4 align-items-center">
                    <div className="col-md-6">
                      <i
                        className="fas fa-history fa-lg color-dark mr-2"
                        style={{ color: "#58595B" }}
                      />
                      <span className="h5">History</span>
                    </div>
                    <div className="col-md-6 text-right">
                      <a
                        href="/journal"
                        className="btn btn-outline-secondary rounded-10 btn-xs"
                      >
                        Journal
                        <i className="fa fa-book-open ml-2" />
                      </a>
                    </div>
                  </div>
                  {resultList.map((item, index) => {
                    const params = {
                      category: item.category,
                      retake_number: item.retake_number,
                    };
                    const firstParse = JSON.parse(item.results);
                    const secondParse = JSON.parse(firstParse);

                    return (
                      <ResultItem
                        actionPlan={secondParse?.action_plan}
                        status={secondParse?.exercise_program}
                        title={index + 1}
                        date={item.created_date}
                        onResults={() => {
                          history.push({
                            pathname: "/results",
                            state: {
                              category: params.category,
                              retake_number: params.retake_number,
                            },
                          });
                        }}
                        onCustomPlan={() => {
                          history.push(
                            `/custom-solution/${item.category}/${item.retake_number}`
                          );
                        }}
                      />
                    );
                  })}

                  {resultList.length >= 1 && isLastPT && (
                    <div className="row  py-4 px-2 py-md-3 px-md-1 rounded-10 border mb-2 mx-0 journal-content">
                      <div className=" col-lg-6 col-md-12">
                        <a href="#">
                          <span className="txt-color-dark font-weight-bold">
                            Low Back Phase I
                          </span>
                        </a>
                      </div>
                      <div className=" col-lg-6 col-md-12 justify-content-md-end d-flex mt-4 mt-md-0">
                        <span>
                          <div>
                            <a
                              href="/pain-warning"
                              className="link mt-md-2 d-md-block"
                            >
                              Get Started
                              <i className="fas fa-chevron-right fa-lg ml-1 icon" />
                            </a>
                          </div>
                        </span>
                      </div>
                    </div>
                  )}

                  {resultList.length >= 1 && !isLastPT && (
                    <div className="row  py-4 px-2 py-md-3 px-md-1 rounded-10 border mb-2 mx-0 journal-content">
                      <div className=" col-lg-6 col-md-12">
                        <a href="#">
                          <span className="txt-color-dark font-weight-bold">
                            Low Back Phase II
                          </span>
                        </a>
                      </div>
                      <div className=" col-lg-6 col-md-12 justify-content-md-end d-flex mt-4 mt-md-0">
                        <span>
                          <div>
                            <a
                              href="/pain-warning"
                              className="link mt-md-2 d-md-block"
                            >
                              Get Started
                              <i className="fas fa-chevron-right fa-lg ml-1 icon" />
                            </a>
                          </div>
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="row mt-3 just">
                    <div className="col-md-6">
                      <a
                        onClick={() => isRetakeModalVisible(true)}
                        className="btn btn-primary btn-xs rounded-10  mb-md-2 mb-2"
                        style={{ marginRight: 4, cursor: "pointer" }}
                      >
                        Re-take Test
                      </a>
                      <a
                        style={{ cursor: "pointer" }}
                        data-toggle="modal"
                        data-target="#coming-soon-journal"
                        className="btn btn-primary btn-xs rounded-10  mb-md-2 mb-2 "
                      >
                        I want to test a new body part
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        )}
        {isNew && <HomeNewUser />}
        <PhysTreeFooter />

        {/* added by josh */}
        <RetakeTestModal
          isOpen={retakeModal}
          onClose={() => isRetakeModalVisible(false)}
        />
        <WarningModal
          isOpen={isWarningModalVisible}
          onClose={() => dispatch({ type: "SET_WARNING_MODAL_TO_FALSE" })}
        />
        <ShareModal
          isOpen={shareModal}
          onClose={() => isShareModalVisible(false)}
        />
        <ReturningModal
          isOpen={returningModal}
          onClose={() => closeReturningModal()}
        />
        <ComingSoonModal />
      </div>
    </>
  );
};

export default Dashboard;
const ResultItem = ({
  title,
  date,
  onResults,
  onCustomPlan,
  status,
  actionPlan,
}) => {
  return (
    <div className="row  py-4 px-2 py-md-3 px-md-1 rounded-10 border mb-2 mx-0 journal-content">
      <div className=" col-lg-6 col-md-12">
        <a href="#">
          <span className="txt-color-dark font-weight-bold">
            Low Back Results {title}
          </span>
          <span className="d-block txt-color-dark txt-sub">
            {moment(new Date(date)).format("DD MMM YYYY hh:mm A")}
          </span>
        </a>
      </div>
      <div className=" col-lg-6 col-md-12 justify-content-md-end d-flex mt-4 mt-md-0">
        <a
          onClick={onResults}
          className="btn btn-outline-secondary btn-xs rounded-10 mr-3"
        >
          <i className="fa fa-chart-bar mr-2" />
          Results
        </a>
        {status === "Completely Recovered" ||
        status === "See a Physical Therapist" ||
        actionPlan === "Follow up with a healthcare provider." ? null : (
          <a
            onClick={onCustomPlan}
            className="btn btn-outline-secondary btn-xs rounded-10"
          >
            <i className="fa fa-user-shield mr-2" />
            Your Plan
          </a>
        )}
      </div>
    </div>
  );
};
