import React, { useMemo, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import PhysTreeHeader from "../Templates/Header";
import PhysTreeFooter from "../Templates/Footer";
import QuestionProgressBar from "../Templates/QuestionProgressBar";
import * as actions from "../../new-redux/actions/FunctionalAssessment";

/* components */
import Q1 from "./GlobalRatingStatus";
import Activity1 from "./Activity1";
import Activity2 from "./Activity2";
import Activity3 from "./Activity3";

const FuncationalAssessment = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { fa, isPhaseTwo } = useSelector((state) => ({
    fa: state.fa,
    isPhaseTwo: state.home.isPhaseTwo,
  }));

  const { answerDetails, step, isSubmitSuccess, isSaveAnswerSuccess } = fa;

  const defaultValues = useMemo(
    () => ({
      slider: 0,
      q2: answerDetails?.q2 || "",
      q4: answerDetails?.q4 || "",
      q6: answerDetails?.q6 || "",
    }),

    [answerDetails]
  );
  const methods = useForm({
    defaultValues,
    criteriaMode: "all",
  });
  useEffect(async () => {
    dispatch({ type: "RESET_FA" });
    if (isPhaseTwo) {
      dispatch(actions.getRetakeNumber());
      dispatch(actions.getAnswers());
    } else {
      dispatch(actions.getPhase1RetakeNumber());
    }
  }, []);

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (isSubmitSuccess) {
      dispatch(actions.saveFunctionalAssessmentAnswer());
    }
  }, [isSubmitSuccess]);

  useEffect(() => {
    if (isSaveAnswerSuccess) {
      if (isPhaseTwo) {
        history.push("/phase-two/evaluate");
      } else {
        history.push("/low-back-algorithm");
      }
    }
  }, [isSubmitSuccess, isSaveAnswerSuccess]);

  const onSubmit = async (data, e) => {
    console.log(e);
  };
  return (
    <FormProvider {...methods}>
      <PhysTreeHeader />
      <ToastContainer />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="site-page container-fluid p-0">
          <QuestionProgressBar category={1} />
          {step === 1 && <Q1 />}
          {step === 2 && <Activity1 />}
          {step === 3 && <Activity2 />}
          {step === 4 && <Activity3 />}
        </div>
      </form>
      <PhysTreeFooter />
    </FormProvider>
  );
};

export default FuncationalAssessment;
