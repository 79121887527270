import React, { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import PhysTreeHeader from "../Templates/Header";
import PhysTreeFooter from "../Templates/Footer";
import QuestionProgressBar from "../Templates/QuestionProgressBar";
import NewJournalModal from "../Journal/NewJournalModal";
import ComingSoonModal from "../ComingSoonModal";
import walkingProgramPdf from "../../PDF/WalkingProgramPDF.pdf";
import postureProgramPdf from "../../PDF/PostureProgramPDF.pdf";

/* added new code  */
import { useDispatch, useSelector } from "react-redux";
import ExerciseModal from "./components/ExerciseModal";
import ExerciseJournalEntry from "./components/ExerciseJournalEntry";
import * as actions from "../../new-redux/actions/NewCustomSolution";
import * as api from "../../api/CustomSolutionApi";
import { toast } from "react-toastify";
/* images */
import videoCheck from "../Images/check.svg";
import playButtonImg from "../Images/play-btn.svg";
import postureIcon from "../Images/icon-posture-2.svg";
import walkIcon from "../Images/icon-walk-2.svg";
import ctaImg from "../Images/cta_img.png";

const progressWrap = {
  width: "100%",
  height: 10,
  backgroundColor: "#009444",
  margin: "20px 0px",
  overflow: "hidden",
  position: "relative",
  borderRadius: 10,
};

const NewCustomSolution = ({ match }) => {
  const [text, setText] = useState(null);
  const [exerciseModal, isExerciseModalVisible] = useState(false);
  const [journalEntryModal, isJournalEntryModalVisible] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const { custom_solution } = useSelector((state) => ({
    custom_solution: state.custom_solution,
  }));

  const { category, retake_number } = match?.params;
  const { customSolutionList, educationList, exerciseDetails } =
    custom_solution;

  useEffect(async () => {
    await dispatch(actions.getAllCustomSolutions(category, retake_number));
  }, []);

  const onWatchHandler = async (videoId) => {
    const id = await AsyncStorage.getItem("userId");
    const payload = {
      id: videoId,
      user_id: id,
    };
    let response = await api.setHasWatched(payload);
    if (response?.status === 200) {
      await dispatch(actions.getAllCustomSolutions(category, retake_number));
    }
  };

  const onSaveJournalEntry = async (data) => {
    const id = await AsyncStorage.getItem("userId");
    const { slider, notes } = data;
    const payload = {
      title: exerciseDetails?.video_name,
      user_id: id,
      rating: slider,
      notes,
      category: category,
    };
    let response = await api.saveJournalEntry(payload);
    if (response.status === 200) {
      toast.success("Journal successfully saved.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      isJournalEntryModalVisible(false);
    }
  };

  return (
    <div>
      <PhysTreeHeader />
      <ToastContainer />
      <QuestionProgressBar category={3} />

      <main className="container my-5">
        <div className="row  mx-0 my-5">
          <div className="col-12 pl-0">
            <a
              className="txt-color-dark my-4"
              style={{ cursor: "pointer" }}
              onClick={() => history.goBack()}
            >
              <i className="fas fa-arrow-left mr-2" />
              Back to Previous Page
            </a>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-6 col-12">
            <h1 style={{ color: "#009444" }}>Custom Solution</h1>
            <p>
              We have provided you with customized exercises based upon the
              information you provided. These are safe exercises, and
              appropriate for you to begin performing now.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <a
              data-toggle="modal"
              data-target="#new-journal"
              id="alert-btn-open"
              className="btn btn-outline-secondary rounded-10 px-4 py-3 mr-2 float-right mb-2"
            >
              + New Journal Entry
            </a>
            <a
              className="btn btn-outline-secondary rounded-10 px-4 py-3 mr-2 float-right mb-2"
              onClick={() => history.push("/journal")}
            >
              View Journal
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 mb-4">
            <div
              className="w-100  border rounded-20 p-5 h-100 card-box"
              style={{ maxWidth: "none", margin: "auto" }}
            >
              <h3>Education About Your Problem</h3>
              {educationList.slice(0, 1).map((item) => {
                return (
                  <ExerciseItem
                    duration={item?.duration}
                    title={item?.video_name}
                    hasWatched={item?.has_watched}
                    link={item?.link}
                    onClick={() => {
                      dispatch({ type: "SET_EXERCISE_DETAILS", payload: item });
                      onWatchHandler(item?.id);
                      isExerciseModalVisible(true);
                    }}
                    onJournalEntryHandler={() => {
                      dispatch({ type: "SET_EXERCISE_DETAILS", payload: item });
                      isJournalEntryModalVisible(true);
                      setText(0);
                    }}
                  />
                );
              })}
              <hr className="divider my-5" />
              <h3>{customSolutionList[0]?.exercise_program}</h3>

              {customSolutionList.map((item) => {
                return (
                  <ExerciseItem
                    duration={item?.duration}
                    title={item?.video_name}
                    hasWatched={item?.has_watched}
                    link={item?.link}
                    onClick={() => {
                      dispatch({ type: "SET_EXERCISE_DETAILS", payload: item });
                      onWatchHandler(item?.id);
                      isExerciseModalVisible(true);
                    }}
                    onJournalEntryHandler={() => {
                      setText(1);
                      dispatch({ type: "SET_EXERCISE_DETAILS", payload: item });
                      isJournalEntryModalVisible(true);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6 mb-4 h-100">
            <div className="card-box text-center border my-2 ">
              <img alt="" src={postureIcon} className="mb-2" />
              <h5 className="py-md-4"> Posture & Lifting Education</h5>
              <div
                className="progress-wrap progress mx-auto"
                style={progressWrap}
              >
                <div className="progress-bar progress" style={progressWrap} />
              </div>
              <a
                className="btn btn-light rounded-10 px-4 mt-2"
                style={{
                  backgroundColor: "#fff",
                  border: "none",
                  cursor: "pointer",
                }}
                href={postureProgramPdf}
                target="_blank"
                rel="noreferrer"
              >
                <span className="txt-color-primary font-weight-bold">
                  View
                  <i className="fas fa-arrow-right fa-lg ml-2" />
                </span>
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-4 h-100">
            <div className="card-box text-center border my-2 ">
              <img alt="" src={walkIcon} className="mb-2" />
              <h5 className="py-md-4"> Walking Program</h5>
              <div
                className="progress-wrap progress mx-auto"
                style={progressWrap}
              >
                <div className="progress-bar progress" style={progressWrap} />
              </div>
              <a
                className="btn btn-light rounded-10 px-4 mt-2"
                style={{
                  backgroundColor: "#fff",
                  border: "none",
                  cursor: "pointer",
                }}
                href={walkingProgramPdf}
                target="_blank"
                rel="noreferrer"
              >
                <span className="txt-color-primary font-weight-bold">
                  View
                  <i className="fas fa-arrow-right fa-lg ml-2" />
                </span>
              </a>
            </div>
          </div>
        </div>

        <div className="card-box mw-100 p-md-5 border my-2">
          <div className="row">
            <div className="col-12 col-md-5 mb-3">
              <img src={ctaImg} alt="" className="d-block mx-auto " />
            </div>
            <div className="col-12 col-md-7 d-flex align-items-center">
              <div className="d-block mx-auto mx-md-0 text-center text-md-left  ">
                <h3 className="my-4">Locate a Provider</h3>
                <p>Click below to connect with a low back specialist now.</p>
                <a
                  style={{ cursor: "pointer" }}
                  data-toggle="modal"
                  data-target="#coming-soon-journal"
                  className="btn btn-primary btn-continue mt-md-2 mx-auto"
                >
                  Browse Preferred Providers
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <PhysTreeFooter />
      <NewJournalModal />
      <ExerciseJournalEntry
        title={exerciseDetails?.video_name}
        isOpen={journalEntryModal}
        onClose={() => isJournalEntryModalVisible(false)}
        onSubmit={(data) => onSaveJournalEntry(data)}
        isExerciseProgram={text === 0 ? true : false}
      />
      <ComingSoonModal />
      <ExerciseModal
        title={exerciseDetails?.video_name}
        link={exerciseDetails?.link}
        isOpen={exerciseModal}
        onClose={() => {
          isExerciseModalVisible(false);
        }}
      />
    </div>
  );
};

export default NewCustomSolution;

const ExerciseItem = ({
  title,
  duration,
  hasWatched,
  link,
  onClick,
  onJournalEntryHandler,
}) => {
  const extractYoutubeVidIdFromUrl = (videoUrl) => {
    const urlArray = videoUrl?.split("/");
    return urlArray[urlArray?.length - 1];
  };

  const videoThumbnailSrc = `https://img.youtube.com/vi/${extractYoutubeVidIdFromUrl(
    link
  )}/mqdefault.jpg`;
  return (
    <div className="row mt-5 justify-content-center align-items-center">
      <div className="col-xl-4 col-lg-5 col-md-7 col-sm-12 col-12">
        <div
          className="video-thumb"
          style={{ backgroundImage: `url("${videoThumbnailSrc}")` }}
        >
          <div className="h-100 d-flex">
            <div className="play-btn mx-auto align-self-center ">
              <a
                style={{ cursor: "pointer" }}
                onClick={onClick}
                data-toggle="modal"
              >
                <img alt="" src={hasWatched ? videoCheck : playButtonImg} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-lg-7 col-md-5 col-sm-12 col-12">
        <p className="font-weight-bold mb-0" style={{ fontSize: 20 }}>
          {title}
        </p>
        <p className="text-left" style={{ fontSize: 16 }}>
          ({duration})
        </p>

        {hasWatched ? (
          <a
            data-target="#new-journal-ex"
            data-toggle="modal"
            className="btn btn-outline-secondary btn-xs rounded-10 mr-3 btn-fw-200"
            onClick={onJournalEntryHandler}
          >
            <i className="fa fa-clipboard mr-2" />
            Add Journal Entry
          </a>
        ) : (
          <a
            onClick={onClick}
            data-toggle="modal"
            className="btn btn-primary btn-xs rounded-10 mr-3 btn-fw-100"
          >
            Start
          </a>
        )}
      </div>
    </div>
  );
};
