import React from "react";
import logo from "../Images/logo.svg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function MarketingFooter() {
  const history = useHistory();
  const { auth } = useSelector((state) => ({
    auth: state.auth,
  }));

  const { isAuth } = auth;

  const onClickImg = () => {
    if (isAuth) {
      history.push("/home");
    } else {
      history.push("/");
    }
  };
  return (
    <footer className="pt-4 sign-up-footer">
      <div className="container">
        <div className="row align-items-center py-4">
          <div className="col">
            <a onClick={onClickImg}>
              <img src={logo} alt="logo" />
            </a>
          </div>
          <div className="col text-end foot-social">
            {/*  <a
              href="https://www.facebook.com/physiotree"
              target="_blank"
              rel="noreferrer"
              className="facebook"
            >
              <i
                aria-label="facebook"
                className="fab fa-facebook-square fa-2x"
              />
            </a>
          
            <a href="" target="_blank" className="mx-3 twitter">
              <i aria-label="twitter" className="fab fa-twitter fa-2x" />
            </a>
            <a href="" target="_blank" className="instagram">
              <i aria-label="instagram" className="fab fa-instagram fa-2x" />
            </a>
            */}
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col text-center text-lg-start">
            <ul className="list-inline foot-links py-4">
              {/*
              <li className="list-inline-item"><a href="" className="">About Us</a></li>
              <li className="list-inline-item"><a href="" className="mx-md-4">How It Works</a></li>
              */}
              {/* <li className="list-inline-item"><a href="/pricing" className="me-md-4">Pricing</a></li>
              <li className="list-inline-item"><a href="/contact-us" className="ml-md-4">Contact Us</a></li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-lg-start">
              © 2021 PhysioTree LLC. All rights reserved.
            </div>
            <div className="col-md-6 text-center text-lg-end">
              <a href="#">Terms and Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default MarketingFooter;
