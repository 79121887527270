import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as httpMethods from "../../Shared/Api";
import PhysTreeHeader from "../Templates/Header";
import PhysTreeFooter from "../Templates/Footer";
import wizardBlue from "../Images/wizard_blue.svg";
import ctaImgBlue from "../Images/cta_img_blue.svg";
import ShareModal from "../Modals/ShareModal";
import { PhaseTwo } from "../../Constants/Surveys";

function CompletelyRecovered() {
  const [retakeNumber, setRetakeNumber] = useState(1);
  const [shareModal, isShareModalVisible] = useState(false);
  const history = useHistory();

  const saveAnswer = (payload) => {
    httpMethods
      .Post("api/surveys/save_answer/", payload)
      .then(() => {
        history.push({
          pathname: "/results",
          state: {
            category: "Phase2",
            retake_number: payload.retake_number,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRetakeNumber = async () => {
    const id = await AsyncStorage.getItem("userId");

    httpMethods
      .Get(`api/surveys/get_retake_number/?user_id=${id}&category=Phase2`)
      .then((result) => {
        setRetakeNumber(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getRetakeNumber();
  }, []);

  const viewButtonHandler = async () => {
    const answerType = { result: 10 };
    const id = await AsyncStorage.getItem("userId");
    const answer = {
      result: "Completely Recovered",
    };
    const payload = {
      user_id: id,
      survey: PhaseTwo,
      retake_number: retakeNumber,
      answer: JSON.stringify(answer),
      answer_type: JSON.stringify(answerType),
    };

    saveAnswer(payload);
  };

  return (
    <div>
      <PhysTreeHeader />
      <div className="site-page container-fluid p-0">
        <main className="container d-flex justify-content-center align-items-center">
          <div className="w-100">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="card-box mw-100 p-md-5">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-4 mb-3">
                      <img
                        src={wizardBlue}
                        alt=""
                        style={{ maxWidth: 160 }}
                        className="mx-auto d-none d-lg-block"
                      />
                      <img
                        src={ctaImgBlue}
                        alt=""
                        style={{ maxWidth: 200 }}
                        className="mx-auto d-table d-lg-none"
                      />
                    </div>
                    <div className="col-12 col-lg-8 d-flex align-items-center">
                      <div className="d-block mx-auto mx-md-0 text-center text-md-left">
                        <h1 className="mb-4 h2">Amazing job and well done!</h1>
                        <p className="completely-recovered">
                          Great to see you’re feeling better and enjoying your
                          normal activities. Evidence suggests that a healthy
                          lifestyle which includes regular exercise is the most
                          powerful step you can take to prevent future episodes.
                        </p>
                        <p className="completely-recovered">
                          We’d love to see PhysioTree help one of your friends
                          or family members, &nbsp;too. Please share our app!
                        </p>
                        <p className="mb-1 completely-recovered">
                          Share with your friends!
                        </p>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.facebook.com/sharer/sharer.php?u=http://165.232.131.254/"
                          className="fb-xfbml-parse-ignore"
                          aria-label="facebook"
                        >
                          <i className="fab fa-facebook-square fa-lg mr-2 completely-recovered-icon" />
                        </a>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://twitter.com/intent/tweet?text=I%20recommend%20PhysioTree%20for%20Lower%20Back%20Pain!%20http://165.232.131.254/"
                          aria-label="twitter"
                        >
                          <i className="fab fa-twitter fa-lg mr-2 completely-recovered-icon" />
                        </a>
                        {/* Instagram has no share functionality */}
                        {/* <a href="" aria-label="instagram">
                          <i className="fab fa-instagram fa-lg mr-2 completely-recovered-icon" />
                        </a> */}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="http://www.linkedin.com/shareArticle?mini=true&url=http://165.232.131.254/"
                          aria-label="linkedin"
                        >
                          <i className="fas fa-envelope fa-lg completely-recovered-icon" />
                        </a>
                      </div>
                    </div>
                    <ShareModal
                      isOpen={shareModal}
                      onClose={() => isShareModalVisible(false)}
                    />

                    <a
                      href="#"
                      className="btn btn-primary btn-continue d-table mx-auto"
                      onClick={() => isShareModalVisible(true)}
                    >
                      Got it
                    </a>
                  </div>
                </div>

                {/* <a
                  style={{ cursor: 'pointer' }}
                  className="btn btn-primary btn-continue d-table mx-auto"
                  onClick={() => history.push('/home')}
                >
                  Got it
                </a> */}

                {/* <ShareModal
                  isOpen={shareModal}
                  onClose={() => isShareModalVisible(false)}
                />

                <a
                  href="#"
                  className="btn btn-primary btn-continue d-table mx-auto"
                  onClick={() => isShareModalVisible(true)}
                >
                  Got it
                </a> */}
                <a
                  style={{ cursor: "pointer" }}
                  className="btn btn-primary btn-continue d-table mx-auto"
                  onClick={viewButtonHandler}
                >
                  View Results
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
      <PhysTreeFooter />
    </div>
  );
}

export default CompletelyRecovered;
