import React from 'react';
import PhysTreeHeader from './Templates/Header';
import PhysTreeFooter from './Templates/Footer';

import wizard from './Images/compressed_wizard.jpg';
import ctaImg from './Images/cta_img.png';

function Welcome() {
  return (
    <div>
      <PhysTreeHeader />
      <main className="site-page container d-flex justify-content-center align-items-center">
        <div className="card-box">
          <div className="row">
            <div className="col-12 col-md-4">
              <img src={wizard} alt="" className="d-none d-md-table mx-auto" />
              <img src={ctaImg} alt="" className="d-table d-md-none mx-auto mb-4" />
            </div>
            <div className="col-12 col-md-8">
              <h2 className="txt-color-dark">
                Welcome to PhysioTree
              </h2>
              <h3 className="txt-color-primary font-weight-normal h4 my-4">
                Thank you for partnering with us for your low back solution!
              </h3>
              <p className="txt-color-dark" style={{ fontSize: 18 }}>
                Together we will work to reach your functional goals.
                To learn more about your particular condition, we will
                be asking you a series of questions. The information that
                you enter will be used to provide you with a set of
                recommendations and exercises specifically tailored to
                your specific problem. Let’s get started!
              </p>

              <div className="col-12 col-sm-6 d-flex align-items-center">
                <div className="d-block mx-auto mx-md-0 text-center text-md-left">
                  <a href="/disclaimer" className="btn btn-continue btn-primary d-table mt-5">Get Started</a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </main>
      <PhysTreeFooter />
    </div>
  );
}

export default Welcome;
