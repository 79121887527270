import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as httpMethods from "../../../Shared/Api";
import followUpCta from "../../Images/cta_img.png";
import { PhaseTwo } from "../../../Constants/Surveys";
import { useSelector } from "react-redux";
import * as api from "../../../api/HistoryApi";

const Medications = ({ isOpen, onClose, onContinueHandler }) => {
  const history = useHistory();
  const { retakeNumber } = useSelector((state) => ({
    retakeNumber: state.history_answer.retakeNumber,
  }));

  const [secondModal, isSecondModalVisible] = useState(false);
  const [array, setArray] = useState([]);

  const historyProcedure = [
    {
      id: 1,
      title: "Injections",
      name: "injection",
    },
    {
      id: 2,
      title: "Surgery",
      name: "surgery",
    },
  ];

  let prcQ2 = [];
  const toggleHandler = (e, item) => {
    if (e.target.checked) {
      setArray([...array, item]);
    } else {
      prcQ2 = prcQ2.filter((type) => type.id != item.id);
      setArray(prcQ2);
    }
  };

  const saveAnswer = (payload) => {
    httpMethods
      .Post("api/surveys/save_answer/", payload)
      .then(() => {
        history.push({
          pathname: "/results",
          state: {
            category: "PT",
            retake_number: payload.retake_number,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const viewButtonHandler = async () => {
    const answerType = { result: 10 };
    const id = await AsyncStorage.getItem("userId");
    const getRetakeNumberResponse = await api.getPTRetakeNumber(id);
    const answer = {
      result: "See a Physical Therapist",
    };
    const payload = {
      user_id: id,
      survey: PhaseTwo,
      retake_number: getRetakeNumberResponse.data.data,
      answer: JSON.stringify(answer),
      answer_type: JSON.stringify(answerType),
    };

    saveAnswer(payload);
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        animation={true}
        centered
        contentClassName="rounded-20"
      >
        <div className="modal-content rounded-20">
          <div className="modal-body p-5   ">
            <a className="close" onClick={onClose}>
              <i className="fas fa-times txt-color-dark-grey" />
            </a>

            <div className="form-group">
              <h4 className=" h4 font-weight-bold mt-md-5 mb-4 ">
                Please Select Type(s) of Procedure:
              </h4>
            </div>

            <div className="form-group" data-toggle="buttons">
              {historyProcedure.map((item) => {
                return (
                  <label
                    className="btn btn-outline-secondary btn-radio mr-1 btn-block"
                    key={item.id}
                  >
                    <input
                      type="checkbox"
                      className="text-dark font-weight-bold d-none"
                      value={item.name}
                      onChange={(e) => {
                        toggleHandler(e, item);
                      }}
                      data-toggle={item.name === "surgery" ? "collapse" : ""}
                      href="#collapseExample"
                    />
                    {item.title}
                  </label>
                );
              })}
            </div>

            <div className="collapse " id="collapseExample">
              <div className="form-group">
                <h4 className=" h4 font-weight-bold mt-md-5 mb-4 ">
                  When did you undergo surgery:
                </h4>
              </div>
              <button
                type="button"
                className="btn btn-outline-secondary btn-radio mr-1 btn-block"
                onClick={() => {
                  isSecondModalVisible(true);
                  onClose(false);
                }}
              >
                Within The Last Three Months
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary btn-radio mr-1 btn-block"
                onClick={() => onContinueHandler(array)}
              >
                More Than Three Months Ago
              </button>
            </div>
            <div className="form-group mt-5">
              <div className="d-block text-right ">
                <button
                  type="button"
                  className="btn btn-success btn-md rounded-10 mt-md-2"
                  disabled={array.length === 0 ? true : false}
                  onClick={() => onContinueHandler(array)}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        show={secondModal}
        onHide={() => history.push("/home")}
        animation={true}
        centered
        contentClassName="rounded-20"
      >
        <div className="modal-content rounded-20">
          <div className="modal-body p-5   ">
            <div className="form-group text-center">
              <img src={followUpCta} alt="" className="w-50" />
              <p className="font-weight-bold mt-md-5 mb-4 text-center">
                Based on you response, we recommend that you follow up with a
                healthcare provider.
              </p>
            </div>
            <div className="form-group" data-toggle="buttons">
              <label className="btn btn-outline-secondary btn-radio mr-1 btn-block">
                <input
                  type="button"
                  autoComplete="off"
                  className="text-dark font-weight-bold d-none"
                  onClick={() => viewButtonHandler()}
                />
                Got it
              </label>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Medications;
