/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import workoutImg from "../../Images/workout7.png";
import * as httpMethods from "../../../Shared/Api";
import { PhaseTwo } from "../../../Constants/Surveys";

function IncreaseFrequencyTwoResult() {
  const [retakeNumber, setRetakeNumber] = useState(1);
  const history = useHistory();

  const saveAnswer = (payload) => {
    httpMethods
      .Post("api/surveys/save_answer/", payload)
      .then(() => {
        history.push({
          pathname: "/results",
          state: {
            category: "Phase2",
            retake_number: payload.retake_number,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRetakeNumber = async () => {
    const id = await AsyncStorage.getItem("userId");

    httpMethods
      .Get(`api/surveys/get_retake_number/?user_id=${id}&category=Phase2`)
      .then((result) => {
        setRetakeNumber(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getRetakeNumber();
  }, []);

  const viewButtonHandler = async () => {
    getRetakeNumber();

    const answerType = { result: 10 };
    const id = await AsyncStorage.getItem("userId");

    const answer = {
      result: "Phase II Stabilization - Flexion & Extension Bias",
    };

    const payload = {
      user_id: id,
      survey: PhaseTwo,
      retake_number: retakeNumber,
      answer: JSON.stringify(answer),
      answer_type: JSON.stringify(answerType),
    };

    saveAnswer(payload);
  };

  return (
    <div className="site-page container-fluid p-0">
      <main className="container  d-flex justify-content-center align-items-center">
        <div className="w-100">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="card-box mw-100 p-md-5 ">
                <div className="row justify-content-center">
                  <div className="col-md-9">
                    <div className="text-center mx-auto">
                      <img alt="" src={workoutImg} className="img-fluid mb-4" />
                      <p className="px-md-5">
                        We&apos;re glad to hear you&apos;re feeling better.
                        Increasing the frequency of your Phase II exercises to 5
                        or more days per week may improve your results.
                        We&apos;ll check back with you in a few weeks to see if
                        you&apos;re ready to progress to Phase III.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <a
                href="#"
                className="btn btn-primary btn-continue d-table mx-auto mb-5"
                onClick={viewButtonHandler}
              >
                View Exercises
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default IncreaseFrequencyTwoResult;
