import React, { useEffect } from "react";
import QuestionProgressBar from "../Templates/QuestionProgressBar";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";

const Q6 = () => {
  const dispatch = useDispatch();
  const { register, setValue, getValues } = useFormContext();

  useEffect(() => {
    register("q6", { required: true });
  }, []);

  const onChange = (e) => {
    setValue("q6", e);
    dispatch({ type: "INCREMENT", payload: getValues() });
  };

  return (
    <div className="site-page container-fluid p-0">
      <QuestionProgressBar category={0} />
      <main className="container  d-flex justify-content-center align-items-center">
        <div className="w-100">
          <div className="card-box mw-100">
            <form>
              <div className="form-row">
                <div className="form-group">
                  <h2 className="form-heading-sub">Low Back Risk Assessment</h2>
                </div>
                <div className="form-group col-6 d-flex justify-content-end align-items-center">
                  <p className="txt-color-dark-grey d-block d-lg-none m-0">
                    Question 6 of 7
                  </p>
                </div>
              </div>

              <div className="form-row">
                <div className="col-12">
                  <p className="txt-color-dark-grey d-none d-lg-block">
                    Question 6 of 7
                  </p>
                  <p className="form-title-head">
                    Is your back pain compensable, e.g., through worker’s
                    compensation or third party insurance?
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          You are receiving time off work or financial
                          compensation as a result of your pain.
                        </Tooltip>
                      }
                    >
                      <a href="#">
                        <i className="fas fa-info-circle fa-xs txt-color-secondary ml-2" />
                      </a>
                    </OverlayTrigger>
                  </p>
                </div>
                <div className="form-group col-12" data-toggle="buttons">
                  <label className="btn btn-outline-secondary btn-radio btn-fw-70 mr-1 btn-redirect">
                    <input
                      id="low-back-q6"
                      type="radio"
                      className="text-dark font-weight-bold d-none"
                      value={1}
                      name="q6"
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                    Yes
                  </label>

                  <label className="btn btn-outline-secondary btn-radio btn-fw-70 mr-1 btn-redirect">
                    <input
                      type="radio"
                      className="text-dark font-weight-bold d-none"
                      value={0}
                      name="q6"
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                    No
                  </label>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-12 col-md-4" />
              </div>
            </form>
          </div>
          <a
            className="btn-prev btn-xs btn"
            onClick={() => dispatch({ type: "DECREMENT" })}
          >
            <i className="fas fa-arrow-left mr-2" />
            Previous
          </a>
        </div>
      </main>
    </div>
  );
};

export default Q6;
