import React, { memo } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import $ from 'jquery';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as httpMethods from '../../../Shared/Api';
import notifIcon from '../../Images/notif-icon.svg';

function EnableNotifModal() {
  const history = useHistory();

  const EnableNotificationClickHandler = async () => {
    const id = await AsyncStorage.getItem('userId');
    const payload = {
      user_id: id,
      enable_email_notifications: true,
    };

    httpMethods
      .Post('api/users/enable_notifications/', payload)
      .then(() => {
        $('#enable-notif').modal('hide');
        history.push('/pain-warning');
      })
      .catch(() => {
        toast.error('Something went wrong. Please try again.', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  const EnableNotificationNoClickHandler = () => {
    $('#enable-notif').modal('hide');
    $('#enable-notif-no').modal('toggle');
  };

  return (
    <div className="modal fade" id="enable-notif" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content rounded-20">
          <div className="modal-body p-5">
            <a style={{ cursor: 'pointer' }} className="close" data-dismiss="modal">
              <i className="fas fa-times txt-color-dark-grey" />
            </a>
            <div className="row mt-md-5">
              <div className="col-12 d-flex align-items-center mx-0 px-0 justify-content-center">
                <img src={notifIcon} alt="" />
              </div>
              <div className="col-12 my-4">
                <h4 className="text-center txt-color-dark h3 font-weight-bold">
                  Enable notifications?
                </h4>
                <p className="text-center">
                  We would like to send you notifications regarding your health status.
                </p>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <a onClick={EnableNotificationClickHandler} className="btn-continue btn-outline-secondary text-decoration-none mx-2 d-table" id="alert-btn-close">
                  Yes
                </a>
                <a onClick={EnableNotificationNoClickHandler} className="btn-continue btn-outline-secondary text-decoration-none mx-2 d-table" id="alert-btn-close">
                  No
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  null,
  null,
)(memo(EnableNotifModal));
