import React from 'react';

function QuestionProgressBar(props) {
  const { category, isPhaseTwo } = props;
  return (
    <div className="row m-0 p-0" hidden={isPhaseTwo}>
      <div className={`col-3 m-0 p-0 indicator ${category >= 0 ? 'active' : ''}`}>
        <p className="p-1 p-md-4 d-none d-md-block">
          <span className="txt-color-primary txt-sub">Part 1</span>
          <br />
          Low Back Risk Assessment
        </p>
      </div>
      <div className={`col-3 m-0 p-0 indicator ${category >= 1 ? 'active' : ''}`}>
        <p className="p-1 p-md-4 d-none d-md-block">
          <span className="txt-color-primary txt-sub">Part 2</span>
          <br />
          Functional Assessment
        </p>
      </div>
      <div className={`col-3 m-0 p-0 indicator ${category >= 2 ? 'active' : ''}`}>
        <p className="p-1 p-md-4 d-none d-md-block">
          <span className="txt-color-primary txt-sub">Part 3</span>
          <br />
          Low Back Movement Algorithm
        </p>
      </div>
      <div className={`col-3 m-0 p-0 indicator ${category >= 3 ? 'active' : ''}`}>
        <p className="p-1 p-md-4 d-none d-md-block">
          <span className="txt-color-primary txt-sub">Part 4</span>
          <br />
          Result
        </p>
      </div>
    </div>
  );
}

export default QuestionProgressBar;
