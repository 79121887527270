import React from "react";
import logo from "../Images/logo.svg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function MarketingHeader() {
  const history = useHistory();
  const { auth } = useSelector((state) => ({
    auth: state.auth,
  }));

  const { isAuth } = auth;

  const onClickImg = () => {
    if (isAuth) {
      history.push("/home");
    } else {
      history.push("/");
    }
  };
  return (
    <nav className="navbar fixed-top navbar-expand-md" id="nav">
      <div className="container">
        <a className="header-navbar-brand" onClick={onClickImg}>
          <img src={logo} alt="logo" title="physiotree logo" />
        </a>
        <button
          id="toggle"
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="line-b" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav ms-auto mb-2 mb-md-0">
            {/* <li className="nav-item">
              <a className="nav-link" href="">About</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="">How It Works</a>
            </li> */}
            {/*  <li className="nav-item">
              <a className="nav-link" href="/pricing">Pricing</a>
            </li> 
             <li className="nav-item">
              <a className="nav-link" href="">Partners</a>
            </li> 
            <li className="nav-item">
              <a className="nav-link" href="/contact-us">Contact</a>
            </li>*/}
            {!isAuth && (
              <li className="nav-item">
                <a className="nav-link btn btn-primary" href="/signup">
                  Get Assessed
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default MarketingHeader;
