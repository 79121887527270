import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import * as httpMethods from '../../Shared/Api';

function NotificationSettings(props) {
  const { userId } = props;

  const [enableEmailNotif, setEnableEmailNotif] = useState();
  const [enableSmsNotif, setEnableSmsNotif] = useState();

  useEffect(() => {
    httpMethods
      .Get(`api/users/get_user_profile/?user_id=${userId}`)
      .then((response) => {
        const responseData = response.data.data;
        setEnableEmailNotif(responseData.enable_email_notifications);
        setEnableSmsNotif(responseData.enable_sms_notifications);
      })
      .catch(() => {
        toast.error('Failed to load profile defaults.', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  }, []);

  const emailSwitchHandler = (event) => {
    const payload = {
      user_id: userId,
      enable_email_notifications: event.target.checked,
      enable_sms_notifications: enableSmsNotif,
    };

    httpMethods
      .Post('api/users/enable_notifications_from_settings/', payload)
      .then(() => {
        setEnableEmailNotif(!enableEmailNotif);
      })
      .catch(() => {
        toast.error('An error occured. Please try again.', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  const smsSwitchHandler = (event) => {
    const payload = {
      user_id: userId,
      enable_email_notifications: enableEmailNotif,
      enable_sms_notifications: event.target.checked,
    };
    httpMethods
      .Post('api/users/enable_notifications_from_settings/', payload)
      .then(() => {
        setEnableSmsNotif(!enableSmsNotif);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="col-12 col-md-9 px-3 px-md-5">
      <form action="" className="form-box-fluid shadow-none">
        <div className="form-group">
          <h3 className="form-heading mb-5">Notifications</h3>
        </div>
        <div className="form-row">
          <div className="form-group col-6 col-md-8 ">
            <label className="form-title-head" htmlFor="customSwitch1">Email Notifications</label>
            <p>
              Agree to receiving notifications from PhysioTree regarding my customized exercise
              program and future PhysioTree programs
            </p>
          </div>
          <div className="form-group col-6 col-md-3 d-flex justify-content-end">
            <label className="switch">
              <input type="checkbox" checked={enableEmailNotif} onChange={emailSwitchHandler} />
              <span className="slider round" />
            </label>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-6 col-md-8 ">
            <label className="form-title-head" htmlFor="customSwitch1">SMS Notifications</label>
            <p>
              Agree to receiving notifications from PhysioTree regarding my customized exercise
              program and future PhysioTree programs
            </p>
          </div>
          <div className="form-group col-6 col-md-3 d-flex justify-content-end">
            <label className="switch">
              <input type="checkbox" checked={enableSmsNotif} onChange={smsSwitchHandler} />
              <span className="slider round" />
            </label>
          </div>
        </div>

      </form>
    </div>
  );
}

export default NotificationSettings;
