import * as types from './Actions/ActionTypes/RegisterTypes';

const initState = {
  firstName: '',
  lastName: '',
  gender: '',
  year: '',
  email: '',
  phoneNum: '',
  zipCode: '',
  password: '',
  errorMessage: '',
  userId: '',
};

const RegisterReducer = (state = initState, action) => {
  switch (action.type) {
    case types.REGISTER_FIRST_NAME: return { ...state, firstName: action.data };
    case types.REGISTER_LAST_NAME: return { ...state, lastName: action.data };
    case types.REGISTER_GENDER: return { ...state, gender: action.data };
    case types.REGISTER_YEAR: return { ...state, year: action.data };
    case types.REGISTER_EMAIL: return { ...state, email: action.data };
    case types.REGISTER_PHONE_NUM: return { ...state, phoneNum: action.data };
    case types.REGISTER_ZIP_CODE: return { ...state, zipCode: action.data };
    case types.REGISTER_PASSWORD: return { ...state, password: action.data };
    case types.REGISTER_ERROR_MESSAGE: return { ...state, errorMessage: action.data };
    case types.REGISTER_USER_ID: return { ...state, userId: action.data };
    default: return state;
  }
};

export default RegisterReducer;
