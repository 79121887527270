import React from 'react';

function JournalShareDoneModal() {
  return (
    <div className="modal fade" id="journal-share-done" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered  ">
        <div className="modal-content rounded-20">
          <div className="modal-body p-5   ">
            <a className="close" data-dismiss="modal">
              <i className="fa fa-times txt-color-dark-grey" />
            </a>

            <div className="row">
              <div className="col-12 d-flex align-items-center mx-0 px-0 justify-content-center">
                <i className="fas fa-check-circle txt-color-primary display-3 font-weight-bold" />
              </div>
              <div className="col-12 my-4">
                <h4 className="text-center txt-color-dark h1 font-weight-bold">
                  Done
                </h4>
                <p className="text-center">
                  You have successfully shared your journal.
                </p>
              </div>
              <div className="col-12">
                <a className="btn-continue btn-primary text-decoration-none mx-auto d-table" data-dismiss="modal">Got It</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JournalShareDoneModal;
