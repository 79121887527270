const initialState = {
  customSolutionPercent: 0,
  data: {},
  resultsDetails: {},
  funcAssessmentList: [],
  userName: "",
  title: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PERCENTAGE":
      return {
        ...state,
        customSolutionPercent: action.payload,
      };
    case "GET_RESULTS_DETAILS": {
      return {
        ...state,
        data: action.payload,
        resultsDetails: action.resultsDetails,
        userName: action.name,
      };
    }
    case "GET_FUNCTIONAL_ASSESSMENT": {
      return {
        ...state,
        funcAssessmentList: action.payload,
        title: action.title,
      };
    }
    case "RESET_RESULTS":
      return initialState;

    default:
      return state;
  }
};

export default reducer;
