import { _request } from "./BaseApi";

export const getAnswers = async (id) => {
  try {
    let url = `api/surveys/get_answer/?user_id=${id}&survey=${5}`;
    let result = await _request("get", url);
    return result;
  } catch (err) {
    return err;
  }
};

export const getRetakeNumber = async (id) => {
  try {
    let url = `api/surveys/get_retake_number/?user_id=${id}&category=Phase2`;
    let result = await _request("get", url, id);
    return result;
  } catch (err) {
    return err;
  }
};

export const getPhase1RetakeNumber = async (id) => {
  try {
    let url = `api/surveys/get_retake_number/?user_id=${id}&category=Phase1`;
    let result = await _request("get", url, id);
    return result;
  } catch (err) {
    return err;
  }
};

export const saveFuncationalAssessmentAnswer = async (payload) => {
  try {
    let url = `api/surveys/save_answer/`;
    let result = await _request("post", url, payload);
    return result;
  } catch (err) {
    return err;
  }
};
