import * as types from './Actions/ActionTypes/LoginTypes';

const initState = {
  email: '',
  firstName: '',
  password: '',
  userId: '',
  errorMessage: '',
};

const LoginReducer = (state = initState, action) => {
  switch (action.type) {
    case types.LOGIN_EMAIL: return { ...state, email: action.data };
    case types.LOGIN_FIRST_NAME: return { ...state, firstName: action.data };
    case types.LOGIN_PASSWORD: return { ...state, password: action.data };
    case types.LOGIN_USER_ID: return { ...state, userId: action.data };
    case types.LOGIN_ERROR_MESSAGE: return { ...state, errorMessage: action.data };
    default: return state;
  }
};

export default LoginReducer;
