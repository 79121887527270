import instance from './AxiosInstance';

export const Post = (url, payload) => {
  const post = instance.request({
    url,
    method: 'POST',
    data: payload,
  });

  return post;
};

export const Get = (url) => {
  const get = instance.request({
    url,
    method: 'GET',
  });

  return get;
};

export const Put = (url, payload) => {
  const put = instance.request({
    url,
    method: 'PUT',
    data: payload,
  });

  return put;
};

export const GetAccessToken = (url, payload, auth) => {
  const getAccessToken = instance.request({
    url,
    method: 'POST',
    auth,
    data: payload,
  });

  return getAccessToken;
};
