const initialState = {
  resultList: [],
  isPT: false,
  isNew: false,
  isReturningUser: false,
  isWarningModalVisible: false,
  isPhaseTwo: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_RESULTS":
      return {
        ...state,
        resultList: action.payload,
      };
    case "IS_PT":
      return {
        ...state,
        isPT: true,
        isPhaseTwo: false,
      };
    case "IS_USER_NEW":
      return {
        ...state,
        isNew: true,
        isReturningUser: false,
        isPhaseTwo: false,
      };
    case "IS_RETURNING_USER":
      return {
        ...state,
        isReturningUser: true,
        isPhaseTwo: true,
        isNew: false,
      };
    case "IS_RETAKE_TEST":
      return {
        ...state,
        isReturningUser: true,
        isPhaseTwo: false,
        isNew: false,
      };
    case "IS_WARNING_MODAL_VISIBLE":
      return {
        ...state,
        isWarningModalVisible: true,
      };
    case "SET_WARNING_MODAL_TO_FALSE":
      return {
        ...state,
        isWarningModalVisible: false,
      };
    case "RESET_HOME":
      return initialState;

    default:
      return state;
  }
};

export default reducer;
