import React, { memo } from 'react';
import { connect } from 'react-redux';
import MarketingHeader from '../Templates/MarketingHeader';
import MarketingFooter from '../Templates/MarketingFooter';

function Pricing() {
  return (
    <div>
      <MarketingHeader />
      <div className="site-page container-fluid p-0 mt-5">
        <div className="container p-5">
          <div className="row mb-4">
            <div className="col-12">
              <h2 className="txt-color-dark settings-head-title">Pricing</h2>
            </div>
          </div>

          <div className="row">
            <div className="col text-center">
              <h2 className="txt-color-dark">All for less than a Copay</h2>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="card-box">
                <div className="text-center mb-4">
                  <span className="dollar">$</span>
                  <span className="amount">25</span>
                  per month

                  <span className="d-block">For greater flexibility</span>
                </div>

                <ul className="list-unstyled">
                  <li className="p-2">
                    <i className="fas fa-check mr-2" aria-hidden="true" />
                    Full Access to PhysioTree
                  </li>
                  <li className="p-2">
                    <i className="fas fa-check mr-2" aria-hidden="true" />
                    Comprehensive Assessment
                  </li>
                  <li className="p-2">
                    <i className="fas fa-check mr-2" aria-hidden="true" />
                    Custom Exercise Plan
                  </li>
                  <li className="p-2">
                    <i className="fas fa-check mr-2" aria-hidden="true" />
                    Low Back Pain Resources
                  </li>
                  <li className="p-2">
                    <i className="fas fa-check mr-2" aria-hidden="true" />
                    No Commitment
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="card-box">
                <div className="text-center mb-4">
                  <span className="dollar">$</span>
                  <span className="amount">45</span>
                  /3 months

                  <span className="d-block">Upfront Savings for Longer Term Access!</span>
                </div>

                <ul className="list-unstyled">
                  <li className="p-2">
                    <i className="fas fa-check mr-2" aria-hidden="true" />
                    Full Access to PhysioTree
                  </li>
                  <li className="p-2">
                    <i className="fas fa-check mr-2" aria-hidden="true" />
                    Comprehensive Assessment
                  </li>
                  <li className="p-2">
                    <i className="fas fa-check mr-2" aria-hidden="true" />
                    Custom Exercise Plan
                  </li>
                  <li className="p-2">
                    <i className="fas fa-check mr-2" aria-hidden="true" />
                    Low Back Pain Resources
                  </li>
                  <li className="p-2">&nbsp;</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MarketingFooter />
    </div>
  );
}

export default connect(
  null,
  null,
)(memo(Pricing));
