import * as httpMethods from '../../Shared/Api';
import * as types from './ActionTypes/VerifyTypes';

export const errorMessageReturn = (data) => (
  {
    type: types.VERIFY_ERROR_MESSAGE,
    data,
  }
);

export const setStatusCode = (data) => (
  {
    type: types.VERIFY_STATUS_CODE,
    data,
  }
);

export const setType = (data) => (
  {
    type: types.VERIFY_TYPE,
    data,
  }
);

const verifySuccessResponse = (dispatch, response) => {
  dispatch(errorMessageReturn(null));
  if (response.data) {
    dispatch(setStatusCode(response.data.status));
  } else {
    dispatch(setStatusCode(''));
  }
};

const verifyErrorResponse = (dispatch, error) => {
  dispatch(errorMessageReturn(error.response.data.message));
};

export const verifySubmit = (payload) => {
  const verifyRequest = (dispatch) => {
    const verify = httpMethods
      .Post('api/verifications/create_code/', payload)
      .then((response) => {
        verifySuccessResponse(dispatch, response);
      })
      .catch((error) => {
        verifyErrorResponse(dispatch, error);
      });

    return verify;
  };

  return verifyRequest;
};
