/* eslint-disable max-len */
import * as types from './Actions/ActionTypes/FunctionalAssessmentTypes';

const initState = {
  q1: '0',
  q2: '',
  q3: '',
  q4: '',
  q5: '',
  q6: '',
  q7: '',
};

const FunctionalAssessmentReducer = (state = initState, action) => {
  switch (action.type) {
    case types.FUNCTIONAL_ASSESSMENT_Q1: return { ...state, q1: action.data };
    case types.FUNCTIONAL_ASSESSMENT_Q2: return { ...state, q2: action.data };
    case types.FUNCTIONAL_ASSESSMENT_Q3: return { ...state, q3: action.data };
    case types.FUNCTIONAL_ASSESSMENT_Q4: return { ...state, q4: action.data };
    case types.FUNCTIONAL_ASSESSMENT_Q5: return { ...state, q5: action.data };
    case types.FUNCTIONAL_ASSESSMENT_Q6: return { ...state, q6: action.data };
    case types.FUNCTIONAL_ASSESSMENT_Q7: return { ...state, q7: action.data };
    default: return state;
  }
};

export default FunctionalAssessmentReducer;
