import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import PhysTreeHeader from "../Templates/Header";
import PhysTreeFooter from "../Templates/Footer";
import QuestionProgressBar from "../Templates/QuestionProgressBar";
import Modal from "react-bootstrap/Modal";

import { useDispatch, useSelector } from "react-redux";
import LowRiskExplanationModal from "../ExplanationModal/LowRiskExplanationModal";
import MedRiskExplanationModal from "../ExplanationModal/MedRiskExplanationModal";
import HighRiskExplanationModal from "../ExplanationModal/HighRiskExplanationModal";
import PhysicianFollowUpModal from "../ExplanationModal/PhysicianFollowUpModal";
import * as actions from "../../new-redux/actions/Results";
import Phase1Results from "./components/Phase1Results";
import Phase2Results from "./components/Phase2Results";
import { useHistory } from "react-router-dom";

const Results = () => {
  const [summaryModal, isSummaryModalVisible] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { results } = useSelector((state) => ({
    results: state.results,
  }));

  const { resultsDetails } = results;

  useEffect(async () => {
    await dispatch(
      actions.getSurveyResults(
        location?.state?.category,
        location?.state?.retake_number
      )
    );
    await dispatch(
      actions.getCustomSolutionPercent(
        location?.state?.category,
        location?.state?.retake_number
      )
    );
  }, []);

  useEffect(async () => {
    if (location?.state?.isNewUser || location?.state?.category === "PT") {
      isSummaryModalVisible(true);
    }
  }, []);

  return (
    <div>
      <PhysTreeHeader />
      <QuestionProgressBar category={3} />

      {location?.state?.category === "Phase1" && (
        <>
          <Phase1Results onClick={() => isSummaryModalVisible(true)} />
          <SummaryModal
            isOpen={summaryModal}
            onClose={() => isSummaryModalVisible(false)}
            score={resultsDetails?.risk_score}
            exercise_program={resultsDetails?.exercise_program}
            action_plan={resultsDetails?.action_plan}
          />
        </>
      )}
      {location?.state?.category === "Phase2" ||
      location?.state?.category === "Phase2WithAlgo" ? (
        <>
          <Phase2Results
            onClick={() => {
              history.push(
                `/custom-solution/${location.state.category}/${location.state.retake_number}`
              );
            }}
          />
        </>
      ) : null}
      {location?.state?.category === "PT" ? (
        <>
          <Phase2Results
            onClick={() => {
              history.push(
                `/custom-solution/${location.state.category}/${location.state.retake_number}`
              );
            }}
            modal={() => {isSummaryModalVisible(true);}}
          />
          <SummaryModal
            isOpen={summaryModal}
            onClose={() => isSummaryModalVisible(false)}
            exercise_program={resultsDetails?.exercise_program}
            action_plan={resultsDetails?.action_plan}
          />
        </>
      ) : null}
      <PhysTreeFooter />
    </div>
  );
};

export default Results;

const SummaryModal = ({ isOpen, onClose, score, exercise_program, action_plan }) => {
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      animation={true}
      centered
      contentClassName="rounded-20"
    >
      <div className="modal-content rounded-20">
        <div className="modal-body p-5   ">
          <a className="close d-block" onClick={onClose}>
            <i className="fas fa-times txt-color-dark-grey" />
          </a>
          <h3 className="mt-md-5 mt-1 mb-4 txt-color-primary">
            Explanation of Results
          </h3>
          {score <= 12 && <LowRiskExplanationModal />}
          {score > 12 && score <= 28 ? <MedRiskExplanationModal /> : null}
          {score > 28 && <HighRiskExplanationModal />}
          {exercise_program === "See a Physical Therapist" && <PhysicianFollowUpModal />}
          {action_plan === "Follow up with a healthcare provider." && <PhysicianFollowUpModal />}
        </div>
      </div>
    </Modal>
  );
};
