import * as api from "../../api/CustomSolutionApi";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const getAllCustomSolutions = (category, retake_number) => {
  return async (dispatch) => {
    try {
      const id = await AsyncStorage.getItem("userId");
      let response = await api.getCustomSolution(id, category, retake_number);
      if (response?.status === 200) {
        dispatch({
          type: "GET_CUSTOM_SOLUTIONS",
          custom_solution_payload: response.data.custom_solution,
          education_payload: response.data.education,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
