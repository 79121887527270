import React from "react";
import { useHistory } from "react-router";

import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";

const RetakeTestModal = ({ onClose, isOpen }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      animation={true}
      centered
      contentClassName="rounded-20"
    >
      <div className="modal-content rounded-20">
        <div className="modal-body p-5 p-md-4  ">
          <a onClick={onClose} className="close">
            <i className="fas fa-times txt-color-dark-grey" />
          </a>
          <div className="d-block">
            <h3 className="txt-color-dark h3 font-weight-bold text-center mt-md-4 mb-2">
              I would like to retake the questionnaire because:
            </h3>
            <div className="mx-auto text-center mt-4 px-md-4">
              {/* <label className="btn btn-outline-secondary btn-radio mr-1 btn-block">
                <input
                  type="radio"
                  autoComplete="off"
                  className="text-dark font-weight-bold d-none"
                  data-dismiss="modal"
                  onClick={() => {
                    dispatch({ type: "IS_WARNING_MODAL_VISIBLE" });
                  }}
                />
                I want to change my original answers.
              </label> */}
              <label className="btn btn-outline-secondary btn-radio mr-1 btn-block">
                <input
                  type="radio"
                  autoComplete="off"
                  data-dismiss="modal"
                  className="text-dark font-weight-bold d-none"
                  onClick={() => {
                    dispatch({ type: "IS_RETAKE_TEST" });
                    history.push("/pain-warning")
                  }}
                />
                I feel ready for the next level.
              </label>
              <label className="btn btn-outline-secondary btn-radio mr-1 btn-block">
                <input
                  type="radio"
                  autoComplete="off"
                  data-dismiss="modal"
                  className="text-dark font-weight-bold d-none"
                  onClick={() => {
                    dispatch({ type: "IS_RETAKE_TEST" });
                    history.push("/pain-warning")
                  }}
                />
                My back condition changed dramatically.
              </label>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RetakeTestModal;
