import React from "react";
import * as httpMethods from "../../Shared/Api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useHistory } from "react-router";

import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";

const WarningModal = ({ onClose, isOpen }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onDeleteHandler = async () => {
    const id = await AsyncStorage.getItem("userId");
    const payload = {
      user_id: id,
      retake_number: 1,
      category: "Phase1",
      survey_id: 1,
    };
    const request = httpMethods
      .Post(`/api/surveys/delete_result/`, payload)
      .then(async (response) => {
        console.log(response);
        if (response.status === 200) {
          await dispatch({ type: "SET_WARNING_MODAL_TO_FALSE" });
          history.push("/disclaimer");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return request;
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      animation={true}
      centered
      contentClassName="rounded-20"
    >
      <div className="modal-content rounded-20">
        <div className="modal-body p-5 p-md-4  ">
          <a className="close" data-dismiss="modal" onClick={onClose}>
            <i className="fas fa-times txt-color-dark-grey" />
          </a>
          <div className="d-block">
            <i className="mx-auto d-table display-4 fas fa-exclamation-triangle font-weight-bold text-warning" />
            <h3 className="txt-color-dark h3 font-weight-bold text-center mt-md-4 mb-2">
              Warning
            </h3>
            <p className="text-center">
              This will Delete your previous answers.
            </p>
            <div className="mx-auto  text-center mt-4">
              <label>
                <input
                  type="radio"
                  autoComplete="off"
                  className="text-dark font-weight-bold d-none"
                />
                <a
                  className="btn btn-primary btn-radio mr-1 btn-fw-200"
                  onClick={onDeleteHandler}
                >
                  Continue
                </a>
              </label>
              <label className="btn btn-outline-secondary btn-radio mr-1 btn-fw-200">
                <button
                  className="text-dark font-weight-bold d-none"
                  onClick={onClose}
                />
                Cancel
              </label>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WarningModal;
