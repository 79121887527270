import * as api from "../../api/FunctionalAssessmentApi";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const getAnswers = () => {
  return async (dispatch) => {
    try {
      const userId = await AsyncStorage.getItem("userId");
      let response = await api.getAnswers(userId);
      if (response.status === 200) {
        dispatch({ type: "GET_FA_ANSWERS", payload: response.data.data });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getRetakeNumber = () => {
  return async (dispatch) => {
    try {
      const userId = await AsyncStorage.getItem("userId");
      let response = await api.getRetakeNumber(userId);
      dispatch({ type: "GET_FA_RETAKE_NUMBER", payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getPhase1RetakeNumber = () => {
  return async (dispatch) => {
    try {
      const userId = await AsyncStorage.getItem("userId");
      let response = await api.getPhase1RetakeNumber(userId);
      dispatch({ type: "GET_FA_RETAKE_NUMBER", payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

export const saveFunctionalAssessmentAnswer = () => {
  return async (dispatch, getState) => {
    try {
      const id = await AsyncStorage.getItem("userId");
      const isPhaseTwo = getState().home.isPhaseTwo;
      const funcAssessment = getState().fa;
      const { retakeNumber, faAnswer } = funcAssessment;

      const answerType = {
        q1: 8, // percent
        q2: 9, // text
        q3: 6, // range 0-10
        q4: 9,
        q5: 6,
        q6: 9,
        q7: 6,
      };

      const payload = {
        user_id: id,
        survey: isPhaseTwo ? 7 : 5,
        retake_number: retakeNumber,
        answer: JSON.stringify(faAnswer),
        answer_type: JSON.stringify(answerType),
      };
      let response = await api.saveFuncationalAssessmentAnswer(payload);
      if (response.status === 200) {
        dispatch({ type: "SAVE_FA_ANSWER" });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
