import React from "react";

import Modal from "react-bootstrap/Modal";

const ReturningModal = ({ onClose, isOpen }) => {
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      animation={true}
      centered
      contentClassName="rounded-20"
      dialogClassName="modal-lg"
    >
      <div className="modal-body p-3 p-md-5">
        <a className="close" data-dismiss="modal" onClick={onClose}>
          <i aria-hidden="true" className="fas fa-times txt-color-dark-grey"></i>
        </a>
        <div className="row mt-md-5">
          <div className="col-12 d-flex align-items-center mx-0 px-0 mb-4">
            <h4 className="txt-color-primary m-0">
              Re-take the test for Low Back Pain Assessment Tool
            </h4>
          </div>
          <div className="col-12 px-0">
            <p>
              We hope that you are progressing well and returning to the activities you enjoy. The PhysioTree Team would like to check in with you and update your exercise program based on how you are doing currently.</p>
            <p>
              Please take a few moments to fill out the following survey and answer a few questions regarding your exercise program. This should take approximately 5 minutes. We will analyze the updated information you provide, and modify your exercise program to fit your needs. Thank you for your time and effort. Keep up the good work!</p>
          </div>
          <a href="/pain-warning" data-aim="retake-test" id="alert-btn-open" className="btn btn-primary btn-radio mt-3" data-dismiss="modal">
            Get Started
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ReturningModal;
