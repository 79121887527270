/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment';
import {
  Page, Text, View, Document, StyleSheet,
} from '@react-pdf/renderer';

// TODO: USE PROPER DESIGN
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const JournalPDF = (props) => {
  const { entryData } = props;
  return (
    <Document>
      {entryData.map((journalEntry) => (
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text>
              SUBJECT:
              {' '}
              {journalEntry.title}
            </Text>
            <Text>
              RATING:
              {' '}
              {journalEntry.rating}
              %
            </Text>
            <Text>
              NOTES:
              {' '}
              {journalEntry.notes}
            </Text>
            <Text>
              DATE:
              {' '}
              {moment(new Date(journalEntry.date_saved)).format('DD MMM YYYY')}
            </Text>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default JournalPDF;
