const initialState = {
  answer: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_LBS_ANSWER":
      return {
        ...state,
        answer: {
          ...state.answer,
          ...action.payload,
        },
      };
    case "RESET_LBS":
      return initialState;
    default:
      return state;
  }
};

export default reducer;
