import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { PDFDownloadLink } from '@react-pdf/renderer'; // DOCUMENTATION: https://react-pdf.org/
import moment from 'moment';
import $ from 'jquery';
import iconDownload from '../Images/icon-download.svg';
import JournalPDF from './JournalPDF';

function JournalDownloadModal(props) {
  const [downloadType, setDownloadType] = useState('csv');
  const {
    journalList,
  } = props;

  const csvData = journalList.map((journal) => {
    let journalData = journal;
    journalData.date_saved = moment(new Date(journal.date_saved)).format('MM/DD/YY');
    return journalData;
  });

  let downloadButtonElm;

  const downloadClickHandler = () => {
    $('#journal-download-done').modal('toggle');
  };

  if (downloadType === 'csv') {
    downloadButtonElm = (
      <span onClick={downloadClickHandler}>
        <CSVLink
          className="text-decoration-none btn-continue btn-primary px-3 d-table"
          data={csvData}
          filename="journal_list.csv"
        >
          Download
        </CSVLink>
      </span>
    );
  }

  if (downloadType === 'pdf') {
    downloadButtonElm = (
      <span onClick={downloadClickHandler}>
        <PDFDownloadLink
          document={<JournalPDF entryData={journalList} />}
          fileName="journal_list.pdf"
          className="text-decoration-none btn-continue btn-primary px-3 d-table"
        >
          Download
        </PDFDownloadLink>
      </span>
    );
  }

  return (
    <div className="modal fade" id="journal-download" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered  ">
        <div className="modal-content rounded-20">
          <div className="modal-body p-5   ">
            <a className="close" data-dismiss="modal">
              <i className="fa fa-times txt-color-dark-grey" />
            </a>
            <div className="row">
              <div className="d-flex align-items-center mt-5 mb-4">
                <img alt="icon download" src={iconDownload} className="mr-3  " />
                <h3 className="txt-color-dark   mb-0 mt-2  ">
                  Download Journal
                </h3>
              </div>
            </div>
            <div className="row mt-4 mx-0">
              <form action="" className="form-row col-12 px-0">
                <div className="form-group col-sm-8 col-12 px-0 mb-3">
                  <select
                    name=""
                    id=""
                    className="form-control border rounded px-2"
                    style={{ height: 60 }}
                    onChange={(event) => setDownloadType(event.target.value)}
                  >
                    <option value="csv" selected={downloadType === 'csv'}>
                      CSV
                    </option>
                    <option value="pdf" selected={downloadType === 'pdf'}>
                      PDF
                    </option>
                  </select>
                </div>
                <div className="col-sm-4 col-12">
                  {downloadButtonElm}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JournalDownloadModal;
