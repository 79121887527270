import { _request } from "./BaseApi";

export const getCustomSolution = async (id, category, retake_number) => {
  try {
    let url = `api/surveys/get_custom_solution/?user_id=${id}&category=${category}&retake_number=${retake_number}`;
    let result = await _request("get", url);
    return result;
  } catch (err) {
    return err;
  }
};

export const setHasWatched = async (payload) => {
  try {
    let url = `api/surveys/set_has_watched/`;
    let result = await _request("post", url, payload);
    return result;
  } catch (err) {
    return err;
  }
};

export const saveJournalEntry = async (payload) => {
  try {
    let url = `api/journal/save_journal/`;
    let result = await _request("post", url, payload);
    return result;
  } catch (err) {
    return err;
  }
};
