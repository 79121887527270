import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PhysTreeHeader from "./Templates/Header";
import PhysTreeFooter from "./Templates/Footer";
import ProfileSettings from "./SettingsPage/ProfileSettings";
import PasswordSettings from "./SettingsPage/PasswordSettings";
import NotificationSettings from "./SettingsPage/NotificationSettings";
import AccountSettings from "./SettingsPage/AccountSettings";

function Settings() {
  const [activeForm, setActiveForm] = useState(0);
  const history = useHistory();
  const loggedUserId = sessionStorage.getItem('userId');

  if (!loggedUserId) history.push("/");

  const getActiveForm = () => {
    switch (activeForm) {
      case 0:
        return <ProfileSettings userId={loggedUserId} />;
      case 1:
        return <PasswordSettings userId={loggedUserId} />;
      case 2:
        return <NotificationSettings userId={loggedUserId} />;
      case 3:
        return <AccountSettings userId={loggedUserId} />;
      default:
        return <ProfileSettings userId={loggedUserId} />;
    }
  };

  const changeFormHandler = (event, formType) => {
    event.preventDefault();
    setActiveForm(formType);
  };

  return (
    <div>
      <ToastContainer />
      <PhysTreeHeader />
      <div className="site-page container-fluid p-0">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <h2 className="txt-color-dark settings-head-title">Settings</h2>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12 col-md-3 mb-5 settings">
              <div className="settings-menu d-flex d-md-block justify-content-center align-items-center">
                <a
                  className={
                    activeForm === 0
                      ? "settings-menu-item active"
                      : "settings-menu-item"
                  }
                  onClick={(event) => {
                    changeFormHandler(event, 0);
                  }}
                >
                  <i className="fas fa-user-circle" />
                  <span className="settings-menu-item-link">Profile</span>
                </a>

                <a
                  className={
                    activeForm === 1
                      ? "settings-menu-item active"
                      : "settings-menu-item"
                  }
                  onClick={(event) => {
                    changeFormHandler(event, 1);
                  }}
                >
                  <i className="fas fa-lock" />
                  <span className="settings-menu-item-link">
                    Change Password
                  </span>
                </a>

                <a
                  className={
                    activeForm === 2
                      ? "settings-menu-item active"
                      : "settings-menu-item"
                  }
                  onClick={(event) => {
                    changeFormHandler(event, 2);
                  }}
                >
                  <i className="fas fa-bell" />
                  <span className="settings-menu-item-link">Notifications</span>
                </a>

                <a
                  className={
                    activeForm === 3
                      ? "settings-menu-item active"
                      : "settings-menu-item"
                  }
                  onClick={(event) => {
                    changeFormHandler(event, 3);
                  }}
                >
                  <i className="fas fa-user" />
                  <span className="settings-menu-item-link">Account</span>
                </a>

                <a
                  className={
                    activeForm === 4
                      ? "settings-menu-item active"
                      : "settings-menu-item"
                  }
                  onClick={(event) => {
                    changeFormHandler(event, 4);
                    history.push("/privacy-policy");
                  }}
                >
                  <i className="fas fa-scroll" />
                  <span className="settings-menu-item-link">
                    Privacy Policy
                  </span>
                </a>

                <a
                  className={
                    activeForm === 5
                      ? "settings-menu-item active"
                      : "settings-menu-item"
                  }
                  onClick={(event) => {
                    changeFormHandler(event, 5);
                    history.push("/terms-and-conditions");
                  }}
                >
                  <i className="fas fa-user-secret" />
                  <span className="settings-menu-item-link">
                    Terms and Condition
                  </span>
                </a>
              </div>
            </div>

            {getActiveForm()}
          </div>
        </div>
      </div>
      <PhysTreeFooter />
    </div>
  );
}

export default Settings;
