import React, { memo } from 'react';
import { connect } from 'react-redux';
import logo from '../Images/logo.svg';

function PlanHeader() {
  return (
    <div className="site-header">
      <nav className="navbar navbar-expand-lg justify-content-between">
        <img src={logo} alt="" />
      </nav>
    </div>
  );
}

export default connect(
  null,
  null,
)(memo(PlanHeader));
