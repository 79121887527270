import React from "react";
import Modal from "react-bootstrap/Modal";

const ShareModal = ({ isOpen, onClose }) => {
  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        animation={true}
        centered
        contentClassName="rounded-20"
      >
        <div className="modal-content rounded-20 ">
          <div className="modal-body p-5 ">
            <a className="close d-block" onClick={onClose}>
              <i className="fas fa-times txt-color-dark-grey" />
            </a>
            <div className="row">
              <div className="col-12 d-flex align-items-center mx-0 px-0 justify-content-center mb-3">
                <h2 className="form-heading">Share The Love</h2>
              </div>
              <div className="col-12 text-center mb-3">
                <p className="completely-recovered">
                  I just recovered from low back pain using PhysioTree. It’s
                  like having a coach right in my pocket. Get relief now.
                </p>
              </div>
              <div className="col-12 text-center">
                <p className="mb-1 completely-recovered">
                  Share with your friends!
                </p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://app.physiotree.com/"
                  className="fb-xfbml-parse-ignore"
                  aria-label="facebook"
                >
                  <i className="fab fa-facebook-square fa-lg mr-2 completely-recovered-icon" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/intent/tweet?text=I%20recommend%20PhysioTree%20for%20Lower%20Back%20Pain!%20https://app.physiotree.com/"
                  aria-label="twitter"
                >
                  <i className="fab fa-twitter fa-lg mr-2 completely-recovered-icon" />
                </a>
                {/* Instagram has no share functionality */}
                {/* <a href="" aria-label="instagram">
                        <i className="fab fa-instagram fa-lg mr-2 completely-recovered-icon" />
                    </a> */}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.linkedin.com/shareArticle?mini=true&url=https://app.physiotree.com/"
                  aria-label="linkedin"
                >
                  <i className="fas fa-envelope fa-lg completely-recovered-icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShareModal;
