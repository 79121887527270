const initialState = {
  isAuth: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_IS_AUTH":
      return {
        ...state,
        isAuth: true,
      };
    case "RESET_AUTH":
      return initialState;
    default:
      return state;
  }
};

export default reducer;
