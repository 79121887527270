import * as types from './Actions/ActionTypes/ProcedureTreatmentTypes';

const initState = {
  errorMessage: '',
  statusCode: '',
  q1: '',
  q2: '',
};

const ProcedureTreatmentReducer = (state = initState, action) => {
  switch (action.type) {
    case types.PROCEDURE_TREATMENT_ERROR_MESSAGE: return { ...state, errorMessage: action.data };
    case types.PROCEDURE_TREATMENT_STATUS_CODE: return { ...state, statusCode: action.data };
    case types.PROCEDURE_TREATMENT_Q1: return { ...state, q1: action.data };
    case types.PROCEDURE_TREATMENT_Q2: return { ...state, q2: action.data };
    default: return state;
  }
};

export default ProcedureTreatmentReducer;
