import React from "react";
import customSolutionWizard from "../../Images/custom_solutions_wizard.svg";
import ReactECharts from "echarts-for-react";
import resultLowMeter from "../../Images/low-risk.svg";
import resultMediumMeter from "../../Images/medium-risk.svg";
import resultHighMeter from "../../Images/high-risk.svg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

const Phase1Results = ({ onClick }) => {
  const history = useHistory();
  const { results } = useSelector((state) => ({
    results: state.results,
  }));

  const { customSolutionPercent, resultsDetails, data, userName } = results;

  const options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      top: "7%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: [""],
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        max: 100,
      },
    ],
    series: [
      {
        name: "Your Percentage of Full Function",
        type: "bar",
        color: ["rgb(0, 148, 68)"],
        barWidth: "55%",
        data: [resultsDetails?.functional_limitation],
      },
    ],
  };

  return (
    <main className="container my-5">
      <div className="w-100  mw-100 border rounded-20 p-5 h-100 card-box mx-auto bg-color-dark">
        <h3 className="h3 text-white mb-4">Results</h3>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <h4>
              <span className="txt-color-primary font-weight-bold">
                Results Summary:
              </span>
              <a onClick={onClick}>
                <i className="fas fa-info-circle fa-xs txt-color-secondary ml-2" />
              </a>
            </h4>
            <div
              className="bg-color-white-opaque rounded-10 p-3 mt-4"
              style={{ maxWidth: 400 }}
            >
              <table className="table table-borderless txt-color-white table-sm font-weight-normal mb-0">
                <thead>
                  <tr className="txt-md txt-color-light-grey ">
                    <th scope="col">Risk of Ongoing Pain</th>
                    <th scope="col">Functional Ability</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {resultsDetails?.risk_score <= 12
                        ? "Low"
                        : resultsDetails?.risk_score > 12 &&
                          resultsDetails?.risk_score <= 28
                        ? "Medium"
                        : resultsDetails?.risk_score > 28
                        ? "High"
                        : ""}
                    </td>
                    <td>
                      {resultsDetails?.functional_limitation + " out  of 100"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h4 className="mt-4 mb-3">
              <span className="txt-color-primary font-weight-bold">
                Action Plan:
              </span>
              <span className="txt-color-white"> Guided Self Management</span>
            </h4>
            <dl className="row mb-0">
              <dt className="col-sm-4 col-5 mb-1">
                <span className="txt-md txt-color-white">
                  Education Program:
                </span>
              </dt>
              <dd className="col-sm-8 col-6 mb-1">
                <span className="txt-md txt-color-white">
                  {resultsDetails?.education_program}
                </span>
              </dd>
            </dl>
            <dl className="row mb-0">
              <dt className="col-sm-4 col-5 mb-1">
                <span className="txt-md txt-color-white">
                  Exercise Program:
                </span>
              </dt>
              <dd className="col-sm-8 col-6 mb-1">
                <span className="txt-md txt-color-white">
                  {resultsDetails?.exercise_program}
                </span>
              </dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4 col-5 mb-1">
                <span className="txt-md txt-color-white">Date:</span>
              </dt>
              <dd className="col-sm-8 col-6 mb-1">
                <span className="txt-md txt-color-white">
                  {moment(data?.created_date).format("MM/DD/YYYY hh:mm A")}
                </span>
              </dd>
            </dl>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="card-group">
              <div className="card p-4">
                <h6>Risk Assessment</h6>
                <img
                  alt=""
                  src={
                    resultsDetails?.risk_score <= 12
                      ? resultLowMeter
                      : resultsDetails?.risk_score > 12 &&
                        resultsDetails?.risk_score <= 28
                      ? resultMediumMeter
                      : resultsDetails?.risk_score > 28
                      ? resultHighMeter
                      : ""
                  }
                  className="img-fluid mt-5"
                />
                <small className="text-center">Your Risk Score</small>
              </div>
              <div className="card p-4">
                <h6>Functional Assessment</h6>
                <div
                  id="bar-chart"
                  style={{ height: 200, width: 200, maxWidth: 200 }}
                >
                  <ReactECharts option={options} style={{ height: 200 }} />
                </div>
                <small className="text-center">
                  Percentage of Full Function
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-12 text-center  ">
          <p>
            <span className="font-weight-bold">{userName}</span>
            , Thank You for using our customized assessment tool. <br />
            Here is your customized PhysioTree program based off your current
            results.
          </p>
        </div>
      </div>
      <div className="bg-color-gradient-1 p-5 rounded-20">
        <div className="row justify-content-center align-items-center w-75 mx-auto">
          <div className="col-md-4">
            <img alt="" src={customSolutionWizard} />
          </div>
          <div className="col-md-6">
            <h2 className="mb-5 text-center">Your Custom Solution</h2>
            <div className="text-center">
              {/*    <p className="mb-2"> {`${customSolutionPercent}%`} </p> */}
              <div className="progress" style={{ width: "100%" }}>
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: `${customSolutionPercent}%` }}
                  aria-valuenow={customSolutionPercent}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <a
                onClick={() => {
                  history.push(`/custom-solution/Phase1/1`);
                }}
                style={{ cursor: "pointer" }}
                className="btn btn-primary btn-continue  mt-5 mx-auto text-center d-inline-block"
              >
                Continue
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Phase1Results;
