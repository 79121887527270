export const REGISTER_FIRST_NAME = 'REGISTER_FIRST_NAME';
export const REGISTER_LAST_NAME = 'REGISTER_LAST_NAME';
export const REGISTER_GENDER = 'REGISTER_GENDER';
export const REGISTER_YEAR = 'REGISTER_YEAR';
export const REGISTER_EMAIL = 'REGISTER_EMAIL';
export const REGISTER_PHONE_NUM = 'REGISTER_PHONE_NUM';
export const REGISTER_ZIP_CODE = 'REGISTER_ZIP_CODE';
export const REGISTER_PASSWORD = 'REGISTER_PASSWORD';
export const REGISTER_ERROR_MESSAGE = 'REGISTER_ERROR_MESSAGE';
export const REGISTER_USER_ID = 'REGISTER_USER_ID';
