import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import RangeSlider from "react-bootstrap-range-slider";
import { useForm, Controller } from "react-hook-form";

const ExerciseJournalEntry = ({
  isOpen,
  onClose,
  title,
  onSubmit,
  isExerciseProgram,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      slider: 0,
    },
    criteriaMode: "all",
    mode: "onChange",
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      animation={true}
      centered
      contentClassName="rounded-20 modal-lg"
    >
      <div className="modal-content rounded-20">
        <div className="modal-body p-5 p-md-4  ">
          <a className="close" data-dismiss="modal" onClick={onClose}>
            <i className="fas fa-times txt-color-dark-grey" />
          </a>
          <a
            style={{ cursor: "pointer" }}
            className="link mt-4 d-block txt-color-dark-grey"
            data-dismiss="modal"
            onClick={onClose}
          >
            <i className="fas fa-arrow-left mr-2" />
            Back to Program
          </a>
          <h3 className="txt-color-primary h3 font-weight-bold mt-md-4 mb-5">
            {title}
          </h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-group col-12 col-md-12">
                <div htmlFor="" className="form-label">
                  {!isExerciseProgram
                    ? "Rate this exercise:"
                    : "How helpful was this video?"}
                </div>
                <Controller
                  name="slider"
                  control={control}
                  render={({ field }) => (
                    <RangeSlider
                      variant="success"
                      tooltipPlacement="top"
                      {...field}
                    />
                  )}
                  rules={{ required: true }}
                />

                <div className="row justify-content-between d-flex mt-3">
                  <div className="col">
                    <p className="text-left sub">
                      {!isExerciseProgram ? "Super Easy" : "Not at all helpful"}
                    </p>
                  </div>
                  <div className="col">
                    <p className="text-right sub">
                      {!isExerciseProgram ? "Impossible" : "Extremely helpful"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-12 col-md-12">
                <label htmlFor="notes-input-2" className="form-label">
                  Notes
                </label>
                <textarea
                  id="notes-input-2"
                  className="form-control"
                  rows="3"
                  {...register("notes")}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-12 col-md-4">
                <button
                  type="submit"
                  className="btn btn-primary btn-md btn-continue"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ExerciseJournalEntry;
