import React, { useState } from 'react';
import { toast } from 'react-toastify';
import $ from 'jquery';
import * as httpMethods from '../../Shared/Api';
import iconJournal from '../Images/icon-journal.svg';
import { checkEmail } from '../Utility/InputValidation';

function JournalShareModal(props) {
  const [email, setEmail] = useState('');
  const [preventSpam, setPreventSpam] = useState(false);
  const {
    journalList,
  } = props;

  const enablePreventSpam = () => {
    setPreventSpam(true);
    setTimeout(() => {
      setPreventSpam(false);
    }, 2000);
  };

  const submitShareButtonHandler = () => {
    enablePreventSpam();
    const payload = {
      email,
      journal_list: journalList,
    };

    httpMethods
      .Post('api/journal/share_journal/', payload)
      .then(() => {
        setEmail('');
        $('#journal-share').modal('toggle');
        $('#journal-share-done').modal('toggle');
      })
      .catch(() => {
        toast.error('Failed to send journal. Please try again.', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  return (
    <div className="modal fade" id="journal-share" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered  ">
        <div className="modal-content rounded-20">
          <div className="modal-body p-5   ">
            <a className="close" data-dismiss="modal">
              <i className="fa fa-times txt-color-dark-grey" />
            </a>
            <div className="row">
              <div className="d-flex align-items-center mt-5 mb-4">
                <img alt="icon journal" src={iconJournal} className="mr-3  " />
                <h3 className="txt-color-dark   mb-0 mt-2  ">
                  Share Your Journal
                </h3>
              </div>
              <div className="col-12 px-0" />
            </div>
            <div className="row mt-4 mx-0">
              <div className="form-row">
                <div className="form-group">
                  <div className="form-label mb-0">
                    Email Address
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="you@example.com"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <p style={{ fontSize: 14, color: 'red' }}>{checkEmail(email) || email === '' ? '' : 'Invalid email'}</p>
                  <button
                    type="button"
                    className="btn btn-success mt-4 d-table"
                    style={{ padding: '15px 30px', borderRadius: '10px', fontSize: '18px' }}
                    disabled={!checkEmail(email) || preventSpam}
                    onClick={submitShareButtonHandler}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JournalShareModal;
