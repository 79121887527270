import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import PlanHeader from "./PlanHeader";
import PhysTreeFooter from "../Templates/Footer";

function Plan() {
  useEffect(() => {
    const el = document.createElement("script");
    el.onload = () => {
      window.Chargebee.init({
        site: "physiotree-test",
      });
      window.Chargebee.registerAgain();
    };
    el.setAttribute("src", "https://js.chargebee.com/v2/chargebee.js");
    document.body.appendChild(el);
  }, []);

  return (
    <div>
      <PlanHeader />
      <header class="gray-bg">
        <div class="container">
          <div class="col">
            <h1 class="heading">Pricing</h1>
          </div>
        </div>
      </header>
      <div className="site-page container-fluid p-0">
        <div className="container p-5">

          <div class="text-center mb-5">
            <h2>Physio Tree Low Back Pain Program</h2>
            <p>Regular Price: $25</p>

          </div>

          <div className="row justify-content-center mb-4 mb-lg-0">
            <div class="card pricing-card p-4 h-100">
              <div class="card-bordered radius-20 p-4">
                <div class="price-lg text-center mb-5 ml-4 mr-4">
                  <span class="symbol d-none"><i class="fas fa-dollar-sign fa-3x"></i></span>
                  <span class="amt">&nbsp;FREE</span>
                  <span class="per"><s>$25&nbsp;</s></span>
                  <span class="d-block sub ">This is a limited time offer.</span>
                </div>

                <ul className="list-unstyled">
                  <li className="p-2">
                    <i className="fas fa-check mr-2" aria-hidden="true" />
                    Full Access to PhysioTree
                  </li>
                  <li className="p-2">
                    <i className="fas fa-check mr-2" aria-hidden="true" />
                    Comprehensive Assessment
                  </li>
                  <li className="p-2">
                    <i className="fas fa-check mr-2" aria-hidden="true" />
                    Custom Exercise Plan
                  </li>
                  <li className="p-2">
                    <i className="fas fa-check mr-2" aria-hidden="true" />
                    Low Back Pain Resources
                  </li>
                  <li className="p-2">
                    <i className="fas fa-check mr-2" aria-hidden="true" />
                    Get Relief
                  </li>
                </ul>

                <span class="d-block text-center">Enter code: <span className="font-weight-bold">BACKPAIN</span> at checkout</span>

                <div className="px-0">
                  <a
                    id="subscribe-1-month"
                    data-cb-type="checkout"
                    className="btn btn-primary btn-continue btn-block mb-4"
                    data-cb-item-0="1-month-USD-Monthly"
                    data-cb-item-0-quantity="1"
                  >
                    Select
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PhysTreeFooter />
    </div>
  );
}

export default connect(null, null)(memo(Plan));
