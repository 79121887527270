import React, { memo, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import * as actions from "../../Redux/Actions/ForgotPassAction";
import { charCountCheck } from "../Utility/InputValidation";

function ForgotPasswordVerify(props) {
  const [verCode, setVerCode] = useState("");
  const { setFormTemplate, email, userId } = props;

  const backToFormHandler = () => {
    setFormTemplate(0);
  };

  const submitHandler = async () => {
    const verifyRequestPayload = {
      user_id: userId,
      code: verCode,
    };

    const response = await props.verifyForgotPassCode(verifyRequestPayload);
    if (response.status === 200) {
      setFormTemplate(3);
    } else {
      toast.error("Invalid verification code.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const codeGenerateResponse = (generateCodeResponse) => {
    if (generateCodeResponse.status === 200 && generateCodeResponse.data) {
      toast.success("Verification code has been sent to email.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.error("Unable to generate verification code. Please try again.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  return (
    <div className="login-form-container">
      <form className="login-form">
        <div className="form-group  mb-5">
          <h2 className="h1 font-weight-bold txt-color-dark">Verify Code</h2>
          <span className="form-title-head">
            Please enter the code we sent to
            {` ${email}`}
          </span>
        </div>
        <div className="form-group mb-5">
          <div className="form-row">
            <div className="col-12 d-flex p-0">
              <input
                type="tel"
                className="verify-input"
                style={{
                  maxWidth: "100%",
                  letterSpacing: 30,
                  paddingLeft: 10,
                }}
                maxLength="4"
                placeholder="0000"
                onChange={(event) => {
                  setVerCode(event.target.value);
                }}
                value={verCode}
              />
            </div>
          </div>
        </div>

        <div className="form-group mb-5">
          <p>
            Didn’t receive a code?
            <a
              href="#"
              className="txt-color-primary font-weight-bold ml-2"
              onClick={async () => {
                const payload = {
                  user_id: userId,
                  user_contact: email,
                  sent_by: "email",
                };
                const generateCodeResponse =
                  await props.generateVerificationCode(payload);
                codeGenerateResponse(generateCodeResponse);
              }}
            >
              Resend Verification Code
            </a>
          </p>
        </div>

        <div className="form-group mb-5">
          <button
            type="button"
            className="btn btn-success login-btn"
            data-aim="verify-alert"
            id="alert-btn-open"
            onClick={submitHandler}
            disabled={!charCountCheck(verCode, 4)}
          >
            Submit
          </button>
        </div>

        <div className="form-group mb-5">
          <a
            style={{ cursor: "pointer" }}
            className="txt-color-secondary"
            onClick={() => {
              backToFormHandler();
            }}
          >
            <i className="fas fa-arrow-left mr-3" />
            Back to Login
          </a>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  email: state.forgotPass.email,
  userId: state.forgotPass.userId,
  errorMessage: state.forgotPass.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  verifyForgotPassCode: (payload) =>
    dispatch(actions.verifyForgotPassCode(payload)),
  generateVerificationCode: (payload) =>
    dispatch(actions.generateVerificationCode(payload)),
});

ForgotPasswordVerify.propTypes = {
  verifyForgotPassCode: PropTypes.func.isRequired,
  generateVerificationCode: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(ForgotPasswordVerify));
