import React, { useEffect } from 'react';

function AccountSettings() {
  useEffect(() => {
    const el = document.createElement('script');
    el.onload = () => {
      window.Chargebee.init({
        site: 'physiotree-test',
      });
      window.Chargebee.registerAgain();
    };
    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
    document.body.appendChild(el);
  }, []);

  return (
    <div className="col-12 col-md-9 px-3 px-md-5">
      <form action="" className="form-box-fluid shadow-none">
        <div className="form-group">
          <h3 className="form-heading mb-5">Account</h3>
        </div>

        <a data-cb-type="portal" className="btn btn-primary btn-continue">Manage your chargebee account</a>

      </form>
    </div>
  );
}

export default AccountSettings;
