import moment from "moment";

const charCountCheck = (str, count) => {
  if (!str) {
    return true;
    // not required to fill up
  }

  const string = String(str);
  if (string.length >= count) {
    return true;
  }
  return false;
};

const charSignupCountCheck = (str, count) => {
  const string = String(str);
  if (string.length >= count) {
    return true;
  }
  return false;
};

const charDateCheck = (str, count) => {
  const string = String(str);

  let noFormat = str.replace(/\D/g, '');
  const month = noFormat.substring(0, 2);
  const date = noFormat.substring(2, 4);
  const year = noFormat.substring(4, 8);
  const currentDate = moment(new Date(), "DD/MM/YYYY");
  const inputDate = moment(new Date(year, month - 1));
  var end = moment(inputDate).daysInMonth();
  if (string.length >= count && parseInt(month) <= 12 && parseInt(date) <= end && parseInt(year) <= currentDate.year()) {
    return true;
  }
  return false;
}

const charYearCheck = (str, count) => {
  const currentDate = moment(new Date(), "DD/MM/YYYY");
  
  if (str.length == count && parseInt(str) <= currentDate.year()) {
    return true;
  }
  return false;
};

const checkForNumber = (str) => {
  const re = /\d/;
  if (str.match(re)) {
    return true;
  }
  return false;
};

const checkForSpChar = (str) => {
  const re = /[^a-zA-Z0-9]+/;
  if (str.match(re)) {
    return true;
  }
  return false;
};

const checkForName = (strToCheck, regexStr1, regexStr2) => {
  const str1 = regexStr1.toLowerCase();
  const str2 = regexStr2.toLowerCase();
  const referenceStr = strToCheck.toLowerCase();

  const regex1 = new RegExp(str1, 'g');
  const regex2 = new RegExp(str2, 'g');
  if (regex1.test(referenceStr) || regex2.test(referenceStr)) {
    return false;
  }
  return true;
};

const ageValidation = (year, requiredAge) => {
  const dateToday = new Date();

  if (!year) {
    return false;
  }

  if (dateToday.getFullYear() - year >= requiredAge && year >= 1900) {
    return true;
  }
  
  return false;
};

const checkEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export {
  charCountCheck,
  charSignupCountCheck,
  charDateCheck,
  charYearCheck,
  checkForNumber,
  checkForSpChar,
  checkForName,
  ageValidation,
  checkEmail,
};
