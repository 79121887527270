import React, { memo } from "react";
import { connect } from "react-redux";
import MarketingHeader from "./Templates/MarketingHeader";
import MarketingFooter from "./Templates/MarketingFooter";

function SuccessContactUs() {
  return (
    <main>
      <MarketingHeader />
      <div className="site-page container d-flex flex-column justify-content-center align-items-center">
        <div className="form-box border-0 shadow-none">
          <div className="w-100 text-center">
            <i className="fas fa-check-circle fa-6x txt-color-primary" />
            <h2 className="mt-5">Successfully sent message!</h2>
          </div>

          <a href="/home" className="btn btn-primary btn-continue w-100 mt-5">
            Home
          </a>
        </div>
      </div>
      <MarketingFooter />
    </main>
  );
}

export default connect(null, null)(memo(SuccessContactUs));
