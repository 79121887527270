import * as httpMethods from '../../Shared/Api';
import * as types from './ActionTypes/ForgotPassTypes';

export const setEmail = (data) => (
  {
    type: types.FORGOT_PASS_EMAIL,
    data,
  }
);

export const errorMessageReturn = (data) => (
  {
    type: types.FORGOT_PASS_ERROR_MESSAGE,
    data,
  }
);

export const setUserId = (data) => (
  {
    type: types.FORGOT_PASS_USER_ID,
    data,
  }
);

const verifySuccessResponse = (dispatch, response) => {
  if (response.data) {
    const { id } = response.data.data;
    dispatch(setUserId(id));
  } else {
    dispatch(setUserId(''));
  }
};

export const verifyForgotPassEmail = (payload) => {
  const forgotPassRequest = (dispatch) => {
    const request = httpMethods
      .Post('api/users/request_forgot_password/', payload)
      .then((response) => {
        verifySuccessResponse(dispatch, response);
        return response.data;
      })
      .catch((response) => response.data);

    return request;
  };

  return forgotPassRequest;
};

export const verifyForgotPassCode = (payload) => {
  const verifyRequest = () => {
    const request = httpMethods
      .Post('api/verifications/verify_code/', payload)
      .then((response) => response)
      .catch((error) => error);

    return request;
  };

  return verifyRequest;
};

export const generateVerificationCode = (payload) => {
  const generateRequest = () => {
    const request = httpMethods
      .Post('api/verifications/create_code/', payload)
      .then((response) => response)
      .catch((response) => response);

    return request;
  };

  return generateRequest;
};
