import * as types from './Actions/ActionTypes/EnableNotificationTypes';

const initState = {
  errorMessage: '',
  statusCode: '',
};

const EnableNotificationReducer = (state = initState, action) => {
  switch (action.type) {
    case types.ENABLE_NOTIFICATIONS_ERROR_MESSAGE: return { ...state, errorMessage: action.data };
    case types.ENABLE_NOTIFICATIONS_STATUS_CODE: return { ...state, statusCode: action.data };
    default: return state;
  }
};

export default EnableNotificationReducer;
