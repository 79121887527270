import React, { memo } from 'react';
import { connect } from 'react-redux';
import PhysTreeHeader from '../Templates/Header';
import PhysTreeFooter from '../Templates/Footer';

function PaymentSuccess() {
  return (
    <main>
      <PhysTreeHeader />
      <div className="site-page container d-flex flex-column justify-content-center align-items-center">
        <div className="form-box border-0 shadow-none">
          <div className="w-100 text-center">
            <i className="fas fa-check-circle fa-6x txt-color-primary" />
            <h2 className="mt-5">
              Payment Successful
            </h2>
          </div>

          <a href="/welcome" className="btn btn-primary btn-continue w-100 mt-5">
            Continue
          </a>
        </div>
      </div>
      <PhysTreeFooter />
    </main>
  );
}

export default connect(
  null,
  null,
)(memo(PaymentSuccess));
