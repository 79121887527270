/* eslint-disable max-len */
import React, { useState, useEffect, memo } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import * as httpMethods from "../../Shared/Api";
// Please refer to the diagrams below for the question ids used in LowBackMovementAlgo.json
// https://app.flowmapp.com/share/ea53cbcdd920d2e1c335cccccb88c761/userflow/134523/
// https://app.flowmapp.com/share/ea53cbcdd920d2e1c335cccccb88c761/userflow/101087/
import LowBackAlgorithmQuestions from "../Questions/LowBackMovementAlgo.json";
import img1 from "../Images/lowback_mvmt_algo_1.jpg";
import img2 from "../Images/lowback_mvmt_algo_2.jpg";
import * as actions from "../../Redux/Actions/LowBackMovementAlgorithmAction";
import { LowBackMovementAlgorithm } from "../../Constants/Surveys";

const questionData = LowBackAlgorithmQuestions.data;

// TODO: Make code easier to understand
function AlgoQuestionTemplate(props) {
  const history = useHistory();
  const [answerSet, setAnswerSet] = useState([]);
  const [retakeNumber, setRetakeNumber] = useState(1);
  const { isPhaseTwo } = useSelector((state) => ({
    isPhaseTwo: state.home.isPhaseTwo,
  }));

  const getNextQuestion = (instanceID, questions) => {
    let nextQuestion;
    let nextSubQuestion;
    if (instanceID === undefined) {
      nextQuestion = questions.find((question) => question.start);
      nextSubQuestion = nextQuestion.instances.find(
        (subQuestion) => subQuestion.subID === "START"
      );
    } else if (instanceID !== "") {
      const categoryID = instanceID.split("-")[0];
      nextQuestion = questions.find((question) => question.id === categoryID);
      nextSubQuestion = nextQuestion.instances.find(
        (subQuestion) => subQuestion.subID === instanceID
      );
    } else {
      // TODO: Show proper results
    }

    return {
      variant: nextQuestion,
      instance: nextSubQuestion,
    };
  };

  const nextQuestion = getNextQuestion([...answerSet].pop(), questionData);
  const questionVariant = nextQuestion.variant;
  const questionInstance = nextQuestion.instance;
  
  const getImgSrc = () => {
    if (questionInstance.imgSrc) {
      if (questionInstance.imgSrc == 'lowback_mvmt_algo_1.jpg') {
        return img1;
      } else if (questionInstance.imgSrc == 'lowback_mvmt_algo_2.jpg'){
        return img2;
      }
    }

    return null;
  };

  const saveAnswer = (payload) => {
    const answer = httpMethods
      .Post("api/surveys/save_answer/", payload)
      .then(() => {
        let path = "/results";
        let category = "Phase1";
        if (isPhaseTwo) {
          path = "/results";
          category = "Phase2WithAlgo";
        }
        history.push({
          pathname: path,
          state: {
            isNewUser: true,
            retake_number: payload.retake_number,
            category: category,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });

    return answer;
  };

  const save = async (result) => {
    const id = await AsyncStorage.getItem("userId");
    const answer = {
      answers: answerSet,
      result,
    };
    const answerType = { result: 10 };
    const payload = {
      user_id: id,
      survey: LowBackMovementAlgorithm,
      retake_number: retakeNumber,
      answer: JSON.stringify(answer),
      answer_type: JSON.stringify(answerType),
    };

    saveAnswer(payload);
  };

  const getRetakeNumber = async () => {
    const id = await AsyncStorage.getItem("userId");

    httpMethods
      .Get(`api/surveys/get_retake_number/?user_id=${id}&category=Phase2`)
      .then((result) => {
        setRetakeNumber(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPhase1RetakeNumber = async () => {
    const id = await AsyncStorage.getItem("userId");

    httpMethods
      .Get(`api/surveys/get_retake_number/?user_id=${id}&category=Phase1`)
      .then((result) => {
        setRetakeNumber(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isPhaseTwo) {
      getRetakeNumber();
    } else {
      getPhase1RetakeNumber();
    }
  }, []);

  return (
    <main className="container  d-flex justify-content-center align-items-center">
      <div className="w-100">
        <div className="card-box mw-100">
          <form>
            <div className="form-row">
              <div className="form-group">
                <h2 className="form-heading-sub">
                  Low Back Movement Algorithm
                </h2>
              </div>
            </div>

            <div className="form-row">
              <div className="col-12">
                <p className="form-title-head">
                  {questionVariant ? questionVariant.question : ""}
                </p>
              </div>

              <div
                className="form-group col-md-6 order-2 order-md-1"
                data-toggle="buttons"
                key={questionInstance ? questionInstance.subID : "END"}
              >
                <label
                  className="btn btn-outline-secondary btn-radio btn-fw-70 mr-1 btn-redirect"
                  data-link="/#"
                >
                  <input
                    type="radio"
                    className="text-dark font-weight-bold d-none"
                    onClick={() => {
                      setAnswerSet((answer) => {
                        const answerArr = [...answer];
                        answerArr.push(questionInstance.posBranch);
                        if (questionInstance.posResult) {
                          props.setResult(questionInstance.posResult);
                          save(questionInstance.posResult);
                        }
                        return answerArr;
                      });
                    }}
                  />
                  {questionInstance?.subID === "Q363-0" ? "Left" : "Yes"}
                </label>

                <label
                  className="btn btn-outline-secondary btn-radio btn-fw-70 mr-1 btn-redirect"
                  data-link="/#"
                >
                  <input
                    type="radio"
                    className="text-dark font-weight-bold d-none"
                    onClick={() => {
                      setAnswerSet((answer) => {
                        const answerArr = [...answer];
                        answerArr.push(questionInstance.negBranch);
                        if (questionInstance.negResult) {
                          props.setResult(questionInstance.negResult);
                          save(questionInstance.negResult);
                        }
                        return answerArr;
                      });
                    }}
                  />
                  {questionInstance?.subID === "Q363-0" ? "Right" : "No"}
                </label>
              </div>

              <div
                className="col-md-6 order-1 order-md-2 mb-md-0 mb-4"
                hidden={questionInstance ? !questionInstance.imgSrc : true}
              >
                {questionInstance && questionInstance.imgSrc ? (
                  <img src={getImgSrc()} alt="" className="img-fluid" />
                ):(<div></div>)}
              </div>
            </div>
          </form>
        </div>
        <a
          className="btn-prev"
          onClick={() => {
            setAnswerSet((answer) => {
              const answerArr = [...answer];
              answerArr.pop();
              return answerArr;
            });
          }}
          hidden={answerSet.length === 0}
        >
          <i className="fas fa-arrow-left mr-2" />
          Previous
        </a>
      </div>
    </main>
  );
}

const mapStateToProps = (state) => ({
  result: state.lowBackMovementAlgorithm.result,
  statusCode: state.lowBackMovementAlgorithm.statusCode,
});

const mapDispatchToProps = (dispatch) => ({
  setResult: (text) => dispatch(actions.setResult(text))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(AlgoQuestionTemplate));
