import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import RegisterReducer from "./RegisterReducer";
import VerifyReducer from "./VerifyReducer";
import VerifyCodeReducer from "./VerifyCodeReducer";
import EnableNotificationReducer from "./EnableNotificationReducer";
import LowerBackStartReducer from "./LowerBackStartReducer";
import ImagingReducer from "./ImagingReducer";
import MedicationReducer from "./MedicationReducer";
import ProcedureReducer from "./ProcedureReducer";
import ProcedureTreatmentReducer from "./ProcedureTreatmentReducer";
import LowBackRiskAssessmentReducer from "./LowBackRiskAssessmentReducer";
import FunctionalAssessmentReducer from "./FunctionalAssessmentReducer";
import ForgotPassReducer from "./ForgotPassReducer";
import LowBackMovementAlgorithmReducer from "./LowBackMovementAlgorithmReducer";
import ResultReducer from "./ResultReducer";
import CustomSolutionReducer from "./CustomSolutionReducer";

/* new reducer */
import HomeReducer from "../new-redux/reducers/HomeReducer";
import LRAReducer from "../new-redux/reducers/LowRiskAssessment";
import NewCustomSolutionReducer from "../new-redux/reducers/NewCustomSolution";
import HistoryReducer from "../new-redux/reducers/History";
import LBSReducer from "../new-redux/reducers/LowerBackStart";
import FAReducer from "../new-redux/reducers/FunctionalAssessment";
import ResultsReducer from "../new-redux/reducers/Results";
import AuthReducer from "../new-redux/reducers/Auth";

const combinedReducers = combineReducers({
  login: LoginReducer,
  register: RegisterReducer,
  verify: VerifyReducer,
  verifyCode: VerifyCodeReducer,
  enableNotification: EnableNotificationReducer,
  lowerBackStart: LowerBackStartReducer,
  imaging: ImagingReducer,
  medication: MedicationReducer,
  procedure: ProcedureReducer,
  procedureTreatment: ProcedureTreatmentReducer,
  lowBackRiskAssessment: LowBackRiskAssessmentReducer,
  functionalAssessment: FunctionalAssessmentReducer,
  forgotPass: ForgotPassReducer,
  lowBackMovementAlgorithm: LowBackMovementAlgorithmReducer,
  resultReducer: ResultReducer,
  customSolution: CustomSolutionReducer,
  /* new */
  home: HomeReducer,
  lra: LRAReducer,
  custom_solution: NewCustomSolutionReducer,
  history_answer: HistoryReducer,
  lbs: LBSReducer,
  fa: FAReducer,
  results: ResultsReducer,
  auth: AuthReducer,
});

export default combinedReducers;
