import React from "react";
import Modal from "react-bootstrap/Modal";

const ExerciseModal = ({ isOpen, onClose, title, link }) => {
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      animation={true}
      size="lg"
      centered
      contentClassName="rounded-20 modal-lg"
    >
      <div className="modal-content rounded-20">
        <div className="modal-body p-5 p-md-4  ">
          <a className="close" data-dismiss="modal" onClick={onClose}>
            <i className="fas fa-times txt-color-dark-grey" />
          </a>
          <a
            style={{ cursor: "pointer" }}
            className="link mt-4 d-block txt-color-dark-grey mb-4"
            data-dismiss="modal"
            onClick={onClose}
          >
            <i className="fas fa-arrow-left mr-2" />
            Back to Program
          </a>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              title="Reduce posterior tilt"
              src={link}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <h2 className="mt-4">{title}</h2>
          <a
            data-dismiss="modal"
            className="btn btn-primary btn-xs "
            onClick={onClose}
          >
            Done
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ExerciseModal;
