import React from "react";
import { useHistory } from "react-router-dom";
import PhysTreeHeader from "../Templates/Header";
import PhysTreeFooter from "../Templates/Footer";
import { useSelector } from "react-redux";

const ExperienceLowBackPain = () => {
  const history = useHistory();

  const { home } = useSelector((state) => ({
    home: state.home,
  }));

  const { isPhaseTwo } = home;

  const toHistory = () => {
    if (isPhaseTwo) {
      history.push("/history");
    } else {
      history.push("/lower-back-start");
    }
  };
  return (
    <div>
      <PhysTreeHeader />
      <main className="container  d-flex justify-content-center align-items-center">
        <div className="w-100">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="card-box mw-100">
                <form>
                  <div className="form-row">
                    <div className="col-12">
                      <h2 className="form-heading">
                        Have you experienced any of the following in regards to
                        your low back pain?
                      </h2>
                    </div>
                  </div>
                  <div className="form-row mb-3">
                    <div className="col-12">
                      <ul>
                        <li>
                          Progressive worsening of symptoms that are constant,
                          especially at night or when you lie down?
                        </li>
                        <li>
                          Pain spreading down one or both legs, especially if
                          the pain extends below your knee of which your
                          physician is unaware?
                        </li>
                        <li>
                          Numbness, tingling, or weakness of which your
                          physician is unaware?
                        </li>
                        <li>Unintended weight loss?</li>
                        <li>New bowel or bladder control problems?</li>
                        <li>A fever associated with your pain?</li>
                        <li>Are you currently pregnant?</li>
                      </ul>
                    </div>
                    <div className="w-100 mt-3">
                      <div className="d-table mx-auto mx-sm-0">
                        <a
                          className="btn btn-outline-secondary btn-radio btn-fw-90 mr-md-3 mr-2"
                          onClick={() => history.push("/follow-up")}
                        >
                          Yes
                        </a>
                        <a
                          className="btn btn-outline-secondary btn-radio btn-fw-90 mr-1"
                          onClick={toHistory}
                        >
                          No
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <PhysTreeFooter />
    </div>
  );
};

export default ExperienceLowBackPain;
