import React from "react";

import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import codeVerifyEmail from "../../Images/code-verify-email.svg";

const ResendCodeModal = ({ onClose, isOpen }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      animation={true}
      centered
      contentClassName="rounded-20"
    >
      <div className="modal-content rounded-20">
        <div className="modal-body p-5   ">
          <a onClick={onClose} className="close">
            <i className="fas fa-times txt-color-dark-grey" />
          </a>
          <div className="row">
            <div className="col-12 d-flex align-items-center mx-0 px-0 justify-content-center">
              <img src={codeVerifyEmail} alt="" />
            </div>
            <div className="col-12 my-4">
              <h4 className="text-center txt-color-dark h3 font-weight-bold">
                Email Verification
              </h4>
              <p className="text-center">We’ve re-sent you a link via email.</p>
            </div>

            <div className="col-12">
              <a
                onClick={onClose}
                className="btn-continue btn-outline-secondary text-decoration-none mx-auto d-table"
                id="alert-btn-close"
              >
                Got it
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ResendCodeModal;
