import React from 'react';
import { useHistory } from 'react-router-dom';
import workoutImg from '../../Images/workout5.png';

function RP27() {
  const history = useHistory();
  return (
    <div className="site-page container-fluid p-0">
      <main className="container  d-flex justify-content-center align-items-center">
        <div className="w-100">
          <div className="row justify-content-center">
            <div className="col-lg-10">

              <div className="card-box mw-100 p-md-5 ">
                <div className="row justify-content-center">
                  <div className="col-md-9">
                    <div className="text-center mx-auto">
                      <img alt="" src={workoutImg} className="img-fluid  mb-4" />
                      <p className="px-md-5">Let&lsquo;s have you go through the algorithm again to see if we can find a better set of exercises for your condition.</p>
                    </div>
                  </div>
                </div>
              </div>
              <a
                className="btn btn-primary btn-continue d-table mx-auto mb-5"
                onClick={() => history.push('/low-back-algorithm')}
              >
                Continue
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default RP27;
