import * as types from './Actions/ActionTypes/ResultTypes';

const initState = {
  statusCdoe: '',
  errorMessage: '',
  data: '',
};

const ResultReducer = (state = initState, action) => {
  switch (action.type) {
    case types.RESULT_ERROR_MESSAGE: return {
      ...state,
      errorMessage: action.data,
    };
    case types.RESULT_STATUS_CODE: return {
      ...state,
      statusCode: action.data,
    };
    case types.RESULT_DATA: return { ...state, data: action.data };
    default: return state;
  }
};

export default ResultReducer;
