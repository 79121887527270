import React, { useState, useEffect } from "react";
import QuestionProgressBar from "../Templates/QuestionProgressBar";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";

const Q4 = () => {
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const { register, setValue, getValues } = useFormContext();

  useEffect(() => {
    register("q4", { required: true });
  }, []);

  const onChange = (e) => {
    setValue("q4", e);
    dispatch({ type: "INCREMENT", payload: getValues() });
  };

  return (
    <div className="site-page container-fluid p-0">
      <QuestionProgressBar category={0} />
      <main className="container  d-flex justify-content-center align-items-center">
        <div className="w-100">
          <div className="card-box mw-100">
            <form>
              <div className="form-row">
                <div className="form-group col-6">
                  <h2 className="form-heading-sub">Low Back Risk Assessment</h2>
                </div>
                <div className="form-group col-6 d-flex justify-content-end align-items-center">
                  <p className="txt-color-dark-grey d-block d-lg-none m-0">
                    Question 4 of 7
                  </p>
                </div>
              </div>

              <div className="form-row">
                <div className="col-12">
                  <p className="txt-color-dark-grey d-none d-lg-block">
                    Question 4 of 7
                  </p>
                </div>

                <div className="col-12">
                  <p className="form-title-head">
                    In your view, how large is the risk that your current pain
                    may become persistent?
                  </p>
                </div>

                <div className="w-100 d-lg-block d-none">
                  <div className="form-group col-12" data-toggle="buttons">
                    <label className="btn btn-outline-secondary btn-radio btn-fw-70 mr-1 btn-redirect">
                      <input
                        type="radio"
                        autoComplete="off"
                        className="text-dark font-weight-bold d-none"
                        value={0}
                        name="q4"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      />
                      0
                    </label>

                    <label className="btn btn-outline-secondary btn-radio btn-fw-70 mr-1 btn-redirect">
                      <input
                        type="radio"
                        autoComplete="off"
                        className="text-dark font-weight-bold d-none"
                        value={1}
                        name="q4"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      />
                      1
                    </label>

                    <label className="btn btn-outline-secondary btn-radio btn-fw-70 mr-1 btn-redirect">
                      <input
                        type="radio"
                        autoComplete="off"
                        className="text-dark font-weight-bold d-none"
                        value={2}
                        name="q4"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      />
                      2
                    </label>

                    <label className="btn btn-outline-secondary btn-radio btn-fw-70 mr-1 btn-redirect">
                      <input
                        type="radio"
                        autoComplete="off"
                        className="text-dark font-weight-bold d-none"
                        value={3}
                        name="q4"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      />
                      3
                    </label>

                    <label className="btn btn-outline-secondary btn-radio btn-fw-70 mr-1 btn-redirect">
                      <input
                        type="radio"
                        autoComplete="off"
                        className="text-dark font-weight-bold d-none"
                        value={4}
                        name="q4"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      />
                      4
                    </label>

                    <label className="btn btn-outline-secondary btn-radio btn-fw-70 mr-1 btn-redirect">
                      <input
                        type="radio"
                        autoComplete="off"
                        className="text-dark font-weight-bold d-none"
                        value={5}
                        name="q4"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      />
                      5
                    </label>

                    <label className="btn btn-outline-secondary btn-radio btn-fw-70 mr-1 btn-redirect">
                      <input
                        type="radio"
                        autoComplete="off"
                        className="text-dark font-weight-bold d-none"
                        value={6}
                        name="q4"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      />
                      6
                    </label>

                    <label className="btn btn-outline-secondary btn-radio btn-fw-70 mr-1 btn-redirect">
                      <input
                        type="radio"
                        autoComplete="off"
                        className="text-dark font-weight-bold d-none"
                        value={7}
                        name="q4"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      />
                      7
                    </label>

                    <label className="btn btn-outline-secondary btn-radio btn-fw-70 mr-1 btn-redirect">
                      <input
                        type="radio"
                        autoComplete="off"
                        className="text-dark font-weight-bold d-none"
                        value={8}
                        name="q4"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      />
                      8
                    </label>

                    <label className="btn btn-outline-secondary btn-radio btn-fw-70 mr-1 btn-redirect">
                      <input
                        type="radio"
                        autoComplete="off"
                        className="text-dark font-weight-bold d-none"
                        value={9}
                        name="q4"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      />
                      9
                    </label>

                    <label className="btn btn-outline-secondary btn-radio btn-fw-70 mr-1 btn-redirect">
                      <input
                        type="radio"
                        autoComplete="off"
                        className="text-dark font-weight-bold d-none"
                        value={10}
                        name="q4"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      />
                      10
                    </label>
                  </div>

                  <div className="form-group col-md-11 col-xl-10 mt-5 py-3 border-bottom">
                    <div className="row">
                      <div className="col-6">
                        <p className="txt-color-primary font-weight-bold text-left m-0">
                          None
                        </p>
                      </div>
                      <div className="col-6">
                        <p className="txt-color-primary font-weight-bold text-right m-0">
                          Extreme
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-100 d-lg-none d-block">
                  <div className="form-group col-12">
                    <div className="w-100">
                      <span className="perform_rate_value">{step}</span>
                    </div>

                    <div className="w-100 d-flex justify-content-center align-items-center">
                      <input
                        type="range"
                        min="0"
                        max="10"
                        defaultValue={0}
                        onChange={(event) => {
                          setStep(event.target.value);
                        }}
                        style={{ width: "90%" }}
                      />
                    </div>
                  </div>

                  <div className="form-group col-12 mt-5 py-3 border-bottom">
                    <div className="col-12 d-flex">
                      <div className="w-50">
                        <p className="txt-color-dark text-md-left text-center p-3">
                          <br />
                          None
                        </p>
                      </div>

                      <div className="w-50 border-left">
                        <p className="txt-color-dark text-md-right text-center p-3">
                          <br />
                          Extreme
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="row">
            <div className="col-auto mr-auto">
              <a
                className="btn-prev btn-xs btn"
                onClick={() => dispatch({ type: "DECREMENT" })}
              >
                <i className="fas fa-arrow-left mr-2" />
                Previous
              </a>
            </div>
            <div className="col-auto">
              <button
                type="button"
                className="btn btn-sm btn-next d-lg-none"
                onClick={() => {
                  setValue("q4", step);
                  dispatch({ type: "INCREMENT", payload: getValues() });
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Q4;
