import React from 'react';
import verifyEmailCode from '../Images/code-verify-email.svg';

function LoginVerifyModal(props) {
  const { setFormTemplate } = props;
  return (
    <div className="modal fade" id="return-options" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content rounded-20">
          <div className="modal-body p-5 p-md-4  ">
            <a className="alert-close" id="alert-btn-close">
              <i
                className="fas fa-times txt-color-secondary"
              />
            </a>
            <div className="row">
              <div className="col-12">
                <center>
                  <img src={verifyEmailCode} alt="" className="" style={{ width: 120 }} />
                  <h3 className="txt-color-dark my-3">
                    Code Verification Sent
                  </h3>

                  <span className="txt-color-dark mb-3 d-table">
                    We’ve sent the verification code through Email.
                  </span>

                  <a
                    onClick={() => {
                      setFormTemplate(2);
                    }}
                    id="alert-btn-close"
                    data-toggle="modal"
                    data-target="#return-options"
                    className="btn btn-outline-secondary font-weight-bold px-5 py-3"
                  >
                    Got it
                  </a>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginVerifyModal;
