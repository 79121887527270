import React from 'react';

function QP22(props) {
  const { setComponentID, subID } = props;
  const questionAnswersVariant = [
    ['QP25-0', 'QP25-0', 'QP25-0'],
    ['QP24-1', 'QP24-2', 'QP24-2'],
  ];
  const optionSet = questionAnswersVariant[subID];
  return (
    <div className="site-page container-fluid p-0">
      <main className="container d-flex justify-content-center align-items-center">
        <div className="w-100">
          <div className="card-box mw-100">
            <form>
              <h4 className="my-md-5 my-4 h4">How often have you been able to complete your exercises?</h4>
              <div className="form-row ">
                <div className="form-group col-12" data-toggle="buttons">
                  <label className="btn btn-outline-secondary btn-radio d-block d-md-inline-block text-center mr-md-1 btn-fw-300">
                    <input
                      type="radio"
                      autoComplete="off"
                      className="text-dark font-weight-bold d-none"
                      onClick={() => {
                        setComponentID(optionSet[0]);
                      }}
                    />
                    less than two times a week
                  </label>
                  <label className="btn btn-outline-secondary btn-radio d-block d-md-inline-block text-center mr-md-1 btn-fw-300">
                    <input
                      type="radio"
                      autoComplete="off"
                      className="text-dark font-weight-bold d-none"
                      onClick={() => {
                        setComponentID(optionSet[1]);
                      }}
                    />
                    three to five times a week
                  </label>
                  <label className="btn btn-outline-secondary btn-radio d-block d-md-inline-block text-center mr-md-1 btn-fw-300">
                    <input
                      type="radio"
                      autoComplete="off"
                      className="text-dark font-weight-bold d-none"
                      onClick={() => {
                        setComponentID(optionSet[2]);
                      }}
                    />
                    greater than five times a week
                  </label>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-12 col-md-4" />
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
}

export default QP22;
