/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { useState, memo } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as httpMethods from "../../Shared/Api";
import * as actions from "../../Redux/Actions/RegisterAction";
import logo from "../Images/logo.svg";

import signUpImg from "../Images/sign-up-img.png";
// import captchaImg from '../Images/captcha.png';
import { Link, useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import VerifyModal from "./NewVerify/VerifyEmail";
import "react-datepicker/dist/react-datepicker.css";

import {
  charSignupCountCheck,
  checkForNumber,
  checkForSpChar,
  ageValidation,
  checkEmail,
} from "../Utility/InputValidation";

function Signup(props) {
  const {
    year,
    email,
    password,
  } = props;
  const [verifyModal, isVerifyModalVisible] = useState(false);
  const [isPassHidden, setIsPassHidden] = useState(true);
  const [isAgreeTerms, setIsAgreeTerms] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [loading, isLoading] = useState(false);
  const history = useHistory();

  const faTimesCircleClass = "far fa-times-circle txt-color-secondary mr-2";
  const faCheckCircleClass = "far fa-check-circle txt-color-primary mr-2";

  const signupClickHandler = (event) => {
    isLoading(true);

    const payload = {
      year: Number(props.year),
      email: props.email,
      password: props.password,
    };

    httpMethods
      .Post("api/users/register/", payload)
      .then((response) => {
        props.setUserId(response.data.data.id);
        isLoading(false);
        isVerifyModalVisible(true);
      })
      .catch(() => {
        // TODO: Replace with status code
        setEmailError("Email already registered with an account.");
        isLoading(false);
        toast.error("Email already registered with an account.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
    event.preventDefault();
  };

  const togglePasswordVisibility = () => {
    setIsPassHidden(!isPassHidden);
  };

  const checkSignupFormValidity = () => {
    if (
      year &&
      ageValidation(year, 18) &&
      email &&
      checkEmail(email) &&
      password &&
      isAgreeTerms &&
      charSignupCountCheck(password, 8) &&
      checkForNumber(password) &&
      checkForSpChar(password)
    ) {
      return true;
    }
    return false;
  };

  return (
    <main>
      <ToastContainer />
      <section className="contents signup">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5 order-2 order-lg-1">
              <img
                src={signUpImg}
                alt="Sign Up!"
                className="signup-img img-fluid p-4"
              />
            </div>
            <div className="signup-container col-lg-5 order-1 order-lg-2">
              <div className="px-4 px-md-5 py-4 radius-20 signup-card mb-5 mb-lg-0">
                <h1 className="heading pb-2">Sign Up Today</h1>
                <h1 className="heading pb-2">It's <span className="font-weight-bold p-signup text-color-1">Free!</span></h1>
                <hr className="signup-hr" />

                <form className="row mb-2">
                  <div className="col input-signup">
                    <label htmlFor="email" className="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id=""
                      placeholder="you@example.com"
                      value={email}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        setEmailError(
                          !checkEmail(inputValue) ? "Invalid email" : ""
                        );
                        props.setEmail(inputValue);
                      }}
                    />
                    <span style={{ fontSize: 14, color: "red" }}>
                      {emailError}
                    </span>
                  </div>
                </form>

                <form className="row mb-2">
                  <div className="col-lg-12 input-signup">
                    <label htmlFor="year" className="form-label">
                      What year were you born?
                    </label>
                  </div>

                  <div className="col">
                  <input
                      className="form-control pr-0 d-block"
                      id="year"
                      name="year"
                      type="number"
                      placeholder="yyyy"
                      value={year}
                      onChange={(event) => {
                        props.setYear(event.target.value);
                      }}
                    />
                  </div>
                </form>

                <form className="row">
                  <div className="col input-signup" id="show_hide_password">
                    <label htmlFor="password" className="form-label">
                      Set Password
                    </label>
                    <input
                      id="password"
                      type={isPassHidden ? "password" : "text"}
                      className="form-control passw"
                      placeholder="password"
                      value={password}
                      onChange={(event) =>
                        props.setPassword(event.target.value)
                      }
                    />
                    <div className="d-table login-password-show signup-password-margin">
                      <input
                        type="checkbox"
                        name=""
                        className="login-password-show-checkbox"
                        onChange={togglePasswordVisibility}
                      />
                      <span
                        className={
                          isPassHidden
                            ? "login-password-show-checkbox-label"
                            : "login-password-show-checkbox-label active"
                        }
                      >
                        Show
                      </span>
                    </div>
                  </div>
                </form>

                <div className="form-group col-12 pt-2">
                  <ul className="list-unstyled">
                    <li>
                      <i
                        className={
                          charSignupCountCheck(password, 8)
                            ? faCheckCircleClass
                            : faTimesCircleClass
                        }
                      />
                      <span className="txt-color-dark">
                        At least 8 characters
                      </span>
                    </li>
                    <li>
                      <i
                        className={
                          checkForSpChar(password)
                            ? faCheckCircleClass
                            : faTimesCircleClass
                        }
                      />
                      <span className="txt-color-dark">
                        One special character
                      </span>
                    </li>
                    <li>
                      <i
                        className={
                          checkForNumber(password)
                            ? faCheckCircleClass
                            : faTimesCircleClass
                        }
                      />
                      <span className="txt-color-dark">One number</span>
                    </li>
                    <li>
                      <i
                        className={
                          ageValidation(year, 18)
                            ? faCheckCircleClass
                            : faTimesCircleClass
                        }
                      />
                      <span className="txt-color-dark">
                        Our platform age requirement is 18 and above
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="col-12">
                  <div className="form-check agree">
                    <label className="terms-checkbox">
                      <input
                        type="checkbox"
                        name=""
                        className="mr-2"
                        checked={isAgreeTerms}
                        onChange={(event) => {
                          setIsAgreeTerms(event.target.checked);
                        }}
                      />
                      <span
                        className="form-check-label text-mute"
                        htmlFor="flexCheckDefault"
                      >
                        I agree to the PhysioTree
                        <Link
                          to="/privacy-policy"
                          className="txt-color-primary"
                        >
                          &nbsp;Privacy Policy
                        </Link>
                        &nbsp;and &nbsp;
                        <Link
                          to="/terms-and-conditions"
                          className="txt-color-primary"
                        >
                          Terms and Conditions
                        </Link>
                        .
                      </span>
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-lg d-block py-3 mb-2 signup-btn"
                      id="alert-btn-open"
                      onClick={signupClickHandler}
                      disabled={!checkSignupFormValidity()}
                    >
                      {loading ? (
                        <Spinner animation="border" role="status" />
                      ) : (
                        <span
                          style={{
                            fontSize: 18,
                            fontWeight: 500,
                            fontFamily: "museo-sans",
                          }}
                        >
                          Sign Me Up
                        </span>
                      )}
                    </button>
                  </div>
                  <div className="w-100 d-flex justify-content-center mt-1">
                    <span
                      className="form-check-label text-mute"
                      htmlFor="flexCheckDefault"
                    >
                      Already have an account?&nbsp;
                    </span>
                    <Link to="/" className="txt-color-primary font-weight-bold">
                      Login Here
                    </Link>
                  </div>
                  <div className="col-12 text-center input-signup mt-2">
                    <p className="text-mute">
                      We do not share any of our users’ personal information
                      with any third parties.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <nav className="navbar fixed-top navbar-expand-md" id="nav">
        <div className="container">
          <a className="header-navbar-brand" href="https://physiotree.com/">
            <img src={logo} alt="logo" title="physiotree logo" />
          </a>
          <button
            id="toggle"
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="line-b" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">
              <li className="nav-item">
                <a className="nav-link btn btn-primary" href="/signup">
                  Get Assessed
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <footer className="pt-4 sign-up-footer">
        <div className="container">
          <div className="row align-items-center py-4">
            <div className="col">
              <a href="https://physiotree.com/">
                <img src={logo} alt="logo" />
              </a>
            </div>
            <div className="col text-end foot-social">
              <a
                href="https://www.facebook.com/physiotree"
                target="_blank"
                rel="noreferrer"
                className="facebook"
              >
                <i
                  aria-label="facebook"
                  className="fab fa-facebook-square fa-2x"
                />
              </a>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col text-center text-lg-start">
              <ul className="list-inline foot-links py-4"></ul>
            </div>
          </div>
        </div>
        <div className="copyright py-3">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center text-lg-start">
                © 2021 PhysioTree LLC. All rights reserved.
              </div>
              {/*   <div className="col-md-6 text-center text-lg-end">
                <a href="#">Terms and Privacy Policy</a>
              </div> */}
            </div>
          </div>
        </div>
      </footer>

      <VerifyModal isOpen={verifyModal} />
    </main>
  );
}

const mapStateToProps = (state) => ({
  year: state.register.year,
  email: state.register.email,
  password: state.register.password,
  userId: state.register.userId,
  errorMessage: state.register.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  setYear: (text) => dispatch(actions.setYear(text)),
  setEmail: (text) => dispatch(actions.setEmail(text)),
  setUserId: (text) => dispatch(actions.setUserId(text)),
  setPassword: (text) => dispatch(actions.setPassword(text)),
});

Signup.propTypes = {
  year: PropTypes.number,
  email: PropTypes.string,
  password: PropTypes.string,
  setYear: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setUserId: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
};

Signup.defaultProps = {
  year: 0,
  email: "",
  password: "",
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Signup));
