import * as httpMethods from '../../Shared/Api';
import * as types from './ActionTypes/LowBackMovementAlgorithmTypes';

export const setResult = (data) => (
  {
    type: types.LOW_BACK_MOVEMENT_ALGORITHM_RESULT,
    data,
  }
);

export const setStatusCode = (data) => (
  {
    type: types.LOW_BACK_MOVEMENT_ALGORITHM_STATUS_CODE,
    data,
  }
);

export const errorMessageReturn = (data) => (
  {
    type: types.LOW_BACK_MOVEMENT_ALGORITHM_ERROR_MESSAGE,
    data,
  }
);

const saveAnswerSuccessResponse = (dispatch, response) => {
  dispatch(errorMessageReturn(null));
  if (response.data) {
    dispatch(setStatusCode(response.data.status));
  } else {
    dispatch(setStatusCode(''));
  }
};

const saveAnswerErrorResponse = (dispatch, error) => {
  dispatch(errorMessageReturn(error.message));
};

export const saveAnswer = (payload) => {
  const saveAnswerRequest = (dispatch) => {
    const answer = httpMethods
      .Post('api/surveys/save_answer/', payload)
      .then((response) => {
        saveAnswerSuccessResponse(dispatch, response);
      })
      .catch((error) => {
        saveAnswerErrorResponse(dispatch, error);
      });

    return answer;
  };

  return saveAnswerRequest;
};
