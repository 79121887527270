import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import MedicationsModal from "../modals/MedicationsModal";

const Medications = () => {
  const dispatch = useDispatch();
  const [medicationModal, isMedicationModalVisible] = useState(false);

  const { isPhaseTwo, history_answer } = useSelector((state) => ({
    isPhaseTwo: state.home.isPhaseTwo,
    history_answer: state.history_answer,
  }));
  const { historyAnswer } = history_answer;
  const { setValue, getValues } = useFormContext();

  const continueHandler = (item) => {
    const finalMedQ2 = item.reduce(
      (acc, cur) => ({ ...acc, [cur.name]: true }),
      {}
    );
    const payload = {
      ...historyAnswer,
      medQ1: getValues("medQ1"),
      medQ2: finalMedQ2,
    };
    dispatch({ type: "INCREMENT_HISTORY_STEP", payload: payload });
  };

  const noHandler = () => {
    setValue("medQ1", false);
    const payload = {
      ...historyAnswer,
      medQ1: getValues("medQ1"),
      medQ2: {},
    };
    dispatch({ type: "INCREMENT_HISTORY_STEP", payload: payload });
  };

  return (
    <main className="container d-flex justify-content-center align-items-center">
      <div className="w-100">
        <div className="card-box mw-100">
          <form>
            <div className="form-group col-6">
              <h2 className="form-heading-sub">History</h2>
            </div>
            <h4 className="my-md-5 my-4 h4 col-md-10">
              {isPhaseTwo
                ? "Since you’ve been using this PhysioTree tool, have you taken any medication for this condition?"
                : "Have you taken any medication for this condition?"}
            </h4>
            <div className="form-row ">
              <div className="form-group col-12" data-toggle="buttons">
                <label className="btn btn-outline-secondary btn-radio btn-fw-90 btn-redirect mr-1">
                  <input
                    type="radio"
                    className="text-dark font-weight-bold d-none"
                    onClick={noHandler}
                  />
                  No
                </label>
                <label
                  className="btn btn-outline-secondary btn-radio btn-fw-90 btn-redirect mr-1"
                  data-toggle="modal"
                  data-target="#medication"
                >
                  <input
                    type="radio"
                    className="text-dark font-weight-bold d-none"
                    onClick={() => {
                      setValue("medQ1", true);
                      isMedicationModalVisible(true);
                    }}
                  />
                  Yes
                </label>
              </div>
            </div>
          </form>
          <MedicationsModal
            isOpen={medicationModal}
            onClose={() => isMedicationModalVisible(false)}
            onContinueHandler={(item) => continueHandler(item)}
          />
        </div>
        <a
          className="btn-prev"
          onClick={() => dispatch({ type: "DECREMENT_HISTORY_STEP" })}
        >
          <i className="fas fa-arrow-left mr-2" />
          Previous
        </a>
      </div>
    </main>
  );
};

export default Medications;
