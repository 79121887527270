import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PhysTreeHeader from "../Templates/Header";
import PhysTreeFooter from "../Templates/Footer";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ctaImg from "../Images/cta_img.png";
import { PhaseTwo } from "../../Constants/Surveys";
import * as api from "../../api/HistoryApi";
import moment from "moment";

import { charDateCheck } from "../Utility/InputValidation";
import { setFormattedDOB } from "../Utility/InputFormatter";

const LowerBackStart = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [date, setDate] = useState("");
  const [dateError, setDateError] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
  });
  const onSubmit = (data) => {
    const { lbsQ2, lbsQ3 } = data;
    const payload = {
      lbsQ1: moment(date).format("YYYY-MM-DD"),
      lbsQ2: lbsQ2,
      lbsQ3: lbsQ3,
    };

    if (date === "") {
      setDateError(true);
    } else if (!dateError) {
      console.log(payload);
      dispatch({ type: "UPDATE_LBS_ANSWER", payload: payload });
      history.push("/history");
    }
  };

  const viewButtonHandler = async () => {
    const id = await AsyncStorage.getItem("userId");
    const getRetakeNumberResponse = await api.getPTRetakeNumber(id);

    const answerType = { result: 10 };
    const answer = {
      result: "See a Physical Therapist",
    };
    const payload = {
      user_id: id,
      survey: PhaseTwo,
      retake_number: getRetakeNumberResponse.data.data ,
      answer: JSON.stringify(answer),
      answer_type: JSON.stringify(answerType),
    };
    let response = await api.saveHistoryAnswer(payload);
    if (response.status === 200) {
      history.push({
        pathname: "/results",
        state: {
          category: "PT",
          retake_number: payload.retake_number,
        },
      });
    }
  };

  return (
    <div>
      <PhysTreeHeader />

      <main className="site-page container d-flex justify-content-center align-items-center">
        <div className="card-box">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-group">
                <div className="form-label text-uppercase">
                  Pain & Dysfunction
                </div>
                <h2 className="form-heading">Lower Back</h2>
              </div>
            </div>
            <hr className="divider" />
            <div className="form-row">
              <div className="col-12">
                <p className="form-title">
                  When did your problem begin?
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip">
                        Select the approximate date your symptoms began.
                      </Tooltip>
                    }
                  >
                    <a style={{ cursor: "pointer" }}>
                      <i className="fas fa-info-circle fa-xs txt-color-secondary ml-2" />
                    </a>
                  </OverlayTrigger>
                </p>
              </div>
              <div className="form-group col-12 col-md-4">
                <label htmlFor="date" className="form-label">
                  Date
                </label>

                <input
                  className="form-control"
                  placeholder="mm/dd/yyyy"
                  maxLength={10}
                  value={date}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const dobFormat = setFormattedDOB(inputValue);
                    setDateError(!charDateCheck(inputValue, 10) ? true : false);
                    setDate(dobFormat);
                  }}
                />
                {dateError && (
                  <span style={{ fontSize: 14, color: "red" }}>
                    {"Requires a valid date"}
                  </span>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="col-12">
                <p className="form-title">
                  How many episodes of low back pain have you had
                  <br />
                  over the last 10 years?
                </p>
              </div>
              <div className="form-group col-12 col-md-4 col-lg-2">
                <label htmlFor="year-select" className="form-label">
                  Select
                </label>
                <select
                  id="year-select"
                  className="form-control"
                  {...register("lbsQ2")}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value=">5">{">"}5</option>
                  <option value=">10">
                    {">"}
                    10
                  </option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-12">
                <p className="form-title">
                  Is your pain the direct result of an accident or a fall?
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip">
                        For example, falling from a standing position (or
                        higher), a motor vehicle accident, or heavy blunt trauma
                        to your low back
                      </Tooltip>
                    }
                  >
                    <a style={{ cursor: "pointer" }}>
                      <i className="fas fa-info-circle fa-xs txt-color-secondary ml-2" />
                    </a>
                  </OverlayTrigger>
                </p>
              </div>
              <div className="form-group col-12 col-md-4" data-toggle="buttons">
                <label
                  htmlFor="accident-pain-yes"
                  className="btn btn-outline-secondary btn-radio  mr-2"
                  data-toggle="modal"
                  data-target="#follow-up"
                >
                  <input
                    id="accident-pain-yes"
                    type="radio"
                    value={true}
                    {...register("lbsQ3")}
                    className="text-dark font-weight-bold d-none"
                  />
                  Yes
                </label>
                <label className="btn btn-outline-secondary btn-radio mr-2">
                  <input
                    type="radio"
                    value={false}
                    {...register("lbsQ3")}
                    className="text-dark font-weight-bold d-none"
                  />
                  No
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-12 col-md-4">
                <button type="submit" className="btn btn-success btn-continue">
                  Continue{" "}
                </button>
              </div>
            </div>
          </form>
        </div>
      </main>
      <PhysTreeFooter />

      <div
        className="modal fade"
        id="follow-up"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered  ">
          <div className="modal-content rounded-20">
            <div className="modal-body p-4">
              <a className="close" data-dismiss="modal">
                <i
                  style={{ cursor: "pointer" }}
                  className="fa fa-times txt-color-dark-grey"
                />
              </a>

              <div className="row">
                <div className="col-12">
                  <center>
                    <img
                      src={ctaImg}
                      alt=""
                      className=""
                      style={{ width: 150 }}
                    />
                    <h5 class="txt-color-dark mt-4 mb-2">
                      Based on your response, we recommend that you follow up with a healthcare provider.
                    </h5>
                    <small class="mb-3 d-table">
                      (e.g. Your primary care provider, a physical therapist, etc.)
                    </small>
                    <a
                      id="alert-btn-close"
                      className="w-100 btn btn-outline-secondary font-weight-bold py-3"
                      data-dismiss="modal"
                      onClick={() => viewButtonHandler()}
                    >
                      I will follow up with a healthcare provider.
                    </a>
                    <a
                      id="alert-btn-close"
                      className="w-100 btn btn-outline-secondary font-weight-bold py-3 mt-3"
                      data-dismiss="modal"
                    >
                      I have been cleared by a healthcare provider to begin an exercise program.
                    </a>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LowerBackStart;
