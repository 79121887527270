import React, { useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useHistory } from "react-router-dom";
import { LowBackRiskAssessment } from "../../Constants/Surveys";
import QuestionProgressBar from "../Templates/QuestionProgressBar";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as api from "../../api/HomeApi";
import * as historyApi from "../../api/HistoryApi";

const Q7 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, setValue, getValues } = useFormContext();

  useEffect(() => {
    register("q7", { required: true });
  }, []);

  const onChange = async (e) => {
    setValue("q7", e);
    const answers = getValues();
    const id = await AsyncStorage.getItem("userId");
    const getRetakeNumberResponse = await historyApi.getPhase1RetakeNumber(id);
    const answerType = {
      q1: 5, // Make constants file  rating none, very mild, mild, moderate, severe, very severe = 5
      q2: 3, // boolean = 3
      q3: 6, // range 0-10
      q4: 6,
      q5: 7, // rating strongly disagree, disagree, agree, strongly agree
      q6: 3,
      q7: 7,
    };
    const payload = {
      user_id: id,
      survey: LowBackRiskAssessment,
      retake_number: getRetakeNumberResponse.data.data,
      answer: JSON.stringify(answers),
      answer_type: JSON.stringify(answerType),
    };

    let response = await api.saveAnswers(payload);
    if (response.status === 200) {
      history.push("/functional-assessment");
    }
  };

  return (
    <div className="site-page container-fluid p-0">
      <QuestionProgressBar category={0} />
      <main className="container  d-flex justify-content-center align-items-center">
        <div className="w-100">
          <div className="card-box mw-100">
            <form>
              <div className="form-row">
                <div className="form-group">
                  <h2 className="form-heading-sub">Low Back Risk Assessment</h2>
                </div>
                <div className="form-group col-6 d-flex justify-content-end align-items-center">
                  <p className="txt-color-dark-grey d-block d-lg-none m-0">
                    Question 7 of 7
                  </p>
                </div>
              </div>

              <div className="form-row">
                <div className="col-12">
                  <p className="txt-color-dark-grey d-none d-lg-block">
                    Question 7 of 7
                  </p>
                  <p className="form-title-head">
                    I have a strong social support system (family, friends,
                    colleagues, etc.).
                  </p>
                </div>
                <div className="w-100   text-center text-sm-left btn-group-line">
                  <label className="btn btn-sm btn-outline-secondary btn-radio btn-md mr-1 btn-redirect">
                    <input
                      type="radio"
                      className="text-dark font-weight-bold d-none"
                      value={3}
                      name="q7"
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                    Strongly Disagree
                  </label>

                  <label className="btn btn-sm btn-outline-secondary btn-radio btn-md mr-1 btn-redirect">
                    <input
                      type="radio"
                      className="text-dark font-weight-bold d-none"
                      value={2}
                      name="q7"
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                    Disagree
                  </label>

                  <label className="btn btn-sm btn-outline-secondary btn-radio btn-md mr-1 btn-redirect">
                    <input
                      type="radio"
                      className="text-dark font-weight-bold d-none"
                      value={1}
                      name="q7"
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                    Agree
                  </label>

                  <label className="btn btn-sm btn-outline-secondary btn-radio btn-md mr-1 btn-redirect">
                    <input
                      type="radio"
                      className="text-dark font-weight-bold d-none"
                      value={0}
                      name="q7"
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                    Strongly Agree
                  </label>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-12 col-md-4" />
              </div>
            </form>
          </div>

          <a
            className="btn-prev btn-xs btn"
            onClick={() => dispatch({ type: "DECREMENT" })}
          >
            <i className="fas fa-arrow-left mr-2" />
            Previous
          </a>
        </div>
      </main>
    </div>
  );
};

export default Q7;
