/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PhysTreeHeader from '../Templates/Header';
import PhysTreeFooter from '../Templates/Footer';
import InitialEvaluate from './Evaluation/InitialEvaluate';

// Question components
import QP21 from './Evaluation/QP21';
import QP22 from './Evaluation/QP22';
import QP25 from './Evaluation/QP25';
import QP24 from './Evaluation/QP24';

// Result components
// TODO: Improve naming
import RP21 from './Evaluation/RP21';
import RP22 from './Evaluation/RP22';
import RP27 from './Evaluation/RP27';
import RP26 from './Evaluation/RP26';
import RP28 from './Evaluation/IncreaseFrequencyResult';
import RP29 from './Evaluation/IncreaseFrequencyTwoResult';

function TreatmentEvaluation() {
  const [componentID, setComponentID] = useState('START');
  let contentBlock;

  switch (componentID) {
    // QUESTIONS
    case 'START':
      contentBlock = <InitialEvaluate {...{ setComponentID }} />;
      break;
    case 'QP21-0':
      contentBlock = <QP21 {...{ setComponentID, subID: 0 }} />;
      break;
    case 'QP22-0':
      contentBlock = <QP22 {...{ setComponentID, subID: 0 }} />;
      break;
    case 'QP22-1':
      contentBlock = <QP22 {...{ setComponentID, subID: 1 }} />;
      break;
    case 'QP24-0':
      contentBlock = <QP24 {...{ setComponentID, subID: 0 }} />;
      break;
    case 'QP24-1':
      contentBlock = <QP24 {...{ setComponentID, subID: 1 }} />;
      break;
    case 'QP24-2':
      contentBlock = <QP24 {...{ setComponentID, subID: 2 }} />;
      break;
    case 'QP25-0':
      contentBlock = <QP25 {...{ setComponentID, subID: 0 }} />;
      break;
    case 'QP25-1':
      contentBlock = <QP25 {...{ setComponentID, subID: 1 }} />;
      break;

    // RESULTS
    case 'RP21-0':
      contentBlock = <RP21 {...{ setComponentID, subID: 0 }} />;
      break;
    case 'RP21-1':
      contentBlock = <RP21 {...{ setComponentID, subID: 1 }} />;
      break;
    case 'RP22-0':
      contentBlock = <RP22 {...{ setComponentID, subID: 0 }} />;
      break;
    case 'RP27-0':
      contentBlock = <RP27 {...{ setComponentID, subID: 0 }} />;
      break;
    case 'RP26-0':
      contentBlock = <RP26 {...{ setComponentID, subID: 0 }} />;
      break;
    case 'RP26-1':
      contentBlock = <RP26 {...{ setComponentID, subID: 1 }} />;
      break;
    case 'RP28-0':
      contentBlock = <RP28 {...{ setComponentID, subID: 0 }} />;
      break;
    case 'RP29-0':
      contentBlock = <RP29 {...{ setComponentID, subID: 0 }} />;
      break;

    default:
      contentBlock = <div {...{ setComponentID }} />;
      break;
  }
  return (
    <div>
      <PhysTreeHeader />
      {contentBlock}
      <PhysTreeFooter />
    </div>
  );
}

export default TreatmentEvaluation;
