import React from "react";

import ReactECharts from "echarts-for-react";
import ctaImg from "../../Images/custom_solution_wizard.svg";
import { useSelector } from "react-redux";
import moment from "moment";
import Tooltip from "./Tooltip";

const Phase2Results = ({ onClick, modal }) => {
  const { results } = useSelector((state) => ({
    results: state.results,
  }));

  const { funcAssessmentList, title, resultsDetails, data } = results;
  const phaseOneChartConfig = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      top: "7%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: [...title],
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        max: 100,
      },
    ],
    series: [
      {
        name: "Your Functional Limitation: ",
        type: "bar",
        color: ["rgb(0, 148, 68)"],
        barWidth: "25%",
        data: [...funcAssessmentList],
      },
    ],
  };

  let CR =
    "Amazing job and well done! Great to see you're feeling better and enjoying your normal activities. Evidence suggests that a healthy lifestyle which includes regular exercise is the most powerful step you can take to prevent future episodes.";
  let SPT =
    "It seems like you're not improving at the rate we'd expect. We recommend that you follow up with a physical therapist for a complete evaluation to address your low back pain.";
  let FPT =
    "Based on your response, we recommend that you follow up with a healthcare provider. (e.g. Your primary care provider, a physical therapist, etc.)";

  return (
    <main className="container my-5">
      <div className="w-100  mw-100 border rounded-20 p-5 h-100 card-box mx-auto bg-color-dark">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <h3 className="h3 text-white mb-4">Results</h3>

            {resultsDetails?.exercise_program === "Completely Recovered" ||
            resultsDetails?.exercise_program === "See a Physical Therapist" ||
            resultsDetails?.action_plan ===
              "Follow up with a healthcare provider." ? (
              <>
                <h4>
                  <span className="txt-color-primary font-weight-bold">
                    Results Summary:
                  </span>
                  
                  {resultsDetails?.exercise_program === "See a Physical Therapist" && 
                    <a onClick={modal}>
                      <i className="fas fa-info-circle fa-xs txt-color-secondary ml-2" />
                    </a>
                  }
                  {resultsDetails?.action_plan === "Follow up with a healthcare provider." && 
                  <a onClick={modal}>
                    <i className="fas fa-info-circle fa-xs txt-color-secondary ml-2" />
                  </a>
                  }
                  {resultsDetails?.exercise_program === "Completely Recovered" &&
                  <Tooltip text={CR}/>}
                </h4>
                <div className="row mb-0">
                  <div className="col-sm-4 col-5 mb-1">
                    <span className="txt-color-primary font-weight-bold">
                      Action Plan:
                    </span>
                  </div>
                  <div className="col-sm-8 col-6 mb-1">
                    {resultsDetails?.action_plan ===
                    "Follow up with a healthcare provider." ? (
                      <span className="txt-color-white">
                        {resultsDetails?.action_plan}
                      </span>
                    ) : (
                      <span className="txt-color-white">
                        {resultsDetails?.exercise_program}
                      </span>
                    )}
                  </div>
                </div>
                <dl className="row">
                  <dt className="col-sm-4 col-5 mb-1">
                    <span className="txt-md txt-color-white">Date:</span>
                  </dt>
                  <dd className="col-sm-8 col-6 mb-1">
                    <span className="txt-md txt-color-white">
                      {moment(data?.created_date).format("MM/DD/YYYY hh:mm A")}
                    </span>
                  </dd>
                </dl>
              </>
            ) : (
              <>
                <div className="row mb-0">
                  <div className="col-sm-4 col-5 mb-1">
                    <span className="txt-color-primary font-weight-bold">
                      Action Plan:
                    </span>
                  </div>
                  <div className="col-sm-8 col-6 mb-1">
                    <span className="txt-color-white">
                      Guided Self Management
                    </span>
                  </div>
                </div>
                <dl className="row mb-0">
                  <dt className="col-sm-4 col-5 mb-1">
                    <span className="txt-md txt-color-white">
                      Education Program:
                    </span>
                  </dt>
                  <dd className="col-sm-8 col-6 mb-1">
                    <span className="txt-md txt-color-white">
                      {resultsDetails?.education_program}
                    </span>
                  </dd>
                </dl>
                <dl className="row mb-0">
                  <dt className="col-sm-4 col-5 mb-1">
                    <span className="txt-md txt-color-white">
                      Excercise Program:
                    </span>
                  </dt>
                  <dd className="col-sm-8 col-6 mb-1">
                    <span className="txt-md txt-color-white">
                      {resultsDetails?.exercise_program}
                    </span>
                  </dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4 col-5 mb-1">
                    <span className="txt-md txt-color-white">Date:</span>
                  </dt>
                  <dd className="col-sm-8 col-6 mb-1">
                    <span className="txt-md txt-color-white">
                      {moment(data?.created_date).format("MM/DD/YYYY hh:mm A")}
                    </span>
                  </dd>
                </dl>
              </>
            )}
          </div>

          {resultsDetails?.action_plan !==
            "Follow up with a healthcare provider." && (
            <div className="col-lg-6 col-md-12">
              <div className="card-group">
                <div className="card p-4">
                  <h6>Functional Assessment</h6>
                  <div
                    id="bar-chart"
                    style={{ height: 200, width: 200, maxWidth: 200 }}
                  >
                    <ReactECharts
                      option={phaseOneChartConfig}
                      style={{ height: 200 }}
                    />
                  </div>
                  <small className="text-center">
                    Percentage of Full Function
                  </small>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {resultsDetails?.exercise_program === "Completely Recovered" ||
      resultsDetails?.exercise_program === "See a Physical Therapist" ||
      resultsDetails?.action_plan ===
        "Follow up with a healthcare provider." ? null : (
        <div className="bg-color-gradient-1 p-5 rounded-20">
          <div className="row justify-content-center align-items-center w-75 mx-auto">
            <div className="col-12 ">
              <h2 className="mb-5 text-center">Your Custom Solution</h2>
              <img className="mx-auto d-table" alt="" src={ctaImg} />
              <div className="w-100 text-center">
                <a
                  onClick={onClick}
                  style={{ cursor: "pointer" }}
                  className="btn btn-primary btn-continue  mt-5 mx-auto text-center d-inline-block"
                >
                  Continue
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default Phase2Results;
