/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as httpMethods from '../../Shared/Api';
import {
  setFormattedPhoneNumber,
  setFormattedZipCode,
} from '../Utility/InputFormatter';

import {
  charCountCheck,
  ageValidation,
  checkEmail,
} from '../Utility/InputValidation';

function ProfileSettings(props) {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [gender, setGender] = useState();
  const [year, setYear] = useState();
  const [email, setEmail] = useState();
  const [phoneNum, setPhoneNum] = useState();
  const [zipCode, setZipCode] = useState();

  const { userId } = props;

  useEffect(() => {
    httpMethods
      .Get(`api/users/get_user_profile/?user_id=${userId}`)
      .then((response) => {
        const responseData = response.data.data;
        if (responseData.first_name) {
          setFirstName(responseData.first_name);
        }
        if (responseData.last_name) {
          setLastName(responseData.last_name);
        }
        if (responseData.gender) {
          setGender(responseData.gender);
        }
        if (responseData.phone_number) {
          setPhoneNum(setFormattedPhoneNumber(responseData.phone_number));
        }
        if (responseData.zip_code) {
          setZipCode(responseData.zip_code);
        }
        setEmail(responseData.email);
        setYear(responseData.year);

        if (response.status !== 200) {
          toast.error('Failed to load profile defaults.', {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      })
  }, []);

  const submitChangesHandler = () => {
    let formattedPhone = '';
    if (phoneNum){
      formattedPhone = phoneNum.replace(/\D/g, '');
    }
    const payload = {
      first_name: firstName,
      last_name: lastName,
      gender,
      year,
      email,
      phone_number: formattedPhone,
      zip_code: zipCode,
    };

    // TODO: Consider case for already existing email with error handlers
    // TODO: Consider other validations that are also present when registering
    httpMethods
      .Post('api/users/change_profile_from_settings/', payload)
      .then(() => {
        toast.success('Changes have been successfully applied.', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch(() => {
        toast.error('An error occured. Please try again.', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  const checkSignupFormValidity = () => {
    if (year
      && ageValidation(year, 18)
      && email
      && checkEmail(email)
      && charCountCheck(phoneNum, 14)
      && charCountCheck(zipCode, 5)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="col-12 col-md-9 px-3 px-md-5">
      <form action="" className="form-box-fluid shadow-none">
        <div className="form-group">
          <h3 className="form-heading mb-5">Profile</h3>
        </div>

        <div className="form-row mb-4">
          <div className="form-group col-12 col-12 col-md-6 mb-3">
            <label htmlFor="settings-first-name" className="form-label">First Name</label>
            <input
              id="settings-first-name"
              type="text"
              className="form-control"
              placeholder="Firstname"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
          </div>

          <div className="form-group col-12 col-md-6">
            <label htmlFor="settings-last-name" className="form-label">Last Name</label>
            <input
              id="settings-last-name"
              type="text"
              className="form-control"
              placeholder="Lastname"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
          </div>
        </div>

        <div className="form-row mb-4">
          <div className="form-group col-10">
            <div className="w-100">
              <div htmlFor="" className="form-label">Gender</div>
            </div>
          </div>

          <div className="custom-control custom-radio col-md-4">
            <input
              type="radio"
              className=""
              id="male"
              name="defaultExampleRadios"
              checked={gender === 'M'}
              onChange={() => setGender('M')}
            />
            <label className="custom-control-label" htmlFor="male">Male</label>
          </div>

          <div className="custom-control custom-radio col-lg-4">
            <input
              type="radio"
              className=""
              id="female"
              name="defaultExampleRadios"
              checked={gender === 'F'}
              onChange={() => setGender('F')}
            />
            <label className="custom-control-label" htmlFor="female">Female</label>
          </div>

          <div className="custom-control custom-radio col-lg-4">
            <input
              type="radio"
              className=""
              id="non-binary"
              name="defaultExampleRadios"
              checked={gender === 'N'}
              onChange={() => setGender('N')}
            />
            <label className="custom-control-label" htmlFor="non-binary">Non-binary</label>
          </div>
        </div>

        <div className="form-row mb-4">
          <div className="form-group col-12 col-md-10">
            <label htmlFor="settings-dob" className="form-label">D.O.B</label>
            <input
              id="settings-dob"
              type="number"
              className="form-control"
              placeholder="yyyy"
              value={year}
              onChange={(event) => {
                setYear(event.target.value);
              }}
            />
          </div>
        </div>

        <div className="form-row mb-4">
          <div className="form-group col-12 col-md-10">
            <label htmlFor="seetings-email" className="form-label">Email Address</label>
            <input
              id="settings-email"
              type="email"
              name=""
              className="form-control"
              placeholder="your@example.com"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
        </div>

        <div className="form-row mb-4">
          <div className="form-group col-12 col-md-10">
            <label htmlFor="settings-phone" className="form-label">Phone Number</label>
            <input
              id="settings-phone"
              type="text"
              name=""
              className="form-control"
              placeholder="+(xxx)xxx-xxxx"
              value={phoneNum}
              onChange={(event) => {
                const phoneNumFormat = setFormattedPhoneNumber(event.target.value);
                setPhoneNum(phoneNumFormat);
              }}
            />
          </div>
        </div>

        <div className="form-row mb-4">
          <div className="form-group col-12 col-md-10">
            <label htmlFor="settings-zip" className="form-label">Zip Code</label>
            <input
              id="settings-zip"
              type="text"
              name=""
              maxLength={5}
              className="form-control"
              placeholder="Type your zip code"
              value={zipCode}
              onChange={(event) => setFormattedZipCode(event.target.value, setZipCode)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-12 col-md-4">
            <button
              type="button"
              className="sign-up-btn btn btn-success btn-continue text-decoration-none"
              disabled={!checkSignupFormValidity()}
              onClick={submitChangesHandler}
            >
              Save Changes
            </button>
          </div>
        </div>

      </form>
    </div>
  );
}

export default ProfileSettings;
