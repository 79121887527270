import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import HistoryTreatmentModal from "../modals/HistoryTreatment";

const HistoryTreatment = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [historyTreatmentModal, isHistoryTreatmentModalVisible] =
    useState(false);
  const { isPhaseTwo, history_answer, lbs } = useSelector((state) => ({
    isPhaseTwo: state.home.isPhaseTwo,
    history_answer: state.history_answer,
    lbs: state.lbs.answer,
  }));

  const { historyAnswer } = history_answer;
  const { setValue, getValues } = useFormContext();

  const continueHandler = (item) => {
    const finalprcTrtQ2 = item.reduce(
      (acc, cur) => ({ ...acc, [cur.name]: true }),
      {}
    );
    const payload = {
      ...lbs,
      ...historyAnswer,
      prcTrtQ1: getValues("prcTrtQ1"),
      prcTrtQ2: finalprcTrtQ2,
    };

    dispatch({ type: "UPDATE_HISTORY_ANSWER", payload: payload });
  };

  const noHandler = () => {
    setValue("prcTrtQ1", false);
    const payload = {
      ...lbs,
      ...historyAnswer,
      prcTrtQ1: getValues("prcTrtQ1"),
      prcTrtQ2: {},
    };

    dispatch({ type: "UPDATE_HISTORY_ANSWER", payload: payload });
  };
  return (
    <main className="container d-flex justify-content-center align-items-center">
      <div className="w-100">
        <div className="card-box mw-100">
          <div className="form-group col-6">
            <h2 className="form-heading-sub">History</h2>
          </div>
          <h4 className="my-md-5 my-4 h4 col-md-10">
            {isPhaseTwo
              ? "Since you have been using this tool, have you had treatments such as (eg Physical Therapy, chiropractic care) for this condition?"
              : "Have you had previous treatment (e.g. Physical Therapy, Chiropractic) for this condition?"}
          </h4>

          <div className="form-row ">
            <div className="form-group col-12" data-toggle="buttons">
              <label className="btn btn-outline-secondary btn-radio btn-fw-90 btn-redirect mr-1">
                <input
                  type="radio"
                  className="text-dark font-weight-bold d-none"
                  onClick={noHandler}
                />
                No
              </label>
              <label className="btn btn-outline-secondary btn-radio btn-fw-90 btn-redirect mr-1">
                <input
                  type="radio"
                  autoComplete="off"
                  className="text-dark font-weight-bold d-none"
                  onClick={() => {
                    setValue("prcTrtQ1", true);
                    isHistoryTreatmentModalVisible(true);
                  }}
                />
                Yes
              </label>
            </div>
          </div>
        </div>
        <a
          className="btn-prev"
          onClick={() => dispatch({ type: "DECREMENT_HISTORY_STEP" })}
        >
          <i className="fas fa-arrow-left mr-2" />
          Previous
        </a>
        <HistoryTreatmentModal
          isOpen={historyTreatmentModal}
          onClose={() => isHistoryTreatmentModalVisible(false)}
          onContinueHandler={(item) => continueHandler(item)}
        />
      </div>
    </main>
  );
};

export default HistoryTreatment;
