import React, { useState, useEffect } from "react";
import PhysTreeFooter from "../../Templates/Footer";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as httpMethods from "../../../Shared/Api";

/* images */
import codeVerifyPhone from "../../Images/code-verify-phone.svg";

/* modal */
import ResendCodeModal from "./ResendCodeModal";
/* redux */
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../Redux/Actions/VerifyAction";
import * as verifyActions from "../../../Redux/Actions/VerifyCodeAction";

import { charCountCheck } from "../../Utility/InputValidation";
import * as api from "../../../api/AuthApi";
require("dotenv").config({ path: "../.env" });

const Verify = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalVisible, isModalVisible] = useState(false);
  const [verCode, setVerCode] = useState("");
  const { state } = useSelector((state) => ({
    state: state.verifyCode,
  }));

  const { statusCode } = state;
  useEffect(() => {
    if (props.location?.state === undefined) {
      history.push("/signup");
    }
  }, []);

  useEffect(async () => {
    if (statusCode === 200) {
      await AsyncStorage.setItem("userId", props.location?.state?.userId);
      const payload = {
        email: props.location?.state?.email,
        password: props.location?.state?.password,
        client_id: process.env.REACT_APP_API_CLIENT_ID,
        client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
      };
      let response = await api.login(payload);
      if (response.status === 200) {
        const { access_token } = response.data;
        const { email, id } = response.data.data;
        await AsyncStorage.setItem("access_token", access_token);
        sessionStorage.setItem("access_token", access_token);
        sessionStorage.setItem("userId", parseInt(id));
        sessionStorage.setItem("email", email);
        await AsyncStorage.setItem("userId", parseInt(id));
      }

      history.push("/welcome");

      // seems to make the app slow, need to optimize api
      // const sched_payload = {
      //   user_id: parseInt(props.location.state.userId),
      // }
      // httpMethods.Post("api/users/schedule_notification/", sched_payload)
    } else if (statusCode === 422) {
      toast.error("Invalid Code", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }, [statusCode]);

  const ResendCodeHandler = async () => {
    const payload = {
      user_id: props.location?.state?.userId,
      user_contact: props.location?.state?.email,
      sent_by: "email",
    };
    await dispatch(actions.verifySubmit(payload));
    isModalVisible(true);
  };

  const verifyAccountClickHandler = async () => {
    const payload = {
      user_id: parseInt(props.location.state.userId),
      code: parseInt(verCode),
    };
    await dispatch(verifyActions.verifyCodeSubmit(payload));
  };
  return (
    <div>
      <ToastContainer />
      <main className="site-page container d-flex justify-content-center align-items-center">
        <div className="form-box">
          <form className="login-form p-0">
            <a href="/signup" class="close">
              <i class="fas fa-times txt-color-dark-grey"></i>
            </a>
            <div className="form-group  mb-5">
              <img
                src={codeVerifyPhone}
                alt=""
                className="d-table mx-auto mb-4"
              />
              <h2 className="h1 font-weight-bold txt-color-dark text-center">
                Verify Code
              </h2>
              <span className="form-title-head d-table mx-auto text-center">
                Please enter the verification code we sent to {props.location?.state?.email}.
              </span>
            </div>

            <div className="form-group mb-5">
              <div className="form-row">
                <div className="col-12 p-0 justify-content-center">
                  <div className="d-flex justify-content-center">
                    <input
                      type="tel"
                      className="verify-input"
                      style={{
                        maxWidth: "60%",
                        letterSpacing: 10,
                        paddingLeft: 10,
                      }}
                      maxLength="4"
                      placeholder="0000"
                      onChange={(event) => {
                        setVerCode(event.target.value);
                      }}
                      value={verCode}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group mb-5">
              <p className="text-center">
                Didn’t receive a code?
                <a
                  className="txt-color-primary font-weight-bold ml-2 link"
                  onClick={ResendCodeHandler}
                >
                  Resend Verification Code
                </a>
              </p>
            </div>

            <div className="form-group">
              <button
                type="button"
                className="btn btn-success btn-continue d-table mx-auto"
                data-aim="verify-alert"
                id="alert-btn-open"
                onClick={verifyAccountClickHandler}
                disabled={!charCountCheck(verCode, 4)}
              >
                Verify Account
              </button>
            </div>
          </form>
        </div>
      </main>
      <ResendCodeModal
        isOpen={modalVisible}
        onClose={() => isModalVisible(false)}
      />
      <PhysTreeFooter />
    </div>
  );
};

export default Verify;
