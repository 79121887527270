import React from "react";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import RangeSlider from "react-bootstrap-range-slider";
import { useHistory } from "react-router-dom";
import { useFormContext, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

const Q1 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { watch, control } = useFormContext();
  const watchSlider = watch("slider");
  const { fa } = useSelector((state) => ({
    fa: state.fa,
  }));

  const { faAnswer } = fa;
  const continueHandler = () => {
    const payload = {
      ...faAnswer,
      q1: watchSlider,
    };
    dispatch({ type: "INCREMENT_FA_STEP", payload: payload });
  };

  return (
    <div className="site-page container-fluid p-0">
      <main className="container  d-flex justify-content-center align-items-center">
        <div className="w-100">
          <div className="card-box mw-100">
            <div className="form-row">
              <div className="form-group">
                <h2 className="form-heading-sub">Functional Assessment</h2>
              </div>
            </div>
            <div className="form-row">
              <div className="col-12 mb-3">
                <h2 className="form-heading">Global Rating of Status</h2>
              </div>
              <div className="col-12">
                <p className="form-title-head">
                  As a result of your back pain, what percentage of your normal
                  function are you currently?
                </p>
              </div>

              <div className="col-9">
                <div className="form-group">
                  <label htmlFor="range_value_output" className="form-label">
                    Percentage
                  </label>
                  <input className="form-control" value={`${watchSlider}%`} />
                </div>
              </div>
              <div className="col-5 mt-5">
                <div className="form-group">
                  <Controller
                    name="slider"
                    control={control}
                    render={({ field }) => (
                      <RangeSlider
                        variant="success"
                        tooltipPlacement="top"
                        tooltipLabel={(currentValue) => `${currentValue}%`}
                        {...field}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-auto mr-auto">
              <a
                style={{ cursor: "pointer" }}
                className="btn-prev btn-xs btn"
                onClick={() => {
                  history.goBack();
                }}
              >
                <i className="fas fa-arrow-left mr-2" />
                Previous
              </a>
            </div>
            <div className="col-auto">
              <button
                type="button"
                className="btn btn-sm btn-next  "
                onClick={continueHandler}
                disabled={
                  watchSlider === 0 || watchSlider === "0" ? true : false
                }
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Q1;
